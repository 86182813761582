<template>
    <div class="pre-loader" style="display: none;">
        <img src="../../assets/gif/loader.gif"> 
    </div>
</template>

<script>
    export default {
        name: 'Preloader'
    }
</script>