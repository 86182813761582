<template>

    <!-- Common Banner Area -->
    <Banner />

    <!-- Contact Area -->
    <ContactArea />

</template>
<script>

import Banner from '@/components/contact/Banner.vue'
import ContactArea from '@/components/contact/ContactArea.vue'
export default {
    name: "ContactView",
    components: {
        Banner, ContactArea
    }
};
</script>