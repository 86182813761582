<template>

    <!-- Common Banner Area -->
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>{{ $t('Booking Confirmation') }}</h2>
                        <ul>
                            <li><router-link to="/">{{ $t('Home') }}</router-link>
                            </li>
                            <li>
                                <span>
                                    <i class="fas fa-circle"></i>
                                </span>
                                <a href="javascript:void(0);" @click="$router.go(-2)">
                                    {{ $t('Flight search result') }}
                                </a>
                            </li>
                            <li>
                                <span>
                                    <i class="fas fa-circle"></i>
                                </span>
                                <a href="javascript:void(0);" @click="$router.go(-1)">
                                    {{ $t('Passengers') }}
                                </a>
                            </li>
                            <li><span><i class="fas fa-circle"></i></span> {{ $t('Booking Confirmation') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Tour Booking Submission Areas -->
    <BookingConfirmation />

</template>
<script>
import BookingConfirmation from '@/components/flights/BookingConfirmation.vue'
export default {
    name: "BookingConfirmationView",
    components: {
        BookingConfirmation
    }
};
</script>