<template>
    <section id="destinations_area" class="section_padding_top destinations_area mt-5">
        <div class="container">
            <!-- Section Heading -->
            <div class="row title mb-5">
                <div class="col-lg-7 col-md-6 col-sm-6 col-12">
                    <h2>{{ $t('Destinations') }} <span class="main-color">JSKY</span></h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-6 col-12">
                    <span class="desc">{{ $t('Wide selection of flights') }}</span>
                </div>
            </div>
            <br>
            <div class="row">
                <div v-for="item in items" class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <img :src="item.image">
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "Destinations",
    data() {
        return {
            items: [
                {
                    image: require("@/assets/img/destination/destination1.png"),
                },
                {
                    image: require("@/assets/img/destination/destination2.png"),
                },
                {
                    image: require("@/assets/img/destination/destination3.png"),
                },
                {
                    image: require("@/assets/img/destination/destination4.png"),
                }
            ],
        }
    }
};
</script>