<template>
    <!-- Common Banner Area -->
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>{{ $t('Passengers') }}</h2>
                        <ul>
                            <li><router-link to="/">{{ $t('Home') }}</router-link></li>
                            <li>
                                <span>
                                    <i class="fas fa-circle"></i>
                                </span>
                                <a href="javascript:void(0);" @click="$router.go(-1)">
                                    {{ $t('Flight search result') }}
                                </a>
                            </li>
                            <li><span><i class="fas fa-circle"></i></span> {{ $t('Passengers') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Booking Submission Areas -->
    <BookingSubmission />

</template>

<script>
// @ is an alias to /src
import BookingSubmission from '@/components/flights/BookingSubmission.vue'
export default {
    name: 'BookingSubmissionView',
    components: {
        BookingSubmission
    }
}
</script>