<template>
    <section id="dashboard_main_arae" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="dashboard_common_table">
                        <h3>{{ $t('Profile') }}</h3>
                        <div class="profile_update_form">
                            <form @submit.prevent="save_data" class="needs-validation add-new-passenger"
                                id="profile_form_area" novalidate>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="f-name">{{ $t('First Name') }}</label>
                                            <input type="text" class="form-control" id="f-name" placeholder="Your Name"
                                                v-model="user.first_name" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="l-name">{{ $t('Last Name') }}</label>
                                            <input type="text" class="form-control" id="l-name" v-model="user.last_name"
                                                required>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="mail-address">{{ $t('Email Address') }}</label>
                                            <input type="email" class="form-control" id="mail-address"
                                                v-model="user.email" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>{{ $t('Currency') }}</label>
                                            <select class="form-control" v-model="user.currency">
                                                <option value="SAR">
                                                    SAR
                                                </option>
                                                <option value="USD">
                                                    USD
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>{{ $t('Language') }}</label>
                                            <select class="form-control" v-model="$i18n.locale">
                                                <option value="en">English</option>
                                                <option value="ar">عربي</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right">
                                        <hr>
                                        <button type="submit" class="btn btn_theme br-5">
                                            {{ $t('Save Changes') }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { toast } from 'vue3-toastify';
import userService from '@/services/user/user-service';
export default {
    name: "Profile",
    data() {
        return {
            user: [],
        }
    },
    methods: {
        async save_data() {
            var isValid = false;
            var forms = document.querySelectorAll('.add-new-passenger')
            Array.prototype.slice.call(forms)
                .forEach(function (form) {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                        isValid = false;
                    } else {
                        isValid = true;
                    }
                    form.classList.add('was-validated')
                });
            if (isValid) {
                $('.pre-loader').show();
                await userService.update_user_data(this.user, { lang: localStorage.getItem('locale') }).then(response => {
                    $('.pre-loader').hide();
                    if (response.data.status == 1) {
                        if (this.$i18n.locale == 'ar') {
                            localStorage.setItem("locale", 'ar');
                            $('body').addClass('rtl');
                        } else {
                            localStorage.setItem("locale", 'en');
                            $('body').removeClass('rtl');
                        }
                        this.$i18n.locale = localStorage.getItem("locale");
                        toast.success(response.data.message, {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                    } else {
                        toast.error(response.data.message, {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                    }
                });
            } else {
                toast.error('جميع الحقول مطلوبة', {
                    autoClose: 2500,
                    position: toast.POSITION.TOP_LEFT,
                    closeButton: true
                });
            }
        },
        async get_user_data() {
            $('.pre-loader').addClass('white');
            $('.pre-loader').show();
            await userService.get_user_data({ lang: localStorage.getItem('locale') }).then(response => {
                var res = response.data.user;
                this.user = res;
                $('.pre-loader').hide();
            });
        }
    },
    mounted() {
        this.get_user_data();
    }
};
</script>