<template>
    <!-- Common Banner Area -->
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>{{ $t('Flight search result') }}</h2>
                        <ul>
                            <li><router-link to="/">{{ $t('Home') }}</router-link></li>
                            <li><span><i class="fas fa-circle"></i></span> {{ $t('Flight search result') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Form Area -->
        <Form />
    </section>
    <!-- Flight Search Areas -->
    <SearchResult />

</template>

<script>
import Form from '@/components/flights/Form.vue'
import SearchResult from '@/components/flights/SearchResult.vue'
export default {
    name: 'BookingSearchResultView',
    components: {
        Form, SearchResult
    }
}
</script>