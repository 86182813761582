import Api from "@/api/api";
import authHeader from "@/services/auth/auth-header";
const END_POINT = "api/users/support";
class supportService {
    get_all_messages(params) {
        return Api.get(END_POINT + "/get_chat_messages", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    send_message(body) {
        return Api.post(END_POINT + "/send_message", body, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new supportService();