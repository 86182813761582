<template>
    <section id="customer_reviews" class="section_padding_bottom">
        <div class="container">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="section_heading_center">
                    <h2>{{ $t('testimonials') }}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="all_review_box">
                        <div class="all_review_date_area">
                            <div class="all_review_date">
                                <h5>08 ديسمبر , 2022</h5>
                            </div>
                            <div class="all_review_star">
                                <h5>ممتاز</h5>
                                <div class="review_star_all"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div class="all_review_text"><img src="../../assets/img/client/Foodics.png" alt="img">
                            <h4>فودكس</h4>
                            <p>"صارت حياتي أسهل مع فلاي أكيد. كنا نستعمل وكالات السفر بس مع فلاي أكيد أقدر استقبل
                                الطلبات
                                بشكل مباشر بدل ما يستنى الموظف يوم أو يومين إلى ما تجيهم الموافقة على السفر. - فودكس"
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="all_review_box">
                        <div class="all_review_date_area">
                            <div class="all_review_date">
                                <h5>15 ديسمبر, 2021</h5>
                            </div>
                            <div class="all_review_star">
                                <h5>ممتاز</h5>
                                <div class="review_star_all"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div class="all_review_text">
                            <img src="../../assets/img/client/VirginMobile.png" alt="img">
                            <h4>فيرجن موبايل</h4>
                            <p>"سابقًا كنا نعمل على عمليات ورقية لموافقات السفر والتواصل عن طريق الايميل مع وكلاؤنا.
                                كانت
                                العملية تأخذ وقت طويل وما كان عندنا مراقبة كاملة على اجراءات السفر لكن الآن مع فلاي أكيد
                                الموضوع شفاف عن طريق المنصة. -يعرب الصايغ، فيرجن موبايل"</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="all_review_box">
                        <div class="all_review_date_area">
                            <div class="all_review_date">
                                <h5>25 ديسمبر, 2022</h5>
                            </div>
                            <div class="all_review_star">
                                <h5>ممتاز</h5>
                                <div class="review_star_all"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div class="all_review_text"><img src="../../assets/img/client/OM.png" alt="img">
                            <h4>عنصر مشع</h4>
                            <p>"كشركة من أهم المزايا الموجودة هي التتبع المباشر التي تساعدنا في معرفة الرحلات المستخدمة
                                ومواعيدها وتكلفتها. - عنصر مشع"</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Customer",
};
</script>