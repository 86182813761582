<template>
    <section id="tour_booking_submission" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb20">
                        <div id="pass-adult-info" class="mb20">
                            <div class="row mb20  justify-content-between">
                                <div class="col-md-4 col-8">
                                    <div class="frm-js-lbl">
                                        <input v-model="searchQuery" class="form-control pass-search w-100"
                                            autocomplete="off" :placeholder="$t('Search')" @input="filter_passengers">
                                    </div>
                                </div>
                                <div class="col-md-2 col-4 col-middle">
                                    <button class="btn btn_theme w-100" @click="add_drawer = !add_drawer"
                                        data-target="#accompany_passenger_modal" data-toggle="modal" data-type="adult"
                                        data-backdrop="static">
                                        <span class="inline-middle">{{ $t('New Passenger') }}</span>
                                    </button>
                                </div>
                            </div>
                            <div id="bk-pass-all">
                                <div class="col-md-12 col-12 mb-3">
                                    <h5 class="bold">{{ $t('Passenger List') }}</h5>
                                </div>
                                <div class="row list">
                                    <div v-for="item in filtered_passengers" :data-id="item.id" :data-type="item.type"
                                        :key="item.id" class="col-md-4 col-12 col-sm-12 mb15 pass-card" ref="passCards">
                                        <div class="pass-spec-container">
                                            <div class="not-pass"></div>
                                            <div class="row no-margin align">
                                                <div
                                                    class="col-md-12 col-sm-12 col-xs-12 col-12 p-0 no-padding col-middle">
                                                    <label>
                                                        <div class="pass-details">
                                                            <div class="right">
                                                                <div class="header-area">
                                                                    <i class="light-icon-pencil btn-edit-pass"
                                                                        :data-id="item.id"
                                                                        @click="editPassenger(item.id)"></i>
                                                                    <span class="clearfix no-margin w-100">
                                                                        {{ item.first_name }} {{
                                                                            item.last_name }}
                                                                    </span>
                                                                </div>
                                                                <span class="clearfix no-margin">
                                                                    {{ $t('Passport') }} ({{ $i18n.locale ===
                                                                        'en'
                                                                        ?
                                                                        getCountriesByCode(item.passport_issuing_country)['en_label']
                                                                        :
                                                                        getCountriesByCode(item.passport_issuing_country)['ar_label']
                                                                    }})
                                                                </span>
                                                                <div class="passport-info">
                                                                    <div>
                                                                        {{ item.passport_number }}
                                                                    </div>
                                                                    <div>
                                                                        {{ $t('Expires') }}
                                                                        <time>
                                                                            {{ $t(new
                                                                                Date(item.passport_expiry_date).toLocaleDateString('en-US',
                                                                                    {
                                                                                        month:
                                                                                            'long'
                                                                                    })) }}
                                                                            {{ new
                                                                                Date(item.passport_expiry_date).toLocaleDateString('en-US',
                                                                                    { day: 'numeric' }) }}

                                                                            {{ new
                                                                                Date(item.passport_expiry_date).getFullYear()
                                                                            }}</time>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <lv-drawer v-model="add_drawer" left :headerTitle="$t('New Passenger')" headerColor="#607c8a" close shadow
            background="#fff" :zIndex="9999">
            <form @submit.prevent="savePassenger" class="needs-validation add-new-passenger" novalidate>
                <div class="drawer-bar drawer-black">
                    <button type="button" class="drawer-bar-item drawer-button active" data-id=".drawer-container.info">
                        {{ $t('Passenger information') }}
                    </button>
                    <button type="button" class="drawer-bar-item drawer-button" data-id=".drawer-container.passport">
                        {{ $t('Passport') }}
                    </button>
                    <button type="button" class="drawer-bar-item drawer-button" data-id=".drawer-container.idCard">
                        {{ $t('Identification')
                        }}
                    </button>
                </div>
                <div class="drawer-container info">
                    <div class="row">
                        <div class="col-md-4">
                            <label>
                                {{ $t('Title') }}
                            </label>
                            <Select2 v-model="add_passenger_body.title" :options="titles" :placeholder="$t('Title')"
                                :settings="{ settingOption: value }" :required="true" :dir="'rtl'" />
                        </div>
                        <div class="col-md-4">
                            <label>
                                {{ $t('First Name') }}
                            </label>
                            <input v-model="add_passenger_body.first_name" type="text" class="input"
                                :placeholder="$t('First Name')" pattern="^[a-zA-Z\s]+$" required>
                        </div>
                        <div class="col-md-4">
                            <label>
                                {{ $t('Last Name') }}
                            </label>
                            <input v-model="add_passenger_body.last_name" type="text" class="input"
                                :placeholder="$t('Last Name')" pattern="^[a-zA-Z\s]+$" required>
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Nationality') }}
                            </label>
                            <Select2 v-model="add_passenger_body.nationality" :options="formattedNationalitiesOptions"
                                :placeholder="$t('Nationality')"
                                :settings="{ settingOption: value, allowClear: true, width: '100%' }" :required="true"
                                :dir="'rtl'" />
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Date of Birth') }}
                            </label>
                            <flat-pickr :class="'input'" v-model="add_passenger_body.birthday"
                                :config="{ allowInput: true, dateFormat: 'Y-m-d', maxDate: new Date() }"
                                :required="true" :placeholder="$t('Date of Birth')" />

                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Date of Birth') }}
                            </label>
                            <input v-model="add_passenger_body.email" type="email" class="input"
                                :placeholder="$t('Email Address')" required>
                        </div>
                    </div>
                </div>
                <div class="drawer-container passport">
                    <div class="row">
                        <div class="col-md-6">
                            <label>
                                {{ $t('Passport') }}
                            </label>
                            <input v-model="add_passenger_body.passport_number" type="text" class="input"
                                :placeholder="$t('Passport')" required>
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Country Issuing') }}
                            </label>
                            <Select2 v-model="add_passenger_body.passport_issuing_country"
                                :options="formattedCountriesOptions" :placeholder="$t('Country Issuing')"
                                :settings="{ settingOption: value, allowClear: true, width: '100%' }" :required="true"
                                :dir="'rtl'" />
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Expiry Date') }}
                            </label>
                            <flat-pickr :class="'input'" v-model="add_passenger_body.passport_expiry_date"
                                :config="{ allowInput: true, dateFormat: 'Y-m-d', minDate: new Date() }"
                                :required="true" :placeholder="$t('Expiry Date')" />
                        </div>
                    </div>
                </div>
                <div class="drawer-container idCard">
                    <div class="row">
                        <div class="col-md-6">
                            <label>
                                {{ $t('ID Number') }}
                            </label>
                            <input v-model="add_passenger_body.IDNumber" type="text" class="input"
                                :placeholder="$t('ID Number')" required>
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Country Issuing') }}
                            </label>
                            <Select2 v-model="add_passenger_body.id_issuing_country"
                                :options="formattedCountriesOptions" :placeholder="$t('Country Issuing')"
                                :settings="{ settingOption: value, allowClear: true, width: '100%' }" :required="true"
                                :dir="'rtl'" />
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Expiry Date') }}
                            </label>
                            <flat-pickr :class="'input'" v-model="add_passenger_body.id_expiry_date"
                                :config="{ allowInput: true, dateFormat: 'Y-m-d', minDate: new Date() }"
                                :required="true" :placeholder="$t('Expiry Date')" />
                        </div>
                    </div>
                </div>
                <div class="row m-3 drawer-footer">
                    <div class="col-md-12 d-flex">
                        <span class="mr-3">
                            <button type="submit" class="btn-primary">{{ $t('Save') }}</button>
                        </span>
                        <span>
                            <button type="button" class="btn-outline-primary" @click="add_drawer = !add_drawer">{{
                                $t('cancel') }}
                            </button>
                        </span>
                    </div>
                </div>
            </form>
        </lv-drawer>
        <lv-drawer v-model="update_drawer" left :headerTitle="$t('Rider adjustment')" headerColor="#607c8a" close shadow
            background="#fff" :zIndex="9999">
            <form @submit.prevent="updatePassenger" class="needs-validation" novalidate>
                <div class="drawer-bar drawer-black">
                    <button type="button" class="drawer-bar-item drawer-button active" data-id=".drawer-container.info">
                        {{ $t('Passenger information') }}
                    </button>
                    <button type="button" class="drawer-bar-item drawer-button" data-id=".drawer-container.passport">
                        {{ $t('Passport') }}
                    </button>
                    <button type="button" class="drawer-bar-item drawer-button" data-id=".drawer-container.idCard">
                        {{ $t('Identification')
                        }}
                    </button>
                </div>
                <div class="drawer-container info">
                    <div class="row">
                        <div class="col-md-4">
                            <label>
                                {{ $t('Title') }}
                            </label>
                            <Select2 v-model="update_passenger_body.title" :options="titles" :placeholder="$t('Title')"
                                :settings="{ settingOption: value }" :required="true" :dir="'rtl'" />
                        </div>
                        <div class="col-md-4">
                            <label>
                                {{ $t('First Name') }}
                            </label>
                            <input v-model="update_passenger_body.first_name" type="text" class="input"
                                :placeholder="$t('First Name')" pattern="^[a-zA-Z\s]+$" required>
                        </div>
                        <div class="col-md-4">
                            <label>
                                {{ $t('Last Name') }}
                            </label>
                            <input v-model="update_passenger_body.last_name" type="text" class="input"
                                :placeholder="$t('Last Name')" pattern="^[a-zA-Z\s]+$" required>
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Nationality') }}
                            </label>
                            <Select2 v-model="update_passenger_body.nationality"
                                :options="formattedNationalitiesOptions" :placeholder="$t('Nationality')"
                                :settings="{ settingOption: value, allowClear: true, width: '100%' }" :required="true"
                                :dir="'rtl'" />
                        </div>
                        <div class="row m-0 col-md-6">
                            <div class="col-md-12 p-0">
                                <label>
                                    {{ $t('Date of Birth') }}
                                </label>
                                <flat-pickr :class="'input'" v-model="update_passenger_body.birthday"
                                    :config="{ allowInput: true, dateFormat: 'Y-m-d', maxDate: new Date() }"
                                    :required="true" :placeholder="$t('Date of Birth')" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Date of Birth') }}
                            </label>
                            <input v-model="update_passenger_body.email" type="email" class="input"
                                :placeholder="$t('Email Address')" required>
                        </div>
                    </div>
                </div>
                <div class="drawer-container passport">
                    <div class="row">
                        <div class="col-md-6">
                            <label>
                                {{ $t('Passport') }}
                            </label>
                            <input v-model="update_passenger_body.passport_number" type="text" class="input"
                                :placeholder="$t('Passport')" required>
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Country Issuing') }}
                            </label>
                            <Select2 v-model="update_passenger_body.passport_issuing_country"
                                :options="formattedCountriesOptions" :placeholder="$t('Country Issuing')"
                                :settings="{ settingOption: value, allowClear: true, width: '100%' }" :required="true"
                                :dir="'rtl'" />
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Expiry Date') }}
                            </label>
                            <flat-pickr :class="'input'" v-model="update_passenger_body.passport_expiry_date"
                                :config="{ allowInput: true, dateFormat: 'Y-m-d', minDate: new Date() }"
                                :required="true" :placeholder="$t('Expiry Date')" />
                        </div>
                    </div>
                </div>
                <div class="drawer-container idCard">
                    <div class="row">
                        <div class="col-md-6">
                            <label>
                                {{ $t('ID Number') }}
                            </label>
                            <input v-model="update_passenger_body.IDNumber" type="text" class="input"
                                :placeholder="$t('ID Number')" required>
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Country Issuing') }}
                            </label>
                            <Select2 v-model="update_passenger_body.id_issuing_country"
                                :options="formattedCountriesOptions" :placeholder="$t('Country Issuing')"
                                :settings="{ settingOption: value, allowClear: true, width: '100%' }" :required="true"
                                :dir="'rtl'" />
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Expiry Date') }}
                            </label>
                            <flat-pickr :class="'input'" v-model="update_passenger_body.id_expiry_date"
                                :config="{ allowInput: true, dateFormat: 'Y-m-d', minDate: new Date() }"
                                :required="true" :placeholder="$t('Expiry Date')" />
                        </div>
                    </div>
                </div>
                <div class="row m-3 drawer-footer">
                    <div class="col-md-12 d-flex">
                        <span class="mr-3">
                            <button type="submit" class="btn-primary">{{ $t('Save') }}</button>
                        </span>
                        <span>
                            <button type="button" class="btn-outline-primary" @click="add_drawer = !add_drawer">{{
                                $t('cancel') }}
                            </button>
                        </span>
                    </div>
                </div>
            </form>
        </lv-drawer>
    </section>
</template>
<script>
import $ from 'jquery';
import { toast } from 'vue3-toastify';
import LvDrawer from 'lightvue/drawer';
import nationalitiesData from '@/assets/file/nationalities.json';
import countriesData from '@/assets/file/countries.json';
import PassengerServices from "@/services/passengers/passenger-service";
export default {
    name: "Passengers",
    data: () => ({
        phone: '',
        nationalities: [],
        countries: [],
        passengers: [],
        filtered_passengers: [],
        add_drawer: false,
        update_drawer: false,
        adults: [],
        infants: [],
        children: [],
        titles: [],
        add_passenger_body: {
            title: 'mr',
            first_name: '',
            last_name: '',
            email: '',
            birthday: '',
            nationality: '',
            passport_number: '',
            passport_issuing_country: '',
            passport_expiry_date: '',
            IDNumber: '',
            id_issuing_country: '',
            id_expiry_date: '',
            lang: localStorage.getItem('locale')
        },
        update_passenger_body: {
            title: 'mr',
            first_name: '',
            last_name: '',
            email: '',
            birthday: '',
            nationality: '',
            passport_number: '',
            passport_issuing_country: '',
            passport_expiry_date: '',
            IDNumber: '',
            id_issuing_country: '',
            id_expiry_date: '',
            lang: localStorage.getItem('locale')
        },
        passenger_id: null,
        searchQuery: ''
    }),
    components: { LvDrawer },
    computed: {
        formattedNationalitiesOptions() {
            return this.nationalities.map(option => ({
                id: option.value,
                text: option.ar_label
            }));
        },
        formattedCountriesOptions() {
            return this.countries.map(option => ({
                id: option.value,
                text: option.ar_label
            }));
        }
    },
    methods: {
        getCountriesByCode(code) {
            var info = this.countries.filter(item => {
                return item.value.includes(code);
            });
            return info[0];
        },
        async savePassenger() {
            var isValid = false;
            var forms = document.querySelectorAll('.add-new-passenger')
            Array.prototype.slice.call(forms)
                .forEach(function (form) {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                        isValid = false;
                    } else {
                        isValid = true;
                    }
                    form.classList.add('was-validated')
                });
            if (isValid) {
                $('.pre-loader').show();
                await PassengerServices.add_passenger(this.add_passenger_body).then(response => {
                    $('.pre-loader').hide();
                    if (response.data.status == 1) {
                        this.add_drawer = false;
                        toast.success(response.data.message, {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                        this.getAllPassengers();
                        this.adddata = {
                            title: 'mr',
                            first_name: '',
                            last_name: '',
                            email: '',
                            birthday: '',
                            nationality: '',
                            passport_number: '',
                            passport_issuing_country: '',
                            passport_expiry_date: '',
                            IDNumber: '',
                            id_issuing_country: '',
                            id_expiry_date: '',
                            lang: localStorage.getItem('locale')
                        };
                    } else {
                        toast.error(response.data.message, {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                    }
                });
            } else {
                toast.error('جميع الحقول مطلوبة', {
                    autoClose: 2500,
                    position: toast.POSITION.TOP_LEFT,
                    closeButton: true
                });
            }
        },
        async updatePassenger() {
            var isValid = false;
            var forms = document.querySelectorAll('.needs-validation')
            Array.prototype.slice.call(forms)
                .forEach(function (form) {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                        isValid = false;
                    } else {
                        isValid = true;
                    }
                    form.classList.add('was-validated')
                });
            if (isValid) {
                $('.pre-loader').show();
                await PassengerServices.update_passenger(this.update_passenger_body, this.passenger_id).then(response => {
                    $('.pre-loader').hide();
                    if (response.data.status == 1) {
                        var res = response.data.passengers;
                        this.passengers = res;
                        this.update_drawer = false;
                        this.getAllPassengers();
                        toast.success(response.data.message, {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                    } else {
                        toast.error(response.data.message, {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                    }
                });
            } else {
                toast.error('جميع الحقول مطلوبة', {
                    autoClose: 2500,
                    position: toast.POSITION.TOP_LEFT,
                    closeButton: true
                });
            }
        },
        async getAllPassengers() {
            $('.pre-loader').addClass('white');
            $('.pre-loader').show();
            PassengerServices.get_all_passengers().then(response => {
                var res = response.data.passengers;
                this.passengers = res;
                this.filtered_passengers = this.passengers;
                $('.pre-loader').hide();
                $('.pre-loader').removeClass('white');
            });
        },
        async editPassenger(id) {
            $('.pre-loader').show();
            await PassengerServices.edit_passenger(id).then(response => {
                const passenger = response.data.data;
                this.update_passenger_body = {
                    title: passenger.title,
                    first_name: passenger.first_name,
                    last_name: passenger.last_name,
                    email: passenger.email,
                    birthday: passenger.birthday,
                    nationality: passenger.nationality,
                    passport_number: passenger.passport_number,
                    passport_issuing_country: passenger.passport_issuing_country,
                    passport_expiry_date: passenger.passport_expiry_date,
                    IDNumber: passenger.IDNumber,
                    id_issuing_country: passenger.id_issuing_country,
                    id_expiry_date: passenger.id_expiry_date,
                    lang: localStorage.getItem('locale')
                };
                this.update_drawer = true;
                this.passenger_id = id;
                $('.pre-loader').hide();
            });
        },
        filter_passengers() {
            const query = this.searchQuery.toLowerCase();
            this.filtered_passengers = this.passengers.filter(passenger => {
                return (
                    passenger.first_name.toLowerCase().includes(query) ||
                    passenger.last_name.toLowerCase().includes(query)
                );
            });
        },
        jquery() {
            $(document).on('click', '.drawer-bar-item', function () {
                var id = $(this).data('id');
                $('.drawer-bar-item').removeClass('active');
                $(this).addClass('active');
                $('.drawer-container').hide();
                $(id).show();
            });
        },
    },
    mounted() {
        this.jquery();
        this.getAllPassengers();
        this.nationalities = nationalitiesData;
        this.countries = countriesData;
        this.titles = [
            { text: this.$t('mr'), id: 'mr' },
            { text: this.$t('ms'), id: 'ms' },
            { text: this.$t('miss'), id: 'miss' },
        ];
    }
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/booking-submission.scss';
</style>