<template>
    <section id="dashboard_main_arae" class="section_padding">
        <div class="container">
            <div class="row">
                <div v-for="(item, index) in bookings" class="col-md-3 mb-4">
                    <router-link :to="'/flight-booking/' + (item.id)">
                        <div class="booking-card">
                            <div class="top-content">
                                <div class="departure">
                                    <p>
                                        {{
                                            item.flight_info?.flightOffers[0].itineraries[0].segments[0].departure.iataCode
                                        }}
                                    </p>
                                    <p class="time">
                                        {{
                                            formatDateTimeTo12Hour(item.flight_info?.flightOffers[0].itineraries[0].segments[0].departure.at)
                                        }}
                                    </p>
                                    <p class="date">
                                        {{
                                            convertDateTime(item.flight_info?.flightOffers[0].itineraries[0].segments[0].departure.at)
                                        }}
                                    </p>
                                </div>
                                <div>
                                    <img src="@/assets/svg/img_flight_trip_icon.svg">
                                </div>
                                <div class="arrival">
                                    <p>
                                        {{
                                            item.flight_info?.flightOffers[0].itineraries[0].segments[item.flight_info?.flightOffers[0].itineraries[0].segments.length
                                                - 1].arrival.iataCode
                                        }}
                                    </p>
                                    <p class="time">
                                        {{
                                            formatDateTimeTo12Hour(item.flight_info?.flightOffers[0].itineraries[0].segments[item.flight_info?.flightOffers[0].itineraries[0].segments.length
                                                - 1].arrival.at)
                                        }}
                                    </p>
                                    <p class="date">
                                        {{
                                            convertDateTime(item.flight_info?.flightOffers[0].itineraries[0].segments[item.flight_info?.flightOffers[0].itineraries[0].segments.length
                                                - 1].arrival.at)
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div class="flight-divider"></div>
                            <div class="bottom-content">
                                <p>{{ $t(item.trip_type ?? '') }}</p>
                                <p v-bind:class="item.status">
                                    {{ $t(item.status ?? '') }}
                                </p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
var that;
import $ from 'jquery';
export default {
    name: "Booking",
    data() {
        return {
            bookings: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
        }
    },
    methods: {
        convertDateTime(date) {
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            return new Date(date).toLocaleString('en-US', options);
        },
        formatDateTimeTo12Hour(date) {
            const options = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            };
            return new Date(date).toLocaleString('en-US', options);
        }
    },
    mounted() {
        $('.pre-loader').addClass('white');
        $('.pre-loader').show();
        var url = `${this.$appUrl}/api/users/booking/request/all`;
        this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
            var res = response.data.bookings;
            if (res.length > 0) {
                this.data_count = res.length;
                this.bookings = res;
            }
            $('.pre-loader').hide();
        });
    }
};
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/booking.scss';
</style>