<template>

    <!-- Common Banner Area -->
    <PassengersBanner />

    <!-- Dashboard Area -->
    <Passengers />

</template>
<script>
import PassengersBanner from '@/components/user/passengers/PassengersBanner.vue'
import Passengers from '@/components/user/passengers/Passengers.vue'

export default {
    name: "PassengersView",
    components: {
        PassengersBanner, Passengers
    }
};
</script>