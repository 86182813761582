import Api from "@/api/api";
import authHeader from "@/services/auth/auth-header";
const END_POINT = "api/users/credit-cards/";
class CreditCardsService {
    get_all_cards(params) {
        return Api.get(END_POINT + "all", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    add_card(body, params) {
        return Api.post(END_POINT + "store", body, { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    edit_card(id) {
        return Api.get(END_POINT + "edit/" + id, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    get_primary_card() {
        return Api.get(END_POINT + "get_primary_card", { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    update_card(body, id) {
        return Api.post(END_POINT + "update/" + id, body, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    set_card_primary(id, params) {
        return Api.get(END_POINT + "set_card_primary/" + id, { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    delete_card(id, params) {
        return Api.get(END_POINT + "delete/" + id, { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new CreditCardsService();