<template>
    <div>
        <header class="main_header_arae">
            <!-- Top Bar -->
            <div class="topbar-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <ul class="topbar-list">
                                <li>
                                    <a href="#!"><i class="fab fa-facebook"></i></a>
                                    <a href="#!"><i class="fab fa-twitter-square"></i></a>
                                    <a href="#!"><i class="fab fa-instagram"></i></a>
                                    <a href="#!"><i class="fab fa-linkedin"></i></a>
                                </li>
                                <li><a href="#!"><span>+966 557180660</span></a></li>
                                <li><a href="#!"><span>info@rs4it.sa</span></a></li>
                            </ul>
                            <lv-skeleton :speed="1" :width="275" :height="10" primaryColor="#607c8a"
                                secondaryColor="#8aa6b5" />
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <ul class="topbar-others-options">
                                <li>
                                    <button v-if="isLogin" aria-haspopup="true" aria-expanded="false" type="button"
                                        class="btn dropdown-toggle btn-white header-item p-0 text-white">
                                        <i class="fas fa-user pr-2"></i>
                                        {{ user?.first_name }} {{ user?.last_name }}
                                    </button>
                                    <a v-else href="javascript:void(0);" data-bs-toggle="modal"
                                        data-bs-target="#login-modal">{{ $t('login') }}</a>
                                    <ul role="menu" tabindex="-1"
                                        class="dropdown-menu dropdown-menu-end dropdown-menu-right">
                                        <router-link to="/my-profile" class="dropdown-item  text-black">
                                            <i class="fas fa-user-alt"></i>
                                            {{ $t('Profile') }}
                                        </router-link>
                                        <router-link to="/wallet" class="dropdown-item  text-black">
                                            <i class="fas fa-wallet"></i>
                                            {{ $t('Wallet') }}
                                        </router-link>
                                        <router-link to="/credit-cards" class="dropdown-item  text-black">
                                            <i class="fas fa-credit-card"></i>
                                            {{ $t('Credit cards') }}
                                        </router-link>
                                        <router-link to="/passengers" class="dropdown-item text-black">
                                            <i class="fas fa-users"></i>
                                            {{ $t('Passengers') }}
                                        </router-link>
                                        <router-link to="/flight-booking" class="dropdown-item text-black">
                                            <i class="fas fa-history"></i>
                                            {{ $t('History') }}
                                        </router-link>
                                        <a href="javascript:void(0);" class="dropdown-item text-danger btn-logout"
                                            @click="logout">
                                            <i class="fas fa-sign-out-alt text-danger"></i>
                                            {{ $t('Log Out') }}
                                        </a>
                                    </ul>
                                </li>
                                <li>
                                    <div class="dropdown language-option">
                                        <select v-model="language" @change="changeLanguage">
                                            <option value="en">English</option>
                                            <option value="ar">عربي</option>
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div class="dropdown language-option">
                                        <select v-model="currency" @change="changeCurrency">
                                            <option value="SAR">{{ $t('SAR') }}</option>
                                            <option value="USD">{{ $t('USD') }}</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Navbar Bar -->
            <div class="navbar-area" :class="{ 'is-sticky': isSticky }">
                <div class="main-responsive-nav">
                    <div class="container">
                        <div class="main-responsive-menu mean-container">
                            <nav class="navbar">
                                <div class="container">
                                    <div class="logo">
                                        <router-link to="/">
                                            <img src="../../assets/img/logo.png" alt="logo" with="150">
                                        </router-link>
                                    </div>
                                    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbar-content">
                                        <div class="hamburger-toggle">
                                            <div class="hamburger">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </button>
                                    <div class="collapse navbar-collapse mean-nav" id="navbar-content">
                                        <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                                            <li class="nav-item">
                                                <router-link to="/" class="nav-link">
                                                    {{ $t('Home') }}
                                                </router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="testi" class="nav-link">
                                                    {{ $t('testimonials') }}
                                                </router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="about-us" class="nav-link">
                                                    {{ $t('About Us') }}
                                                </router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/contact" class="nav-link">
                                                    {{ $t('Contact Us') }}
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="main-navbar">
                    <div class="container">
                        <nav class="navbar navbar-expand-md navbar-light">
                            <router-link class="navbar-brand" to="/">
                                <img src="../../assets/img/logo.png" alt="logo">
                            </router-link>

                            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul class="navbar-nav">
                                    <li class="nav-item">
                                        <router-link to="/" class="nav-link">
                                            {{ $t('Home') }}
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="testi" class="nav-link">
                                            {{ $t('testimonials') }}
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="about-us" class="nav-link">
                                            {{ $t('About Us') }}
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/contact" class="nav-link">
                                            {{ $t('Contact Us') }}
                                        </router-link>
                                    </li>
                                </ul>
                                <div class="others-options d-flex align-items-center">
                                    <div class="option-item">
                                        <a :href="this.$businessAppUrl" class="btn  btn_navber">
                                            {{ $t('company login') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="others-option-for-responsive">
                    <div class="container">
                        <div class="container">
                            <div class="option-inner">
                                <div class="others-options d-flex align-items-center">
                                    <div class="option-item">
                                        <a href="javascript:void(0);" class="search-box"><i
                                                class="fas fa-search"></i></a>
                                    </div>
                                    <div class="option-item">
                                        <router-link to="/contact" class="btn btn_navber">Get free quote</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
var that;
import $ from 'jquery';
import event from '@/useEvent';
export default {
    name: 'Header',
    data() {
        return {
            language: 'en',
            currency: 'SAR',
            isSticky: false,
            isLogin: false,
            user: null,
        }
    },
    methods: {
        logout() {
            this.$swal({
                title: 'هل أنت متأكد؟',
                text: "تريد تسجيل الخروج!.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#05a34a',
                cancelButtonColor: '#d33',
                cancelButtonText: 'لا',
                confirmButtonText: 'نعم'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.removeItem("access_token");
                    event.emit('login-event', { user: null, isLogin: false });
                }
            });
        },
        changeLanguage() {
            if (this.language == 'ar') {
                localStorage.setItem("locale", 'ar');
                $('body').addClass('rtl');
            } else {
                localStorage.setItem("locale", 'en');
                $('body').removeClass('rtl');
            }
            this.$i18n.locale = localStorage.getItem("locale");
        },
        changeCurrency() {
            if (this.currency == 'SAR') {
                localStorage.setItem("currency", 'SAR');
            } else {
                localStorage.setItem("currency", 'USD');
            }
        },
        SetLoginData(user) {
            this.user = user;
            this.isLogin = true;
        }
    },
    mounted() {
        that = this;
        if (localStorage.getItem("access_token")) {
            var user = this.$cookies.get('user');
            if (user && user != 'undefined') {
                this.user = JSON.parse(JSON.stringify(user));
            }
            this.isLogin = localStorage.getItem("access_token");
            var url = `${this.$appUrl}/api/users/get_user_data`;
            this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
                var res = response.data.user;
                this.user = res;
                this.$cookies.set("user", JSON.stringify(res), "Session");
                if (response.data.status == 0) {
                    localStorage.removeItem("access_token");
                    that.$bvModal.hide('login-out-modal');
                    that.isLogin = false;
                    window.location.href = '/';
                }
            });
        }
        window.addEventListener('scroll', () => {
            let scroll = window.scrollY
            if (scroll >= 200) {
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        })
        document.addEventListener('click', function (e) {
            // Hamburger menu
            if (e.target.classList.contains('hamburger-toggle')) {
                e.target.children[0].classList.toggle('active');
            }
        });
        this.language = localStorage.getItem("locale");
        if (this.language == 'ar') {
            $('body').addClass('rtl');
        } else {
            $('body').removeClass('rtl');
        }
        if (localStorage.getItem("locale")) {
            this.$i18n.locale = localStorage.getItem("locale");
            this.lang = localStorage.getItem("locale");
        } else {
            localStorage.setItem("locale", this.$i18n.locale);
        }
        if (localStorage.getItem("currency")) {
            this.currency = localStorage.getItem("currency");
        } else {
            this.currency = 'SAR';
            localStorage.setItem("currency", this.currency);
        }
        event.on('login-event', function (data) {
            that.user = data.user;
            that.isLogin = data.isLogin;
        });
    }
}
</script>