import Api from "@/api/api";
import authHeader from "@/services/auth/auth-header";
const END_POINT = "api/users/wallet";
class WalletService {
    get_user_wallet(params) {
        return Api.get(END_POINT, { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    get_recharge_requests(params) {
        return Api.get(END_POINT + "/recharge_requests", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    cancel_request(id, params) {
        return Api.get(END_POINT + "/recharge_requests/cancel_request/" + id, { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    send_recharge_request(body, params) {
        return Api.post(END_POINT + "/recharge_requests/send", body, { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new WalletService();