<template>

    <!-- Common Banner Area -->
    <CreditCardsBanner />

    <!-- Dashboard Area -->
    <CreditCards />

</template>
<script>

import CreditCardsBanner from '@/components/user/credit-cards/CreditCardsBanner.vue'
import CreditCards from '@/components/user/credit-cards/CreditCards.vue'
export default {
    name: "CreditCardsView",
    components: {
        CreditCardsBanner, CreditCards
    }
};
</script>
