<template>
    <section id="counter_area" class="section_padding_bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 ">
                    <div class="counter_area_wrapper">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 clo-sm-12 col-12">
                                <div class="counter_item">
                                    <img src="../../assets/img/icon/user.png" alt="icon">
                                    <h3 class="counter">2508</h3>
                                    <h6>الشركاء</h6>
                                </div> 
                            </div>
                            <div class="col-lg-3 col-md-6 clo-sm-12 col-12">
                                <div class="counter_item">
                                    <img src="../../assets/img/icon/bank.png" alt="icon">
                                    <h3 class="counter">1748</h3>
                                    <h6>حجوزات الفنادق</h6>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 clo-sm-12 col-12">
                                <div class="counter_item">
                                    <img src="../../assets/img/icon/world-map.png" alt="icon">
                                    <h3 class="counter">4287</h3>
                                    <h6>الوجهات</h6>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 clo-sm-12 col-12">
                                <div class="counter_item">
                                    <img src="../../assets/img/icon/calander.png" alt="icon">
                                    <h3 class="counter">40</h3>
                                    <h6>حجوزات الطيران</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "Counter"
};
</script>