<template>
    <section id="theme_search_form">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="flight_categories_search">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="oneway-tab" data-bs-toggle="tab"
                                    data-bs-target="#oneway_flight" type="button" role="tab"
                                    aria-controls="oneway_flight" aria-selected="true">
                                    {{ $t('Oneway') }}
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="roundtrip-tab" data-bs-toggle="tab"
                                    data-bs-target="#roundtrip" type="button" role="tab" aria-controls="roundtrip"
                                    aria-selected="false">
                                    {{ $t('Roundtrip') }}</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="multi_city-tab" data-bs-toggle="tab"
                                    data-bs-target="#multi_city" type="button" role="tab" aria-controls="multi_city"
                                    aria-selected="false">
                                    {{ $t('Multi-City') }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="theme_search_form_area">
                        <div class="tab-content" id="myTabContent1">
                            <div class="tab-pane fade show active" id="oneway_flight" role="tabpanel"
                                aria-labelledby="oneway-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="oneway_search_form">
                                            <form action="#!">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12 relative">
                                                        <div class="flight_boxed_label">
                                                            <p>{{ $t('From') }}</p>
                                                            <i class="fas fa-plane-departure"></i>
                                                        </div>
                                                        <div class="flight_Search_boxed">
                                                            <input type="text" class="departure" name="departure"
                                                                v-model="departure"
                                                                @input="handle_airports_search_departure"
                                                                :placeholder="$t('City or Airport')">
                                                            <span v-if="departure_name != ''" class="departure_name">
                                                                {{ departure_name }}
                                                            </span>
                                                        </div>
                                                        <ul v-if="is_departure_searching" tabindex="0"
                                                            class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">
                                                            <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                aria-disabled="true">
                                                                <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                                                    {{ $t('Nearest airport') }}
                                                                </a>
                                                            </li>
                                                            <li v-for="(airport, index) in airports_data"
                                                                class="fl-route-list ui-menu-item" :key="index"
                                                                @click="selectDepartureAirport(airport)">
                                                                <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                                                    <div class="inline-block airportMargin">
                                                                        <span class="fl-loc">
                                                                            {{ this.$i18n.locale ==
                                                                                'en' ? airport.en_name :
                                                                                airport.ar_name
                                                                            }}
                                                                        </span>
                                                                        <span class="fl-country">
                                                                            {{
                                                                                this.$i18n.locale ==
                                                                                    'en' ? airport.en_city :
                                                                                    airport.ar_city
                                                                            }}
                                                                            ,
                                                                            {{ this.$i18n.locale ==
                                                                                'en'
                                                                                ? airport.en_country :
                                                                                airport.ar_country
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                    <div class="pull-right">
                                                                        <h4 class="fl-route-code">{{
                                                                            airport.code }}
                                                                        </h4>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li v-if="noResults"
                                                                class="fl-route-list ui-menu-item no-results">
                                                                {{ $t('No results!') }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12 relative">
                                                        <div class="flight_boxed_label">
                                                            <p>{{ $t('To') }}</p>
                                                            <i class="fas fa-plane-arrival"></i>
                                                        </div>
                                                        <div class="flight_Search_boxed">
                                                            <input type="text" class="arrival" name="arrival"
                                                                v-model="arrival" :placeholder="$t('City or Airport')"
                                                                @input="handle_airports_search_arrival">
                                                            <span v-if="arrival_name != ''" class="arrival_name">
                                                                {{ arrival_name }}
                                                            </span>
                                                            <div class="range_plan">
                                                                <i class="fas fa-exchange-alt"></i>
                                                            </div>
                                                        </div>
                                                        <ul v-if="is_arrival_searching" tabindex="0"
                                                            class="ui-id-2 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">
                                                            <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                aria-disabled="true">
                                                                <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                                                    {{ $t('Nearest airport') }}
                                                                </a>
                                                            </li>
                                                            <li v-for="(airport, index) in airports_data"
                                                                class="fl-route-list ui-menu-item" :key="index"
                                                                @click="selectArrivalAirport(airport)">
                                                                <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                                                    <div class="inline-block airportMargin">
                                                                        <span class="fl-loc">
                                                                            {{ this.$i18n.locale ==
                                                                                'en' ? airport.en_name :
                                                                                airport.ar_name
                                                                            }}
                                                                        </span>
                                                                        <span class="fl-country">
                                                                            {{
                                                                                this.$i18n.locale ==
                                                                                    'en' ? airport.en_city :
                                                                                    airport.ar_city
                                                                            }}
                                                                            ,
                                                                            {{ this.$i18n.locale ==
                                                                                'en'
                                                                                ? airport.en_country :
                                                                                airport.ar_country
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                    <div class="pull-right">
                                                                        <h4 class="fl-route-code">{{
                                                                            airport.code }}
                                                                        </h4>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li v-if="noResults"
                                                                class="fl-route-list ui-menu-item no-results">
                                                                {{ $t('No results!') }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-4  col-md-6 col-sm-12 col-12">
                                                        <div class="form_search_date">
                                                            <div class="flight_boxed_label">
                                                                <p>{{ $t('Departure Date') }}</p>
                                                            </div>
                                                            <div class="flight_Search_boxed">
                                                                <div class="Journey_date">
                                                                    <VueDatePicker v-model="date"
                                                                        :enable-time-picker="false"
                                                                        :min-date="new Date()"
                                                                        :placeholder="$t('Departure Date')" />
                                                                    <span v-if="date != null" class="departure_day">
                                                                        {{ $t(new
                                                                            Date(date).toLocaleDateString('en-US',
                                                                                { weekday: 'long' })) }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div class="flight_boxed_label">
                                                            <p>{{ $t('passenger') }}, {{ $t('Class') }} </p>
                                                        </div>
                                                        <div class="flight_Search_boxed dropdown_passenger_area">
                                                            <div class="dropdown">
                                                                <button class="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    {{ number }} {{ $t('passenger') }}
                                                                </button>

                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div class="traveller-calulate-persons">
                                                                        <div class="passengers">
                                                                            <h6>{{ $t('Passengers') }}</h6>
                                                                            <div class="passengers-types">
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count pcount">{{
                                                                                                pnumber
                                                                                            }}</span>
                                                                                        <div class="type-label">
                                                                                            <p>{{ $t('Adult') }}
                                                                                            </p>
                                                                                            <span>{{
                                                                                                $t('morethan')
                                                                                                }}
                                                                                                12+
                                                                                                {{ $t('year')
                                                                                                }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add"
                                                                                            @click.stop="increment">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract"
                                                                                            @click.stop="decrement">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count ccount">{{
                                                                                                cnumber
                                                                                            }}</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                {{ $t('Child')
                                                                                                }}
                                                                                            </p><span> {{
                                                                                                $t('Lessthan')
                                                                                                }} 12
                                                                                                {{ $t('year')
                                                                                                }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-c"
                                                                                            @click.stop="incrementc">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-c"
                                                                                            @click.stop="decrementc">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count incount">{{
                                                                                                inumber
                                                                                            }}</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                {{ $t('Infant')
                                                                                                }}
                                                                                            </p><span>{{
                                                                                                $t('Lessthan')
                                                                                                }} 2
                                                                                                {{ $t('year')
                                                                                                }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-in"
                                                                                            @click.stop="incrementi">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-in"
                                                                                            @click.stop="decrementi">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="cabin-selection">
                                                                            <h6>{{ $t('Cabin Class') }}</h6>
                                                                            <div class="cabin-list">
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <input id="cb-eco" type="radio"
                                                                                        v-model="cabin" name="cabin"
                                                                                        value="ECONOMY"
                                                                                        class="chk-cabin">
                                                                                    <label for="cb-eco">
                                                                                        <span class="muiButton-label">
                                                                                            {{ $t('ECONOMY') }}
                                                                                        </span>
                                                                                    </label>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <input id="cb-bus" type="radio"
                                                                                        v-model="cabin" name="cabin"
                                                                                        value="BUSINESS"
                                                                                        class="chk-cabin">
                                                                                    <label for="cb-bus">
                                                                                        <span class="muiButton-label">
                                                                                            {{ $t('BUSINESS') }}
                                                                                        </span>
                                                                                    </label>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <input id="cb-firs" type="radio"
                                                                                        v-model="cabin" name="cabin"
                                                                                        value="FIRST" class="chk-cabin">
                                                                                    <label for="cb-firs"><span
                                                                                            class="MuiButton-label">
                                                                                            {{ $t('FIRST') }}
                                                                                        </span>
                                                                                    </label>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>{{ $t(cabin) }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="isLogin && companies?.length > 0" class="col-md-12 p-0">
                                                        <div class="business-company mt-3">
                                                            <div class="container">
                                                                <div class="row align-items-center">
                                                                    <div class="col-md-3">
                                                                        <div class="flight_boxed_label v-hidden">
                                                                            <p>{{ $t('Departure Date') }}</p>
                                                                        </div>
                                                                        <LvToggleSwitch v-model="businessTravel"
                                                                            :label="$t('Corporate Travel')" />
                                                                    </div>
                                                                    <div v-if="businessTravel" class="col-md-3">
                                                                        <div class="flight_boxed_label">
                                                                            <p>{{ $t('Company') }}</p>
                                                                        </div>
                                                                        <div class="flight_Search_boxed">
                                                                            <select class="form-control"
                                                                                v-model="company"
                                                                                @change="companyChanged">
                                                                                <option v-for="item in companies"
                                                                                    :key="item.id" :value="item.id">
                                                                                    {{
                                                                                        item.company_name
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="businessTravel" class="col-md-4">
                                                                        <div class="flight_boxed_label">
                                                                            <p>{{ $t('travel-purposes') }}</p>
                                                                        </div>
                                                                        <div class="flight_Search_boxed">
                                                                            <select class="form-control"
                                                                                v-model="purpose" required>
                                                                                <option v-for="item in purposes"
                                                                                    :key="item.id" :value="item.id">
                                                                                    {{
                                                                                        item.title_for_employee
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="top_form_search_button">
                                                        <button type="button"
                                                            class="btn btn_theme btn_md btn-search-flight-next"
                                                            @click="search_flight">
                                                            {{ $t('Search Flight') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="roundtrip" role="tabpanel" aria-labelledby="roundtrip-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="oneway_search_form">
                                            <form action="#!">
                                                <div class="row">
                                                    <div class="col-lg-3  col-md-6 col-sm-12 col-12 relative">
                                                        <div class="flight_boxed_label">
                                                            <p>{{ $t('From') }}</p>
                                                            <i class="fas fa-plane-departure"></i>
                                                        </div>
                                                        <div class="flight_Search_boxed">
                                                            <input type="text" class="departure" name="departure"
                                                                v-model="departure"
                                                                @input="handle_airports_search_departure"
                                                                :placeholder="$t('City or Airport')">
                                                            <span v-if="departure_name != ''" class="departure_name">
                                                                {{ departure_name }}
                                                            </span>
                                                        </div>
                                                        <ul v-if="is_departure_searching" tabindex="0"
                                                            class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">
                                                            <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                aria-disabled="true">
                                                                <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                                                    {{ $t('Nearest airport') }}
                                                                </a>
                                                            </li>
                                                            <li v-for="(airport, index) in airports_data"
                                                                class="fl-route-list ui-menu-item" :key="index"
                                                                @click="selectDepartureAirport(airport)">
                                                                <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                                                    <div class="inline-block airportMargin">
                                                                        <span class="fl-loc">
                                                                            {{ this.$i18n.locale ==
                                                                                'en' ? airport.en_name :
                                                                                airport.ar_name
                                                                            }}
                                                                        </span>
                                                                        <span class="fl-country">
                                                                            {{
                                                                                this.$i18n.locale ==
                                                                                    'en' ? airport.en_city :
                                                                                    airport.ar_city
                                                                            }}
                                                                            ,
                                                                            {{ this.$i18n.locale ==
                                                                                'en'
                                                                                ? airport.en_country :
                                                                                airport.ar_country
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                    <div class="pull-right">
                                                                        <h4 class="fl-route-code">{{
                                                                            airport.code }}
                                                                        </h4>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li v-if="noResults"
                                                                class="fl-route-list ui-menu-item no-results">
                                                                {{ $t('No results!') }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-3  col-md-6 col-sm-12 col-12 relative">
                                                        <div class="flight_boxed_label">
                                                            <p>{{ $t('To') }}</p>
                                                            <i class="fas fa-plane-arrival"></i>
                                                        </div>
                                                        <div class="flight_Search_boxed">
                                                            <input type="text" class="arrival" name="arrival"
                                                                v-model="arrival" :placeholder="$t('City or Airport')"
                                                                @input="handle_airports_search_arrival">
                                                            <span v-if="arrival_name != ''" class="arrival_name">
                                                                {{ arrival_name }}
                                                            </span>
                                                            <div class="range_plan">
                                                                <i class="fas fa-exchange-alt"></i>
                                                            </div>
                                                        </div>
                                                        <ul v-if="is_arrival_searching" tabindex="0"
                                                            class="ui-id-2 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">
                                                            <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                aria-disabled="true">
                                                                <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                                                    {{ $t('Nearest airport') }}
                                                                </a>
                                                            </li>
                                                            <li v-for="(airport, index) in airports_data"
                                                                class="fl-route-list ui-menu-item" :key="index"
                                                                @click="selectArrivalAirport(airport)">
                                                                <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                                                    <div class="inline-block airportMargin">
                                                                        <span class="fl-loc">
                                                                            {{ this.$i18n.locale ==
                                                                                'en' ? airport.en_name :
                                                                                airport.ar_name
                                                                            }}
                                                                        </span>
                                                                        <span class="fl-country">
                                                                            {{
                                                                                this.$i18n.locale ==
                                                                                    'en' ? airport.en_city :
                                                                                    airport.ar_city
                                                                            }}
                                                                            ,
                                                                            {{ this.$i18n.locale ==
                                                                                'en'
                                                                                ? airport.en_country :
                                                                                airport.ar_country
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                    <div class="pull-right">
                                                                        <h4 class="fl-route-code">{{
                                                                            airport.code }}
                                                                        </h4>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li v-if="noResults"
                                                                class="fl-route-list ui-menu-item no-results">
                                                                {{ $t('No results!') }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-4  col-md-6 col-sm-12 col-12">
                                                        <div class="flight_boxed_label">
                                                            <p>{{ $t('Departure Date') }}</p>
                                                            <p>{{ $t('Return Date') }}</p>
                                                        </div>
                                                        <div class="form_search_date">
                                                            <div class="flight_Search_boxed date_flex_area">
                                                                <div class="Journey_date">
                                                                    <VueDatePicker v-model="date"
                                                                        :enable-time-picker="false"
                                                                        :min-date="new Date()"
                                                                        :placeholder="$t('Departure Date')" />
                                                                    <span v-if="date != null" class="departure_day">
                                                                        {{ $t(new
                                                                            Date(date).toLocaleDateString('en-US',
                                                                                { weekday: 'long' })) }}
                                                                    </span>
                                                                </div>
                                                                <div class="Journey_date">
                                                                    <VueDatePicker v-model="arrival_date"
                                                                        :enable-time-picker="false"
                                                                        :min-date="new Date()"
                                                                        :placeholder="$t('Return Date')" />
                                                                    <span v-if="arrival_date != null"
                                                                        class="arrival_day">
                                                                        {{ $t(new
                                                                            Date(arrival_date).toLocaleDateString('en-US',
                                                                                { weekday: 'long' })) }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div class="flight_boxed_label">
                                                            <p>{{ $t('passenger') }}, {{ $t('Class') }} </p>
                                                        </div>
                                                        <div class="flight_Search_boxed dropdown_passenger_area">
                                                            <div class="dropdown">
                                                                <button class="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    {{ number }} {{ $t('passenger') }}
                                                                </button>

                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div class="traveller-calulate-persons">
                                                                        <div class="passengers">
                                                                            <h6>{{ $t('Passengers') }}</h6>
                                                                            <div class="passengers-types">
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count pcount">{{
                                                                                                pnumber
                                                                                            }}</span>
                                                                                        <div class="type-label">
                                                                                            <p>{{ $t('Adult') }}
                                                                                            </p>
                                                                                            <span>{{
                                                                                                $t('morethan')
                                                                                                }}
                                                                                                12+
                                                                                                {{ $t('year')
                                                                                                }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add"
                                                                                            @click.stop="increment">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract"
                                                                                            @click.stop="decrement">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count ccount">{{
                                                                                                cnumber
                                                                                            }}</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                {{ $t('Child')
                                                                                                }}
                                                                                            </p><span> {{
                                                                                                $t('Lessthan')
                                                                                                }} 12
                                                                                                {{ $t('year')
                                                                                                }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-c"
                                                                                            @click.stop="incrementc">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-c"
                                                                                            @click.stop="decrementc">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count incount">{{
                                                                                                inumber
                                                                                            }}</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                {{ $t('Infant')
                                                                                                }}
                                                                                            </p><span>{{
                                                                                                $t('Lessthan')
                                                                                                }} 2
                                                                                                {{ $t('year')
                                                                                                }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-in"
                                                                                            @click.stop="incrementi">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-in"
                                                                                            @click.stop="decrementi">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="cabin-selection">
                                                                            <h6>{{ $t('Cabin Class') }}</h6>
                                                                            <div class="cabin-list">
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <input id="cb-eco" type="radio"
                                                                                        v-model="cabin" name="cabin"
                                                                                        value="ECONOMY"
                                                                                        class="chk-cabin">
                                                                                    <label for="cb-eco">
                                                                                        <span class="muiButton-label">
                                                                                            {{ $t('ECONOMY') }}
                                                                                        </span>
                                                                                    </label>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <input id="cb-bus" type="radio"
                                                                                        v-model="cabin" name="cabin"
                                                                                        value="BUSINESS"
                                                                                        class="chk-cabin">
                                                                                    <label for="cb-bus">
                                                                                        <span class="muiButton-label">
                                                                                            {{ $t('BUSINESS') }}
                                                                                        </span>
                                                                                    </label>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <input id="cb-firs" type="radio"
                                                                                        v-model="cabin" name="cabin"
                                                                                        value="FIRST" class="chk-cabin">
                                                                                    <label for="cb-firs"><span
                                                                                            class="MuiButton-label">
                                                                                            {{ $t('FIRST') }}
                                                                                        </span>
                                                                                    </label>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>{{ $t(cabin) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="isLogin && companies?.length > 0" class="col-md-12 p-0">
                                                    <div class="business-company mt-3">
                                                        <div class="container">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-3">
                                                                    <div class="flight_boxed_label v-hidden">
                                                                        <p>{{ $t('Departure Date') }}</p>
                                                                    </div>
                                                                    <LvToggleSwitch v-model="businessTravel"
                                                                        :label="$t('Corporate Travel')" />
                                                                </div>
                                                                <div v-if="businessTravel" class="col-md-3">
                                                                    <div class="flight_boxed_label">
                                                                        <p>{{ $t('Company') }}</p>
                                                                    </div>
                                                                    <div class="flight_Search_boxed">
                                                                        <select class="form-control" v-model="company"
                                                                            @change="companyChanged">
                                                                            <option v-for="item in companies"
                                                                                :key="item.id" :value="item.id">
                                                                                {{
                                                                                    item.company_name
                                                                                }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div v-if="businessTravel" class="col-md-4">
                                                                    <div class="flight_boxed_label">
                                                                        <p>{{ $t('travel-purposes') }}</p>
                                                                    </div>
                                                                    <div class="flight_Search_boxed">
                                                                        <select class="form-control" v-model="purpose"
                                                                            required>
                                                                            <option v-for="item in purposes"
                                                                                :key="item.id" :value="item.id">
                                                                                {{
                                                                                    item.title_for_employee
                                                                                }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="top_form_search_button">
                                                    <button type="button"
                                                        class="btn btn_theme btn_md btn-search-flight-next"
                                                        @click="search_flight">
                                                        {{ $t('Search Flight') }}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="multi_city" role="tabpanel" aria-labelledby="multi_city-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="oneway_search_form">
                                            <form action="#!">
                                                <div v-for='(item, index) in destinations'>
                                                    <div class="multi_city_form mt-4">
                                                        <div class="row align-items-center">
                                                            <div class="col-lg-3 relative">
                                                                <div class="flight_boxed_label">
                                                                    <p>{{ $t('From') }}</p>
                                                                    <i class="fas fa-plane-departure"></i>
                                                                </div>
                                                                <div class="flight_Search_boxed">
                                                                    <input type="text" class="departure"
                                                                        name="departure" v-model="item.departure"
                                                                        :placeholder="$t('City or Airport')"
                                                                        @input="handle_airports_search_departure_multi_city(index)">
                                                                    <span v-if="item.departure_name != ''"
                                                                        class="departure_name">
                                                                        {{ item.departure_name }}
                                                                    </span>
                                                                </div>
                                                                <ul v-if="item.is_departure_searching"
                                                                    class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">
                                                                    <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                        aria-disabled="true">
                                                                        <a class="fl-routes ui-menu-item-wrapper"
                                                                            id="ui-id-8" tabindex="-1">
                                                                            {{ $t('Nearest airport') }}
                                                                        </a>
                                                                    </li>
                                                                    <li v-for="(airport, key) in airports_data"
                                                                        class="fl-route-list ui-menu-item" :key="key"
                                                                        @click="selectDepartureAirportMultiCity(airport, index)">
                                                                        <a class="fl-routes ui-menu-item-wrapper"
                                                                            tabindex="-1">
                                                                            <div class="inline-block airportMargin">
                                                                                <span class="fl-loc">
                                                                                    {{ this.$i18n.locale ==
                                                                                        'en' ? airport.en_name :
                                                                                        airport.ar_name
                                                                                    }}
                                                                                </span>
                                                                                <span class="fl-country">
                                                                                    {{
                                                                                        this.$i18n.locale ==
                                                                                            'en' ? airport.en_city :
                                                                                            airport.ar_city
                                                                                    }}
                                                                                    ,
                                                                                    {{ this.$i18n.locale ==
                                                                                        'en'
                                                                                        ? airport.en_country :
                                                                                        airport.ar_country
                                                                                    }}
                                                                                </span>
                                                                            </div>
                                                                            <div class="pull-right">
                                                                                <h4 class="fl-route-code">{{
                                                                                    airport.code }}
                                                                                </h4>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li v-if="item.noResults"
                                                                        class="fl-route-list ui-menu-item no-results">
                                                                        {{ $t('No results!') }}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="col-lg-3 relative">
                                                                <div class="flight_boxed_label">
                                                                    <p>{{ $t('To') }}</p>
                                                                    <i class="fas fa-plane-arrival"></i>
                                                                </div>
                                                                <div class="flight_Search_boxed">
                                                                    <input type="text" class="arrival" name="arrival"
                                                                        v-model="item.arrival"
                                                                        @input="handle_airports_search_arrival_multi_city(index)"
                                                                        :placeholder="$t('City or Airport')">
                                                                    <span v-if="item.arrival_name != ''"
                                                                        class="arrival_name">
                                                                        {{ item.arrival_name }}
                                                                    </span>
                                                                    <div class="range_plan">
                                                                        <i class="fas fa-exchange-alt"></i>
                                                                    </div>
                                                                </div>
                                                                <ul v-if="item.is_arrival_searching"
                                                                    class="ui-id-2 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">
                                                                    <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                                                        aria-disabled="true">
                                                                        <a class="fl-routes ui-menu-item-wrapper"
                                                                            id="ui-id-8" tabindex="-1">
                                                                            {{ $t('Nearest airport') }}
                                                                        </a>
                                                                    </li>
                                                                    <li v-for="(airport, key) in airports_data"
                                                                        class="fl-route-list ui-menu-item" :key="key"
                                                                        @click="selectArrivalAirportMultiCity(airport, index)">
                                                                        <a class="fl-routes ui-menu-item-wrapper"
                                                                            tabindex="-1">
                                                                            <div class="inline-block airportMargin">
                                                                                <span class="fl-loc">
                                                                                    {{ this.$i18n.locale ==
                                                                                        'en' ? airport.en_name :
                                                                                        airport.ar_name
                                                                                    }}
                                                                                </span>
                                                                                <span class="fl-country">
                                                                                    {{
                                                                                        this.$i18n.locale ==
                                                                                            'en' ? airport.en_city :
                                                                                            airport.ar_city
                                                                                    }}
                                                                                    ,
                                                                                    {{ this.$i18n.locale ==
                                                                                        'en'
                                                                                        ? airport.en_country :
                                                                                        airport.ar_country
                                                                                    }}
                                                                                </span>
                                                                            </div>
                                                                            <div class="pull-right">
                                                                                <h4 class="fl-route-code">{{
                                                                                    airport.code }}
                                                                                </h4>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li v-if="item.noResults"
                                                                        class="fl-route-list ui-menu-item no-results">
                                                                        {{ $t('No results!') }}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <div class="flight_boxed_label">
                                                                    <p>{{ $t('Departure Date') }}</p>
                                                                </div>
                                                                <div class="form_search_date">
                                                                    <div class="flight_Search_boxed">
                                                                        <div class="Journey_date">
                                                                            <VueDatePicker v-model="item.date"
                                                                                :enable-time-picker="false"
                                                                                :min-date="new Date()"
                                                                                :placeholder="$t('Departure Date')" />
                                                                            <span v-if="item.date != null"
                                                                                class="departure_day">
                                                                                {{ $t(new
                                                                                    Date(item.date).toLocaleDateString('en-US',
                                                                                        { weekday: 'long' })) }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2" v-if="index > 1">
                                                                <div class="flight_boxed_label v-hidden">
                                                                    <p>{{ $t('Departure Date') }}</p>
                                                                </div>
                                                                <div class="multi_form_remove">
                                                                    <button type="button"
                                                                        @click="deleteDestination(index)">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div v-if="index == 0"
                                                                class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                <div class="flight_boxed_label">
                                                                    <p>{{ $t('passenger') }}, {{ $t('Class') }}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    class="flight_Search_boxed dropdown_passenger_area">
                                                                    <div class="dropdown">
                                                                        <button class="dropdown-toggle final-count"
                                                                            data-toggle="dropdown" type="button"
                                                                            id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                            {{ number }} {{ $t('passenger') }}
                                                                        </button>

                                                                        <div class="dropdown-menu dropdown_passenger_info"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <div class="traveller-calulate-persons">
                                                                                <div class="passengers">
                                                                                    <h6>{{ $t('Passengers') }}
                                                                                    </h6>
                                                                                    <div class="passengers-types">
                                                                                        <div class="passengers-type">
                                                                                            <div class="text">
                                                                                                <span
                                                                                                    class="count pcount">{{
                                                                                                        pnumber
                                                                                                    }}</span>
                                                                                                <div class="type-label">
                                                                                                    <p>{{
                                                                                                        $t('Adult')
                                                                                                        }}</p>
                                                                                                    <span>{{
                                                                                                        $t('morethan')
                                                                                                        }}
                                                                                                        12+
                                                                                                        {{
                                                                                                            $t('year')
                                                                                                        }}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add"
                                                                                                    @click.stop="increment">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract"
                                                                                                    @click.stop="decrement">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text">
                                                                                                <span
                                                                                                    class="count ccount">{{
                                                                                                        cnumber
                                                                                                    }}</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        {{
                                                                                                            $t('Child')
                                                                                                        }}
                                                                                                    </p><span>
                                                                                                        {{
                                                                                                            $t('Lessthan')
                                                                                                        }} 12
                                                                                                        {{
                                                                                                            $t('year')
                                                                                                        }}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-c"
                                                                                                    @click.stop="incrementc">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-c"
                                                                                                    @click.stop="decrementc">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text">
                                                                                                <span
                                                                                                    class="count incount">{{
                                                                                                        inumber
                                                                                                    }}</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        {{
                                                                                                            $t('Infant')
                                                                                                        }}
                                                                                                    </p><span>{{
                                                                                                        $t('Lessthan')
                                                                                                        }} 2
                                                                                                        {{
                                                                                                            $t('year')
                                                                                                        }}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-in"
                                                                                                    @click.stop="incrementi">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-in"
                                                                                                    @click.stop="decrementi">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="cabin-selection">
                                                                                    <h6>{{ $t('Cabin Class') }}
                                                                                    </h6>
                                                                                    <div class="cabin-list">
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <input id="cb-eco"
                                                                                                type="radio"
                                                                                                v-model="cabin"
                                                                                                name="cabin"
                                                                                                value="ECONOMY"
                                                                                                class="chk-cabin">
                                                                                            <label for="cb-eco">
                                                                                                <span
                                                                                                    class="muiButton-label">
                                                                                                    {{
                                                                                                        $t('ECONOMY')
                                                                                                    }}
                                                                                                </span>
                                                                                            </label>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <input id="cb-bus"
                                                                                                type="radio"
                                                                                                v-model="cabin"
                                                                                                name="cabin"
                                                                                                value="BUSINESS"
                                                                                                class="chk-cabin">
                                                                                            <label for="cb-bus">
                                                                                                <span
                                                                                                    class="muiButton-label">
                                                                                                    {{
                                                                                                        $t('BUSINESS')
                                                                                                    }}
                                                                                                </span>
                                                                                            </label>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <input id="cb-firs"
                                                                                                type="radio"
                                                                                                v-model="cabin"
                                                                                                name="cabin"
                                                                                                value="FIRST"
                                                                                                class="chk-cabin">
                                                                                            <label for="cb-firs"><span
                                                                                                    class="MuiButton-label">
                                                                                                    {{
                                                                                                        $t('FIRST')
                                                                                                    }}
                                                                                                </span>
                                                                                            </label>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span>{{ $t(cabin) }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="destinations.length < 5" class="row">
                                                    <div class="col-lg-12">
                                                        <div class="add_multy_form">
                                                            <button type="button" @click="addDestination">
                                                                + {{ $t('Add another flight') }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="isLogin && companies?.length > 0" class="col-md-12 p-0">
                                                    <div class="business-company mt-3">
                                                        <div class="container">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-3">
                                                                    <div class="flight_boxed_label v-hidden">
                                                                        <p>{{ $t('Departure Date') }}</p>
                                                                    </div>
                                                                    <LvToggleSwitch v-model="businessTravel"
                                                                        :label="$t('Corporate Travel')" />
                                                                </div>
                                                                <div v-if="businessTravel" class="col-md-3">
                                                                    <div class="flight_boxed_label">
                                                                        <p>{{ $t('Company') }}</p>
                                                                    </div>
                                                                    <div class="flight_Search_boxed">
                                                                        <select class="form-control" v-model="company"
                                                                            @change="companyChanged">
                                                                            <option v-for="item in companies"
                                                                                :key="item.id" :value="item.id">
                                                                                {{
                                                                                    item.company_name
                                                                                }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div v-if="businessTravel" class="col-md-4">
                                                                    <div class="flight_boxed_label">
                                                                        <p>{{ $t('travel-purposes') }}</p>
                                                                    </div>
                                                                    <div class="flight_Search_boxed">
                                                                        <select class="form-control" v-model="purpose"
                                                                            required>
                                                                            <option v-for="item in purposes"
                                                                                :key="item.id" :value="item.id">
                                                                                {{
                                                                                    item.title_for_employee
                                                                                }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="top_form_search_button">
                                                    <button type="button"
                                                        class="btn btn_theme btn_md btn-search-flight-next"
                                                        @click="search_flight">
                                                        {{ $t('Search Flight') }}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import $ from 'jquery';
import event from '@/useEvent'
import { debounce } from 'lodash';
import { toast } from 'vue3-toastify';
import jsonData from '@/assets/file/airports-v2.json';
import LvToggleSwitch from 'lightvue/toggle-switch';

export default {
    name: "Form",
    components: {
        LvToggleSwitch,
    },
    data() {
        return {
            number: 1,
            pnumber: 1,
            cnumber: 0,
            inumber: 0,
            cabin: 'ECONOMY',
            date: null,
            arrival_date: null,
            destinations: [
                {
                    departure: "",
                    departure_name: "",
                    is_departure_searching: false,
                    arrival: "",
                    arrival_name: "",
                    is_arrival_searching: false,
                    date: null,
                    noResults: false
                },
                {
                    departure: "",
                    departure_name: "",
                    is_departure_searching: false,
                    arrival: "",
                    arrival_name: "",
                    is_arrival_searching: false,
                    date: null,
                    noResults: false
                }
            ],
            businessTravel: false,
            isLogin: false,
            companies: null,
            company: null,
            purpose: null,
            purposes: null,
            requiredCompany: '',
            requiredPurpose: '',
            departure_name: '',
            arrival_name: '',
            is_departure_searching: false,
            is_arrival_searching: false,
            noResults: false,
            airports_data: [],
            departure_day: '',
            arrival_day: ''
        };
    },
    methods: {
        addDestination: function () {
            this.destinations.push({
                departure: "",
                departure_name: "",
                is_departure_searching: false,
                arrival: "",
                arrival_name: "",
                is_arrival_searching: false,
                date: null,
                noResults: false
            },);
        },
        deleteDestination(index) {
            this.destinations.splice(index, 1);
        },
        increment() {
            if ((parseInt(this.number)) < 9) {
                this.number++;
                this.pnumber++;
            } else {
                toast.error('تم بلوغ العدد الأقصى للركاب', {
                    autoClose: 2500,
                    position: toast.POSITION.TOP_RIGHT,
                    closeButton: true
                });
            }
        },
        decrement() {
            if (this.number === 0) return
            this.number -= 1

            if (this.pnumber === 0 || this.pnumber === 1) return

            this.pnumber -= 1
        },
        incrementc() {
            if ((parseInt(this.number)) < 9) {
                this.number++;
                this.cnumber++;
            } else {
                toast.error('تم بلوغ العدد الأقصى للركاب', {
                    autoClose: 2500,
                    position: toast.POSITION.TOP_RIGHT,
                    closeButton: true
                });
            }
        },
        decrementc() {
            if (this.number === 0) return
            this.number -= 1
            if (this.cnumber === 0) return
            this.cnumber -= 1
        },
        incrementi() {
            if ((parseInt(this.number)) < 9) {
                this.number++;
                this.inumber++;
            } else {
                toast.error('تم بلوغ العدد الأقصى للركاب', {
                    autoClose: 2500,
                    position: toast.POSITION.TOP_RIGHT,
                    closeButton: true
                });
            }
        },
        decrementi() {
            if (this.number === 0) return
            this.number -= 1
            if (this.inumber === 0) return
            this.inumber -= 1
        },
        companyChanged(val) {
            var url = `${this.$appUrl}/api/users/get_user_travel_purposes/${val}`;
            this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
                var res = response.data.purposes;
                this.purposes = res;
                if (res.length > 0) {
                    this.purpose = res[0].id;
                }
            });
        },

        //arrival
        handle_airports_search_arrival: debounce(function () {
            this.searchAirPortsArrival(this.arrival);
        }, 500),
        searchAirPortsArrival(val) {
            this.noResults = false;
            if (val == '') {
                this.is_arrival_searching = false;
                return;
            } else {
                this.is_arrival_searching = true;
            }
            this.airports_data = jsonData.filter(item => {
                if (item.ar_name == null ||
                    item.code == null ||
                    item.ar_city == null ||
                    item.ar_state == null ||
                    item.ar_country == null ||
                    item.en_name == null ||
                    item.en_city == null ||
                    item.en_state == null ||
                    item.en_country == null) {
                    return false;
                } else {
                    return item.ar_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.code.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_country.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_country.toLowerCase().includes(val.toLowerCase());
                }

            });
            if (this.airports_data.length == 0) {
                this.noResults = true;
            }
        },
        selectArrivalAirport(data) {
            this.is_arrival_searching = false;
            this.arrival = data['code'];
            this.arrival_name = data[this.$i18n.locale + '_name'];
        },

        //departure
        handle_airports_search_departure: debounce(function () {
            this.searchAirPortsDeparture(this.departure);
        }, 500),
        searchAirPortsDeparture(val) {
            this.noResults = false;
            if (val == '') {
                this.is_departure_searching = false;
                return;
            } else {
                this.is_departure_searching = true;
            }
            this.airports_data = jsonData.filter(item => {
                if (item.ar_name == null ||
                    item.code == null ||
                    item.ar_city == null ||
                    item.ar_state == null ||
                    item.ar_country == null ||
                    item.en_name == null ||
                    item.en_city == null ||
                    item.en_state == null ||
                    item.en_country == null) {
                    return false;
                } else {
                    return item.ar_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.code.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_country.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_country.toLowerCase().includes(val.toLowerCase());
                }

            });
            if (this.airports_data.length == 0) {
                this.noResults = true;
            }
        },
        selectDepartureAirport(data) {
            this.is_departure_searching = false;
            this.departure = data['code'];
            this.departure_name = data[this.$i18n.locale + '_name'];
        },


        //multi_city
        handle_airports_search_departure_multi_city: debounce(function (index) {
            this.searchAirPortsDepartureMultiCity(this.destinations[index].departure, index);
        }, 500),
        searchAirPortsDepartureMultiCity(val, index) {
            this.destinations[index].noResults = false;
            if (val == '') {
                this.destinations[index].is_departure_searching = false;
                return;
            } else {
                this.destinations[index].is_departure_searching = true;
            }
            this.airports_data = jsonData.filter(item => {
                if (item.ar_name == null ||
                    item.code == null ||
                    item.ar_city == null ||
                    item.ar_state == null ||
                    item.ar_country == null ||
                    item.en_name == null ||
                    item.en_city == null ||
                    item.en_state == null ||
                    item.en_country == null) {
                    return false;
                } else {
                    return item.ar_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.code.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_country.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_country.toLowerCase().includes(val.toLowerCase());
                }

            });
            if (this.airports_data.length == 0) {
                this.destinations[index].noResults = true;
            }
        },
        selectDepartureAirportMultiCity(data, index) {
            this.destinations[index].is_departure_searching = false;
            this.destinations[index].departure = data['code'];
            this.destinations[index].departure_name = data[this.$i18n.locale + '_name'];
        },
        handle_airports_search_arrival_multi_city: debounce(function (index) {
            this.searchAirPortsArrivalMultiCity(this.destinations[index].arrival, index);
        }, 500),
        searchAirPortsArrivalMultiCity(val, index) {
            this.destinations[index].noResults = false;
            if (val == '') {
                this.destinations[index].is_arrival_searching = false;
                return;
            } else {
                this.destinations[index].is_arrival_searching = true;
            }
            this.airports_data = jsonData.filter(item => {
                if (item.ar_name == null ||
                    item.code == null ||
                    item.ar_city == null ||
                    item.ar_state == null ||
                    item.ar_country == null ||
                    item.en_name == null ||
                    item.en_city == null ||
                    item.en_state == null ||
                    item.en_country == null) {
                    return false;
                } else {
                    return item.ar_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.code.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_country.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_country.toLowerCase().includes(val.toLowerCase());
                }

            });
            if (this.airports_data.length == 0) {
                this.destinations[index].noResults = true;
            }
        },
        selectArrivalAirportMultiCity(data, index) {
            this.destinations[index].is_arrival_searching = false;
            this.destinations[index].arrival = data['code'];
            this.destinations[index].arrival_name = data[this.$i18n.locale + '_name'];
        },

        search_flight() {
            var type = $('#myTabContent1 .tab-pane.active').attr('id');
            if (type == 'oneway_flight') {
                var currency = localStorage.getItem("currency"),
                    from = this.departure,
                    to = this.arrival,
                    adults = this.pnumber,
                    children = this.cnumber,
                    infants = this.inumber,
                    cabin = this.cabin,
                    nonStop = false,
                    max = 250,
                    maxPrice = "",
                    ret_date = '',
                    date = new Date(this.date).toLocaleDateString('sv-SE');
                if (this.businessTravel && (!this.purpose || !this.company)) {
                    if (!this.purpose) {
                        toast.error('الغرض من السفر مطلوب', {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                    }
                    if (!this.company) {
                        toast.error('الشركة مطلوبة', {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                    }
                    return;
                }
                if (from == '' || to == '') {
                    toast.error('Invalid Location', {
                        autoClose: 2500,
                        position: toast.POSITION.TOP_RIGHT,
                        closeButton: true
                    });
                } else {
                    var data = {
                        "from": from,
                        "to": to,
                        "date": date,
                        "ret_date": ret_date,
                        "adults": adults,
                        "children": children,
                        "infants": infants,
                        "cabin": cabin
                    };
                    localStorage.setItem("searchData", JSON.stringify(data));
                    localStorage.setItem("isBusiness", this.businessTravel);
                    if (this.businessTravel) {
                        localStorage.setItem("company", this.company);
                        localStorage.setItem("purpose", this.purpose);
                    } else {
                        localStorage.removeItem("company", this.company);
                        localStorage.removeItem("purpose", this.purpose);
                    }
                    this.$router.push(`/flight-search-result?currency=${currency}&from=${from}&to=${to}&date=${date}&ret_date=${ret_date}&adults=${adults}&children=${children}&infants=${infants}&cabin=${cabin}&nonStop=${nonStop}&maxPrice=${maxPrice}&max=${max}&type=oneway`);
                }
            } else if (type == 'roundtrip') {
                var currency = localStorage.getItem("currency"),
                    from = this.departure,
                    to = this.arrival,
                    date = new Date(this.date).toLocaleDateString('sv-SE'),
                    ret_date = new Date(this.arrival_date).toLocaleDateString('sv-SE'),
                    adults = this.pnumber,
                    children = this.cnumber,
                    infants = this.inumber,
                    cabin = this.cabin,
                    nonStop = false,
                    max = 250,
                    maxPrice = "";
                if (this.businessTravel && (!this.purpose || !this.company)) {
                    if (!this.purpose) {
                        this.requiredPurpose = 'md-invalid';
                    } else {
                        this.requiredPurpose = '';
                    }
                    if (!this.company) {
                        this.requiredCompany = 'md-invalid';
                    } else {
                        this.requiredCompany = '';
                    }
                    return;
                }
                if (from == '' || to == '') {
                    toast.error('Invalid Location', {
                        autoClose: 2500,
                        position: toast.POSITION.TOP_RIGHT,
                        closeButton: true
                    });
                } else {
                    var data = {
                        "from": from,
                        "to": to,
                        "date": date,
                        "ret_date": ret_date,
                        "adults": adults,
                        "children": children,
                        "infants": infants,
                        "cabin": cabin
                    };
                    localStorage.setItem("searchData", JSON.stringify(data));
                    localStorage.setItem("isBusiness", this.businessTravel);
                    if (this.businessTravel) {
                        localStorage.setItem("company", this.company);
                        localStorage.setItem("purpose", this.purpose);
                    } else {
                        localStorage.removeItem("company", this.company);
                        localStorage.removeItem("purpose", this.purpose);
                    }
                    this.$router.push(`/flight-search-result?currency=${currency}&from=${from}&to=${to}&date=${date}&ret_date=${ret_date}&adults=${adults}&children=${children}&infants=${infants}&cabin=${cabin}&nonStop=${nonStop}&maxPrice=${maxPrice}&max=${max}&type=roundtrip`);
                }
            } else {
                var allValid = true;
                $.each(this.destinations, function (index, city) {
                    if (city.departure.trim() === '' || city.arrival.trim() === '') {
                        allValid = false;
                        return false;
                    }
                });
                if (allValid) {
                    var currency = localStorage.getItem("currency"),
                        adults = this.pnumber,
                        children = this.cnumber,
                        infants = this.inumber,
                        cabin = this.cabin,
                        nonStop = false,
                        max = 250,
                        maxPrice = "";
                    var data = {
                        "adults": adults,
                        "children": children,
                        "infants": infants,
                        "cabin": cabin
                    };
                    var info = ``;
                    $.each(this.destinations, function (index, item) {
                        info += `&from[]=${item.departure}`;
                    });
                    $.each(this.destinations, function (index, item) {
                        info += `&to[]=${item.arrival}`;
                    });
                    $.each(this.destinations, function (index, item) {
                        info += `&date[]=${new Date(item.date).toLocaleDateString('sv-SE')}`;
                    });
                    localStorage.setItem("searchData", JSON.stringify(data));
                    localStorage.setItem("isBusiness", this.businessTravel);
                    if (this.businessTravel) {
                        localStorage.setItem("company", this.company);
                        localStorage.setItem("purpose", this.purpose);
                    } else {
                        localStorage.removeItem("company", this.company);
                        localStorage.removeItem("purpose", this.purpose);
                    }
                    this.$router.push(`/flight-search-result?currency=${currency}${info}&adults=${adults}&children=${children}&infants=${infants}&cabin=${cabin}&nonStop=${nonStop}&maxPrice=${maxPrice}&max=${max}&type=multi_city`);
                } else {
                    toast.error('Invalid Location', {
                        autoClose: 2500,
                        position: toast.POSITION.TOP_RIGHT,
                        closeButton: true
                    });
                    return;
                }
            }
        },
        get_user_companies() {
            var url = `${this.$appUrl}/api/users/get_user_companies`;
            this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
                var res = response.data.companies;
                this.companies = res;
                if (response.data.status == 1) {
                    if (res.length > 0) {
                        this.company = res[0].id;
                        var url = `${this.$appUrl}/api/users/get_user_travel_purposes/${this.company}`;
                        this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
                            var res = response.data.purposes;
                            this.purposes = res;
                            if (response.data.status == 1) {
                                if (res.length > 0) {
                                    this.purpose = res[0].id;
                                }
                            }
                        });
                    }
                }
            });
        },
        Jquery() {
            $(document).on('click', '.range_plan', function () {
                var departure = $(this).parent().parent().parent().find('.departure').val(),
                    arrival = $(this).parent().parent().parent().find('.arrival').val(),
                    departure_name = $(this).parent().parent().parent().find('.departure_name').html(),
                    arrival_name = $(this).parent().parent().parent().find('.arrival_name').html();
                $(this).parent().parent().parent().find('.departure').val(arrival);
                $(this).parent().parent().parent().find('.arrival').val(departure);
                $(this).parent().parent().parent().find('.departure_name').html(arrival_name);
                $(this).parent().parent().parent().find('.arrival_name').html(departure_name);
            });
        },
    },
    mounted() {
        var that = this;
        this.Jquery();
        if (localStorage.getItem("access_token")) {
            this.isLogin = localStorage.getItem("access_token");
            this.get_user_companies();
        }
        event.on('login-event', function (data) {
            that.isLogin = data.isLogin;
            if (data.isLogin) {
                that.get_user_companies();
            }
        });
    }
};
</script>
<style scoped>
.form-control {
    background: #fafafa;
    border: none;
}

.form-control:focus {
    color: var(--heading-color);
    background-color: #fff;
    border: none;
    border: 1px solid #fafafa;
    outline: none;
    box-shadow: none;
}
</style>
<style lang="scss">
.ui-id-1,
.ui-id-2 {
    max-height: calc(100vh - 335px);
    overflow-y: auto;
    border: 1px solid #f5eefd;
    background: #fff;
    color: #333;
    cursor: default;
    list-style: none;
    padding: 0;
    display: block;
    outline: 0;
    margin: 0px 12px;
    border-radius: 8px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #e3e3e3;
        border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--main-color);
        border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--main-color);
    }
}

.ui-autocomplete {
    position: absolute;
    top: 110px;
    right: 0px;
    left: 0px;
    text-align: initial;
    z-index: 1060;
}
.ui-menu .ui-menu-item.fl-route-list.ui-autocomplete-category {
    pointer-events: none;
    opacity: 1 !important;
}

.fl-route-list {
    border: 1px solid transparent;
    border-bottom-color: #f0eeee;
    cursor: pointer;
}

.fl-route-list.ui-autocomplete-category .fl-routes,
.fl-route-list.ui-autocomplete-category .fl-routes:hover {
    pointer-events: none;
    color: var(--main-color);
    ;
    cursor: pointer;
}

.fl-routes {
    display: block;
    outline: 0 !important;
    text-decoration: none !important;
    padding: 10px !important;
    border-color: transparent !important;
    border-bottom-color: #f0eeee;
    position: relative;
    white-space: normal;
}

.inline-block {
    display: inline-block;
}

.fl-loc {
    font-weight: 600;
}

.fl-routes .fl-country {
    display: block;
    font-size: 12px;
    margin-top: 3px;
    color: #b3b3b3;
}

.rtl .pull-right {
    float: left !important;
}

.fl-routes .fl-route-code {
    font-size: 18px;
    margin: 0;
    margin-top: 5px;
    font-weight: 700;
}

.range_plan {
    cursor: pointer;
}

.dp__input_readonly {
    padding-right: 40px !important;
}

input.chk-cabin {
    display: none;
}

.label-select-btn:has(input:checked) {
    background: var(--main-color);
    color: var(--white-color);
}

.label-select-btn label {
    width: 100%;
    text-align: initial;
}

.passengers-type {
    i {
        vertical-align: middle;
    }
}
</style>