<template>
    <section id="about_us_top" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="about_us_left">
                        <h2>{{ title }}</h2>
                        <p>{{ aboutInfo1 }} </p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about_us_right">
                        <img src="../../assets/img/common/abour_right.png" alt="img">
                    </div>
                </div>

            </div>
        </div>
    </section>

</template>
<script>

export default {
    name: "AboutInfo",
    data() {
        return {
            title: 'التجربة السلسة لسفر الشركات',
            aboutInfo1: `جي سكاي هو حل سفر متكامل للشركات يقدم منتجات سفر للشركات وموظفيها ليجمع ما بين أفضل تجارب الحجوزات للموظفين وأفضل تجربة لإدارة حلول السفر بالكامل.`,
        };
    }
};
</script>