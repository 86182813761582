import Api from "@/api/api";
import authHeader from "@/services/auth/auth-header";
const END_POINT = "api/users/passengers/";
class PassengerService {
    get_all_passengers(params) {
        return Api.get(END_POINT + "all", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    add_passenger(body) {
        return Api.post(END_POINT + "store", body, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    edit_passenger(id) {
        return Api.get(END_POINT + "edit/" + id, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    update_passenger(body, id) {
        return Api.post(END_POINT + "update/" + id, body, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new PassengerService();