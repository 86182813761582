<template>
    <section id="dashboard_main_arae" class="section_padding">
        <div class="container">
            <div class="row mb20  justify-content-between">
                <div class="col-md-4 col-8">
                    <div class="frm-js-lbl">
                        <input v-model="searchQuery" class="form-control pass-search w-100" autocomplete="off"
                            :placeholder="$t('Search')" @input="filter_passengers">
                    </div>
                </div>
                <div class="col-md-2 col-4 col-middle">
                    <button class="btn btn_theme w-100" @click="add_drawer = !add_drawer"
                        data-target="#accompany_passenger_modal" data-toggle="modal" data-type="adult"
                        data-backdrop="static">
                        <span class="inline-middle">{{ $t('New card') }}</span>
                    </button>
                </div>
            </div>
            <div class="row mt-5">
                <div v-for="card in credit_cards" class="col-lg-4 mb-5">
                    <a href="javascript:void(0);" class="delete-card" @click="delete_card(card.id)">
                        <i class="far fa-trash-alt text-danger"></i>
                        <p v-if="card.is_primary == 1" class="main-color">{{ $t('primary') }}</p>
                    </a>
                    <vue-paycard @click="card.is_primary != 1 ? set_primary_card(card.id) : null" :value-fields="card"
                        :hasRandomBackgrounds="false" />
                </div>
            </div>
        </div>
        <lv-drawer v-model="add_drawer" left :headerTitle="$t('New card')" headerColor="#607c8a" close shadow
            background="#fff" :zIndex="9999">
            <form @submit.prevent="add_card" class="needs-validation add-new-passenger" novalidate>
                <div class="drawer-container">
                    <div class="row">
                        <div class="col-md-12">
                            <vue-paycard :inputFields="add_card_body" :value-fields="add_card_body"
                                :hasRandomBackgrounds="false" @get-type="set_card_type" />
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <label>
                                Card Number
                            </label>
                            <input v-model="add_card_body.cardNumber" type="text" class="input"
                                placeholder="Card Number" @input="onInputChange" dir="ltr" maxlength="19" minlength="19" required>
                        </div>
                        <div class="col-md-6">
                            <label>
                                Card Holder
                            </label>
                            <input v-model="add_card_body.cardName" type="text" class="input"
                                :placeholder="$t('Card Holder')" pattern="^[a-zA-Z\s]+$" required>
                        </div>
                        <div class="col-md-6">
                            <label>
                                {{ $t('Expiration Date') }}
                            </label>
                            <div class="expiration-date">
                                <select v-model="add_card_body.cardMonth" class="input" required>
                                    <option value="">Month</option>
                                    <option v-for="month in months" :key="month.value" :value="month.value">{{
                                        month.text }}</option>
                                </select>
                                <select v-model="add_card_body.cardYear" class="input" required>
                                    <option value="">Year</option>
                                    <option v-for="year in years" :key="year" :value="year.value">{{ year.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-3 drawer-footer">
                    <div class="col-md-12 d-flex">
                        <span class="mr-3">
                            <button type="submit" class="btn-primary">{{ $t('Save') }}</button>
                        </span>
                        <span>
                            <button type="button" class="btn-outline-primary" @click="add_drawer = !add_drawer">{{
                                $t('cancel') }}
                            </button>
                        </span>
                    </div>
                </div>
            </form>
        </lv-drawer>
    </section>
</template>
<script>
import { toast } from 'vue3-toastify';
import LvDrawer from 'lightvue/drawer';
import creditCardService from '@/services/credit-cards/credit-card-service';

export default {
    name: "CreditCards",
    data() {
        return {
            add_drawer: false,
            selectedYear: new Date().getFullYear(),
            selectedMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'),
            credit_cards: [],
            add_card_body: {
                cardName: '',
                cardNumber: '',
                cardMonth: '',
                cardYear: '',
                card_brand: ''
            }
        }
    },
    components: { LvDrawer },
    computed: {
        years() {
            const currentYear = new Date().getFullYear();
            const futureYears = 11;
            let yearsArray = [];
            for (let i = 0; i <= futureYears; i++) {
                yearsArray.push({ value: currentYear + i, text: currentYear + i });
            }
            return yearsArray;
        },
        months() {
            return [
                { value: '01', text: '01' },
                { value: '02', text: '02' },
                { value: '03', text: '03' },
                { value: '04', text: '04' },
                { value: '05', text: '05' },
                { value: '06', text: '06' },
                { value: '07', text: '07' },
                { value: '08', text: '08' },
                { value: '09', text: '09' },
                { value: '10', text: '10' },
                { value: '11', text: '11' },
                { value: '12', text: '12' },
            ];
        }
    },
    methods: {
        set_card_type(type) {
            this.add_card_body.card_brand = type;
        },
        onInputChange() {
            this.add_card_body.cardNumber = this.formatCardNumber(this.add_card_body.cardNumber);
        },
        set_primary_card(id) {
            this.$swal({
                title: this.$t('are you sure?'),
                text: this.$t('The card will be set as the primary payment card'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#05a34a',
                cancelButtonColor: '#d33',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('continue'),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    $('.pre-loader').show();
                    await creditCardService.set_card_primary(id, { lang: localStorage.getItem('locale') }).then(response => {
                        this.get_all_cards();
                        if (response.data.status == 1) {
                            toast.success(response.data.message, {
                                autoClose: 2500,
                                position: toast.POSITION.TOP_RIGHT,
                                closeButton: true
                            });
                        } else {
                            toast.erorr(response.data.message, {
                                autoClose: 2500,
                                position: toast.POSITION.TOP_RIGHT,
                                closeButton: true
                            });
                        }
                    });
                }
            });
        },
        delete_card(id) {
            this.$swal({
                title: this.$t('are you sure?'),
                text: this.$t('You will not be able to restore this item!'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#05a34a',
                cancelButtonColor: '#d33',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('delete'),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    $('.pre-loader').show();
                    await creditCardService.delete_card(id, { lang: localStorage.getItem('locale') }).then(response => {
                        this.get_all_cards();
                        if (response.data.status == 1) {
                            toast.success(response.data.message, {
                                autoClose: 2500,
                                position: toast.POSITION.TOP_RIGHT,
                                closeButton: true
                            });
                        } else {
                            toast.erorr(response.data.message, {
                                autoClose: 2500,
                                position: toast.POSITION.TOP_RIGHT,
                                closeButton: true
                            });
                        }
                    });
                }
            });
        },
        async get_all_cards() {
            $('.pre-loader').addClass('white');
            $('.pre-loader').show();
            this.credit_cards = [];
            await creditCardService.get_all_cards().then(response => {
                var cards = response['data']['data']
                cards.forEach(element => {
                    this.credit_cards.push({
                        id: element['id'],
                        status: element['status'],
                        is_primary: element['is_primary'],
                        cardName: element['holder_name'],
                        cardNumber: this.formatCardNumber(element["card_number"]),
                        cardMonth: element["expiration_month"],
                        cardYear: '20' + element["expiration_year"],
                        setType: element["brand_name"]
                    })
                });
                $('.pre-loader').hide();
            });
        },
        async add_card() {
            var isValid = false;
            var forms = document.querySelectorAll('.needs-validation')
            Array.prototype.slice.call(forms)
                .forEach(function (form) {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                        isValid = false;
                    } else {
                        isValid = true;
                    }
                    form.classList.add('was-validated')
                });
            if (isValid) {
                $('.pre-loader').show();
                var body = {
                    brand_name: this.add_card_body.card_brand == 'mastercard' ? 'MASTER' : (this.add_card_body.card_brand == 'visa' ? 'VISA' : 'MADA'),
                    card_number: this.add_card_body.cardNumber.replace(/\s+/g, ''),
                    holder_name: this.add_card_body.cardName,
                    expiration_month: this.add_card_body.cardMonth,
                    expiration_year: this.add_card_body.cardYear - 2000,
                };
                await creditCardService.add_card(body,{ lang: localStorage.getItem('locale') }).then(response => {
                    $('.pre-loader').hide();
                    if (response.data.status == 1) {
                        this.add_drawer = false;
                        this.get_all_cards();
                        toast.success(response.data.message, {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                    } else {
                        toast.error(response.data.message, {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                    }
                });
            } else {
                toast.error('جميع الحقول مطلوبة', {
                    autoClose: 2500,
                    position: toast.POSITION.TOP_LEFT,
                    closeButton: true
                });
            }
        },
        formatCardNumber(cardNumber) {
            return cardNumber.replace(/(\d{4})(?=\d)/g, "$1 ").trim();
        },
    },
    mounted() {
        this.get_all_cards();
    }
};
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/credit-cards.scss';
</style>