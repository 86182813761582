export default function authHeader() {
    let access_token = localStorage.getItem("access_token");
    const lang = localStorage.getItem("lang") || "ar";
    if (access_token) {
        return {
            "Authorization": "Bearer " + access_token,
            "Accept": "*/*",
            "Content-Type": "application/json",
        };
    } else {
        return {
            lang: lang,
        };
    }
}