<template>
    <section id="contact_main_arae" class="section_padding">
        <div class="container">
            <div class="contact_main_form_area">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section_heading_center">
                            <h2>{{ $t('Leave us a message') }}</h2>
                        </div>
                        <div class="contact_form">
                            <form action="!#" id="contact_form_content">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control bg_input"
                                                :placeholder="$t('First Name') + '*'">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control bg_input"
                                                :placeholder="$t('Last Name') + '*'">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control bg_input"
                                                :placeholder="$t('Email Address')">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control bg_input"
                                                :placeholder="$t('Mobile Number') + '*'">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <textarea class="form-control bg_input" rows="5"
                                                :placeholder="$t('Message')"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <button type="button" class="btn btn_theme btn_md">
                                                {{ $t('Send message') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: "ContactArea",
    data() {
        return {
            active: false,
        }
    },
    methods: {
        modalToggle() {
            const body = document.querySelector("body")
            this.active = !this.active
            this.active ? body.classList.add("modal-open") : body.classList.remove("modal-open")
        },
    },
};
</script>