<template>
    <div>
        <footer id="footer_area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 footer_logo_area">
                        <div class="footer_heading_area">
                            <img src="../../assets/img/footer-logo.png">
                        </div>
                        <div class="footer_first_area">
                            <div class="footer_inquery_area">
                                <ul class="soical_icon_footer">
                                    <li><a class="active" href="javascript:void(0);"><i class="fab fa-facebook"></i></a></li>
                                    <li><a href="javascript:void(0);"><i class="fab fa-twitter-square"></i></a></li>
                                    <li><a href="javascript:void(0);"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="javascript:void(0);"><i class="fab fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>{{$t('about')}} <span> JSKY </span></h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><router-link to="/about">{{ $t('About Us') }}</router-link></li>
                                <li><a :href="this.$businessAppUrl">{{ $t('Jsky Business') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>{{ $t('Support') }}</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><router-link to="/contact">{{ $t('Contact Us') }}</router-link></li>
                                <li><router-link to="/faq">{{ $t('FAQ') }}</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>{{ $t('Legal') }}</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><router-link to="/top-destinations-details">{{ $t('Terms and Conditions')
                                        }}</router-link>
                                </li>
                                <li><router-link to="/top-destinations-details">{{ $t('Privacy Policy') }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>{{ $t('Download app') }}</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li>
                                    <a href="https://apps.apple.com/">
                                        <i class="appstore"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://play.google.com/">
                                        <i class="googleplay"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div class="copyright_area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <hr>
                    </div>
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                        <ul data-v-5e628c52="" class="navbar-nav">
                            <li data-v-5e628c52="" class="nav-item"><a data-v-5e628c52="" href="/"
                                    class="router-link-active router-link-exact-active nav-link"
                                    aria-current="page">الرئيسية</a></li>
                            <li data-v-5e628c52="" class="nav-item"><a data-v-5e628c52="" href="/testimonials"
                                    class="nav-link">تجارب المستخدمين</a></li>
                            <li data-v-5e628c52="" class="nav-item"><a data-v-5e628c52="" href="/about"
                                    class="nav-link">من نحن</a></li>
                            <li data-v-5e628c52="" class="nav-item"><a data-v-5e628c52="" href="/contact"
                                    class="nav-link">تواصل معنا</a></li>
                        </ul>
                    </div>
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="copyright_left">
                            <p>{{ $t('allRights') }} {{ currentYear }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        }
    },
}
</script>
