<template>
  <Preloader />
  <Header ref="headerRef" />
  <router-view />
  <Footer />
  <ChatButton />
  <div class="modal fade" id="login-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body p-0" >
            <div class="modal-content get-code-div">
              <div class="mdl-close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fl-ico close"></i>
              </div>
              <div class="modal-header mt15 text-center">
                <h3>{{ $t('Enter Number') }}</h3>
              </div>
              <div class="clearfix p15">
                <div class="col-md-12  text-center">
                  <img src="@/assets/img/20944201.jpg" style="width: 60%;" />
                </div>
                <div class="col-md-12">
                  <p class="text-center mb-3">{{ $t('The code will be sent to the number') }}</p>
                  <div class="fa-input-group fix-ltr">
                    <div class="btn-group bootstrap-select fa-custom-picker user-ccode fix-ltr dropup"
                      data-value="Saudi Arabia">
                      <div class="btn btn-default">
                        <span class="filter-option current pull-left">
                          <div class="cc-con">
                            <div class="cc-flag">
                              <span class="flag flag-sa"></span>
                              <span class="ml10 mr10 code">+966</span>
                            </div>
                          </div>
                        </span>&nbsp;
                      </div>
                      <div role="combobox" class="dropdown-menu open">
                        <div class="bs-searchbox" >
                          <input type="text" autocomplete="off" role="textbox" aria-label="Search" class="form-control">
                        </div>
                        <ul role="listbox" aria-expanded="true" class="dropdown-menu country-code">
                          <li v-for="code in CountryCodeDetails" :key="code.code" data-original-index="0">
                            <a href="javascript:void(0);" :data-code="code.code" :data-dial-code="code.dial_code">
                              <div class="cc-con">
                                <div class="cc-flag">
                                  <span class="ml10 mr10">
                                    {{ code.dial_code }}
                                    <span :class="'flag flag-' + (code.code.toLowerCase())"></span>
                                  </span>
                                </div>
                                <span class="location">
                                  {{ code.name }}
                                </span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <input type="hidden" class="mobile-code" value="+966">
                    </div>
                    <input class="form-control required-field mobile-num" target="mdl-mobile" type="text" maxlength="9"
                      placeholder="5XXXXXXXX">
                  </div>
                </div>
                <div class="col-md-12 mb-4">
                  <span class="small text-red inputmobile" id="mobile-num-error"></span>
                </div>
                <div class="col-md-12 mb-4">
                  <button class="btn btn-fa-primary btn-block btn-lg p15 btn-get-login-code" target="mdl-mobile">
                    {{ $t('Send Verification Code') }}
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-content otp-content" style="display: none;">
              <div class="mdl-close" data-dismiss="modal" aria-label="Close">
                <i class="fl-ico close"></i>
              </div>
              <div class="modal-header  mt15 text-center">
                <h3>أدخل رمز التحقق</h3>
                <div class="mr20 ml20">
                  <span>تم إرسال الرمز إلى </span>
                  <p class="mobile bold mt10 fix-ltr"></p>
                </div>
                <div class="col-md-12 text-center">
                  <a class="pointer btn-back-mdl-num second-color underline change-number">استخدام
                    رقم
                    مختلف</a>
                </div>
              </div>
              <div class="clearfix">
                <div id="mdl-log-verify" class="modal-body no-padding">
                  <div class="clearfix">
                    <div class="p15">
                      <div class="row no-margin p15">
                        <div class="col-md-12 text-center mb10 fix-ltr">
                          <div><span id="time">02:00</span></div>
                        </div>
                        <div class="col-md-12 mb30 form-code-container fix-ltr">
                          <input class="form-code number-en-only verification-code" maxlength="4">
                        </div>
                        <div class="col-md-12 mb-4">
                          <button class="btn btn-fa-primary btn-block btn-lg p15 btn-verify-login-code">تحقق
                            من رقم الجوال
                          </button>
                        </div>
                        <div class="col-md-12  text-center">
                          <button class="btn pointer btn-block btn-outline btn-resend-code">إعادة
                            إرسال
                            الرمز</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var that;
import $ from 'jquery'
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Preloader from '@/components/layout/Preloader'
import ChatButton from '@/components/layout/ChatButton.vue'
import countryCode from '@/assets/file/country-code.json'
import { toast } from 'vue3-toastify'
import event from '@/useEvent'

export default {
  name: 'App',
  data() {
    return {
      isLogin: false,
      user: null,
      CountryCodeDetails: [],
    }
  },
  components: {
    Header, Footer, Preloader, ChatButton
  },
  methods: {
    scrollToElementWithCode(code) {
      var targetElement = $('[data-code="' + code + '"]');
      if (targetElement.length > 0) {
        var offsetTop = targetElement.offset().top;
        $('.country-code').animate({ scrollTop: offsetTop + 80 }, 'slow');
      }
    },
    startTimer(duration, display) {
      var timer = duration, minutes, seconds;
      setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
          timer = duration;
        }
      }, 1000);
    },
    Jquery() {
      $(document).on('click', '.dropdown-menu-end a', function (e) {
        $('.dropdown-menu-end').hide();
      });
      $(document).on('keypress', function (e) {
        if (e.which == 13 && $('.mobile-num').is(":focus")) {
          $('.btn-get-login-code').click();
        }
      });
      $(document).on('input', '.verification-code', function (e) {
        if (($(this).val()).length == 4) {
          $('.btn-verify-login-code').click();
        }
      });
      $(document).on('click', '.btn-get-login-code', function () {
        var url = `${that.$appUrl}/api/users/auth/login`;
        var phone = $('.mobile-num').val(), code = $('.mobile-code').val();
        if (!phone) {
          $('.fa-input-group').addClass('red-border');
          return;
        }
        $('.pre-loader').show();
        let formData = new FormData();
        formData.append('phone', phone);
        formData.append('phone_country_code', code);
        formData.append('lang', that.$i18n.locale);
        const headers = {
          "Authorization": "Bearer my-token",
          "My-Custom-Header": "foobar"
        };
        that.axios.post(url, formData, { headers })
          .then(response => {
            $('.pre-loader').hide();
            if (response.data.status == 1) {
              $('.get-code-div').hide();
              $('.otp-content').css('display', 'flex');
              $('.mobile').html(response.data.phone_country_code + response.data.phone);
              $('.btn-verify-login-code').data('phone', response.data.phone);
              var fiveMinutes = 60 * 2,
                display = document.querySelector('#time');
              that.startTimer(fiveMinutes, display);
            } else {
              toast.error(response.data.message, {
                autoClose: 2500,
                position: toast.POSITION.TOP_RIGHT,
                closeButton: true
              });
            }
          });

      });
      $(document).on('input', '.bs-searchbox input', function () {
        var val = $(this).val();
        that.CountryCodeDetails = Object.values(countryCode).filter(item => {
          return (that.$i18n.locale == 'ar' ? item.name.includes(val) : item.en_name.includes(val)) || item.dial_code.includes(val);
        });
      });
      $(document).on('click', 'li .dropdown-toggle', function () {
        $(this).parent().find('.noti-bar').css('display', 'block');
        $(this).parent().find('ul').css('display', 'block');
      });
      $(document).on('click', '.country-code li a', function () {
        var code = $(this).data('code'),
          dial_code = $(this).data('dial-code'),
          flag = $('.filter-option.current .flag');
        $('.filter-option.current .flag').removeClass();
        $('.dropdown-menu.open').hide();
        $('.mobile-code').val(dial_code);
        $('.filter-option.current .code').html(dial_code);
        flag.addClass('flag flag-' + code.toLowerCase());
        that.CountryCodeDetails = countryCode;
      });
      $(document).on('click', '.filter-option', function () {
        that.scrollToElementWithCode($('.mobile-code').val());
        $('.dropdown-menu.open').show();
      });
      $(document).on('click', '.btn-verify-login-code', function () {
        var phone = $(this).data("phone");
        var code = $('.verification-code').val();
        var url = `${that.$appUrl}/api/users/auth/CheckCode`;
        $('.pre-loader').show();
        let formData = new FormData();
        formData.append('phone', phone);
        formData.append('code', code);
        formData.append('lang', that.$i18n.locale);
        const headers = {
          "Authorization": "Bearer my-token",
          "My-Custom-Header": "foobar"
        };
        that.axios.post(url, formData, { headers })
          .then(response => {
            $('.pre-loader').hide();
            if (response.data.status == 1) {
              localStorage.setItem("access_token", response.data.access_token);
              that.isLogin = true;
              window.$('#login-modal').modal('toggle');
              var url = `${that.$appUrl}/api/users/get_user_data`;
              that.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
                var res = response.data.user;
                that.user = res;
                event.emit('login-event', { user: res, isLogin: true });
              });
            } else {
              toast.error(response.data.message, {
                autoClose: 2500,
                position: toast.POSITION.TOP_RIGHT,
                closeButton: true
              });
            }
          });
      });
    }
  },
  mounted() {
    that = this;
    this.Jquery();
    this.CountryCodeDetails = countryCode;
  }
}
</script>