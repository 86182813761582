<template>
    <BookingDetailsBanner />
    <BookingDetails />
</template>
<script>
import BookingDetailsBanner from '@/components/user/booking/BookingDetailsBanner.vue'
import BookingDetails from '@/components/user/booking/BookingDetails.vue'

export default {
    name: "BookingDetailsView",
    components: {
        BookingDetailsBanner, BookingDetails
    }
};
</script>