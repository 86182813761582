<template>

    <!-- Common Banner Area -->
    <WalletBanner />

    <!-- Dashboard Area -->
    <Wallet />

</template>
<script>

import WalletBanner from '@/components/user/wallet/WalletBanner.vue'
import Wallet from '@/components/user/wallet/Wallet.vue'
export default {
    name: "WalletView",
    components: {
        WalletBanner, Wallet
    }
};
</script>
