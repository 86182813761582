<template>
    <section id="jsky_solutions_area" class="section_padding_top jsky_solutions_area mt-5">
        <div class="container">
            <!-- Section Heading -->
            <div class="row align-items-center mb-5">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="right-side" style="">
                        <h2>
                            {{ $t('Provides travel booking solutions') }}
                            <span class="main-color">JSKY</span>
                            <br>
                            {{ $t('Institutional and corporate travel management') }}
                        </h2>
                        <p>{{ $t('JSKY allows companies to book tickets') }}</p>
                        <button type="button" class="btn btn_theme btn_sm">{{ $t('more') }}</button>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12 text-right">
                    <img src="@/assets/img/jsky_solutions.png">
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "JskySolutions",
};
</script>