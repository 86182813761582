<template>

    <!-- Common Banner Area -->
    <BookingBanner />

    <!-- Dashboard Area -->
    <Booking />
</template>
<script>
import BookingBanner from '@/components/user/booking/BookingBanner.vue'
import Booking from '@/components/user/booking/Booking.vue'

export default {
    name: "BookingView",
    components: {
        BookingBanner, Booking
    }
};
</script>