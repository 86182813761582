<template>
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>تفاصيل البنك</h2>
                        <ul>
                            <li><router-link to="/">{{ $t('Home') }}</router-link>
                            </li>
                            <li>
                                <span>
                                    <i class="fas fa-circle"></i>
                                </span>
                                <router-link :to="'/flight-booking/' + this.$route.params.id">
                                    {{ $t('business-booking-details') }}
                                </router-link>
                            </li>
                            <li><span><i class="fas fa-circle"></i></span> تفاصيل البنك</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <BookingBankDetails />
</template>
<script>
import BookingBankDetails from '@/components/flights/BookingBankDetails.vue'
export default {
    name: "BookingBankDetailsView",
    components: {
        BookingBankDetails
    }
};
</script>