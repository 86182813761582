<template>
    <section id="tour_booking_bank_details" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <div class="bank-content text-left">
                        <ul id="transfer-details" class="wallet-bank-list">
                            <li class="row align header">
                                <div class="col-md-6 no-padding col-middle">
                                    <h4 class="bold prime-color inline-middle no-margin">
                                        تفاصيل البنك
                                    </h4>
                                </div>
                                <div class="col-md-6 no-padding col-middle text-right">
                                    <img id="wallet-bank-logo" class="wallet-bank-icon" :src="bank.bank_logo">
                                </div>
                            </li>
                            <li class="row align">
                                <div class="col-md-6 col-middle text-left">
                                    <span>
                                        المبلغ :
                                    </span>
                                </div>
                                <div class="col-md-6 col-middle text-right bold">
                                    <span>{{ total.toFixed(2) }} {{ $t(currency ?? '') }}</span>
                                </div>
                            </li>
                            <li class="row align">
                                <div class="col-md-4 col-middle text-left">
                                    <span>
                                        اسم
                                        الحساب :
                                    </span>
                                </div>
                                <div class="col-md-4 col-middle text-right">
                                    <span>{{ bank.account_name }}</span>
                                </div>
                                <div class="col-md-4 col-middle text-right">
                                    <a href="javascript:void(0);" @click="copyText(bank.account_name)">
                                        <i class="fa fa-copy"></i>
                                    </a>
                                </div>
                            </li>
                            <li class="row align">
                                <div class="col-md-4 col-middle text-left">
                                    <span>
                                        رقم
                                        الحساب :
                                    </span>
                                </div>
                                <div class="col-md-4 col-middle text-right">
                                    <span class="inline-middle pointer btn-copy-to-clipboard">
                                        {{ bank.account_number }}
                                    </span>
                                </div>
                                <div class="col-md-4 col-middle text-right">
                                    <a href="javascript:void(0);" @click="copyText(bank.account_number)">
                                        <i class="fa fa-copy"></i>
                                    </a>
                                </div>
                            </li>
                            <li class="row align">
                                <div class="col-md-4 col-middle text-left">
                                    <span>آيبان :</span>
                                </div>
                                <div class="col-md-4 col-middle text-right">
                                    <span class="inline-middle pointer btn-copy-to-clipboard">
                                        {{ bank.iban_number }}
                                    </span>
                                </div>
                                <div class="col-md-4 col-middle text-right">
                                    <a href="javascript:void(0);" @click="copyText(bank.iban_number)">
                                        <i class="fa fa-copy"></i>
                                    </a>
                                </div>
                            </li>
                            <li class="row align">
                                <div class="col-md-6 col-middle text-left">
                                    <span>
                                        اخر موعد للسداد :
                                    </span>
                                </div>
                                <div class="col-md-6 col-middle text-right bold text-danger">
                                    <span>
                                        {{ deadlineDate() }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-if="receipt_src != null" class="bank-content text-center">
                        <h4 class="mb-3">
                            صورة الإيصال
                        </h4>
                        <img :src="receipt_src">
                        <button type="button" class="btn btn-danger btn_md w-50 mt-4" @click="clear_receipt_input">
                            تغيير
                        </button>
                    </div>
                    <div class="mb20">
                        <p class="prime-color">تعليمات</p>
                        <p>
                            <span class="prime-color">1.</span>
                            <span>يمكنك التحويل من خلال خدمات الانترنت للبنوك ، الصراف ، أو من
                                خلال زيارة الفرع.</span>
                        </p>
                        <p>
                            <span class="prime-color">2.</span>
                            <span>الرجاء رفع صورة واضحة لإيصال التحويل</span>
                        </p>
                        <p>
                            <span class="prime-color">3.</span>
                            <span class="bankReceipt">
                                <span class="bankReceipt">سيتم تحديث
                                    الرصيد بعد ٢٤ ساعة من استلام طلب التحويل
                                </span>
                            </span>
                        </p>
                    </div>
                    <div class="mb20">
                        <input type="file" class="d-none" id="receipt" accept="image/x-png, image/jpg, image/jpeg"
                            @change="onFileChange">
                        <button type="button" class="btn btn_theme btn_md w-100" @click="upload_receipt">
                            <span v-if="receipt_src == null">
                                تحميل صورة الإيصال
                            </span>
                            <span v-else>
                                إرسال
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import $ from 'jquery';
import { toast } from 'vue3-toastify';
export default {
    name: 'BookingBankDetails',
    data: () => ({
        bank: [],
        total: 0,
        currency: null,
        created_at: null,
        receipt_src: null,
        selectedFile: null,
        booking_id: null
    }),
    methods: {
        copyText(text) {
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = text;
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            tempTextArea.setSelectionRange(0, 99999);
            document.execCommand('copy');
            document.body.removeChild(tempTextArea);
            alert('Text copied to clipboard');
        },
        deadlineDate() {
            var currentLocale = 'en-US';
            var now = new Date(this.created_at);
            var futureTime = new Date(now.getTime() + 1 * 60 * 60 * 1000);
            var options = {
                day: '2-digit',
                month: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            };
            return new Intl.DateTimeFormat(currentLocale, options).format(futureTime);
        },
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.receipt_src = e.target.result;
                };
                reader.readAsDataURL(file);
                this.selectedFile = file;
            }
        },
        async upload_receipt() {
            if ($('#receipt').val() == '') {
                $('#receipt').click();
            } else {
                $('.pre-loader').show();
                const formData = new FormData();
                formData.append('booking_id', this.booking_id);
                formData.append('attachment', this.selectedFile);
                formData.append('lang', localStorage.getItem('locale'));
                try {
                    await this.axios.post(`${this.$appUrl}/api/flights/order/send_receipt_image`, formData, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }).then(response => {
                        $('.pre-loader').hide();
                        toast.success("تم ارسال الإيصال بنجاح", {
                            autoClose: 2500,
                            position: toast.POSITION.TOP_RIGHT,
                            closeButton: true
                        });
                        setTimeout(() => {
                            this.$router.push('/flight-booking/' + this.booking_id);
                        }, 1500);
                    });
                } catch (error) {
                    console.error('Error uploading file', error);
                }
            }
        },
        clear_receipt_input() {
            $('#receipt').val('');
            this.receipt_src = null;
        },
    },
    mounted() {
        $('.pre-loader').addClass('white');
        $('.pre-loader').show();
        var url = `${this.$appUrl}/api/users/booking/request/show/${this.$route.params.id}`;
        this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
            var booking = response.data.data;
            this.bank = booking.bank;
            this.total = booking.total;
            this.created_at = booking.created_at;
            this.booking_id = booking.id;
            this.currency = booking.currency;
            $('.pre-loader').hide();
        });
    }
}
</script>
<style lang="scss" scoped>
.col-md-offset-3 {
    margin-right: 25%;
    margin-left: 0;
}
.bank-content{
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 3px;
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.04), 5px 8px 16px rgba(0, 0, 0, 0.04);
}
.row.align {
    float: none;
    vertical-align: middle;
    white-space: nowrap;
}
.wallet-bank-list {
    padding: 0;
    list-style: none;
    position: relative;
    margin: 0 15px;
}
.wallet-bank-list>li.header {
    border-bottom: 0;
}
.wallet-bank-list>li{
    border-bottom: 1px solid #ccc;
    padding: 16px 0;
}
.wallet-bank-list>li:last-child {
    border-bottom: none;
}
.wallet-bank-icon {
    width: 100px;
    height: auto;
}
.fa-copy{
    font-size: 18px;
}
</style>