<template>

    <!-- Common Banner Area -->
    <BookingPoliciesBanner />

    <!-- Dashboard Area -->
    <BookingPolicies />

</template>
<script>

import BookingPoliciesBanner from '@/components/flights/BookingPoliciesBanner.vue'
import BookingPolicies from '@/components/flights/BookingPolicies.vue'


export default {
    name: "BookingPoliciesView",
    components: {
        BookingPoliciesBanner, BookingPolicies
    }
};
</script>
