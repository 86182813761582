import { createRouter, createWebHistory } from 'vue-router'
import AboutView from '../views/about/AboutView.vue'
import BookingView from '../views/booking/BookingView.vue'
import BookingDetails from '../views/booking/BookingDetails.vue'
import BookingConfirmationView from '../views/flights/BookingConfirmationView.vue'
import BookingBankDetailsView from '../views/flights/BookingBankDetailsView.vue'
import BookingSearchResultView from '../views/flights/BookingSearchResultView.vue'
import BookingBookingSubmission from '../views/flights/BookingSubmission.vue'
import BookingPoliciesView from '../views/flights/BookingPoliciesView.vue'
import HomeView from '../views/home/HomeView.vue'
import PassengersView from '../views/user/PassengersView.vue'
import ProfileView from '../views/user/ProfileView.vue'
import WalletView from '../views/user/WalletView.vue'
import CreditCardsView from '../views/user/CreditCardsView.vue'

import FaqsView from '../views/pages/FaqsView.vue'
import PrivacyPolicyView from '../views/pages/PrivacyPolicyView.vue'
import ErrorView from '../views/pages/ErrorView.vue'
import ContactView from '../views/contact/ContactView.vue'
import NotFoundView from '../views/pages/NotFoundView.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/flight-search-result',
        name: 'flight-search-result',
        component: BookingSearchResultView
    },
    {
        path: '/flight-booking-submission',
        name: 'flight-booking-submission',
        component: BookingBookingSubmission
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView
    },
    {
        path: '/faqs',
        name: 'faqs',
        component: FaqsView
    },
    {
        path: '/booking-confirmation',
        name: 'booking-confirmation',
        component: BookingConfirmationView
    },
    {
        path: '/flight-booking',
        name: 'flight-booking',
        component: BookingView
    },
    {
        path: '/flight-booking/:id',
        name: 'flight-booking-details',
        component: BookingDetails
    },
    {
        path: '/flight-booking/bank/details/:id',
        name: 'booking-bank-details',
        component: BookingBankDetailsView
    },
    {
        path: '/flight-policies/:id',
        name: 'flight-booking-policies',
        component: BookingPoliciesView
    },
    {
        path: '/my-profile',
        name: 'my-profile',
        component: ProfileView
    },
    {
        path: '/wallet',
        name: 'wallet',
        component: WalletView
    },
    {
        path: '/credit-cards',
        name: 'credit-cards',
        component: CreditCardsView
    },
    {
        path: '/passengers',
        name: 'passengers',
        component: PassengersView
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicyView
    },
    {
        path: '/error',
        name: 'error',
        component: ErrorView
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactView
    },
    {
        path: '/:cathAll(.*)',
        name: 'NotFoundView',
        component: NotFoundView
    }
]


const scrollBehavior = (to, from, savedPosition) => {
    return savedPosition || to.meta?.scrollPos || { top: 0, left: 0 }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior,
})

router.beforeEach((to, from, next) => {
    // console.log('window.scrollY:', window.scrollY)
    from.meta?.scrollPos && (from.meta.scrollPos.top = window.scrollY)
        // console.log('from:\t', from.name, '\t', from.meta?.scrollPos)
        // console.log('to:\t\t', to.name, '\t', to.meta?.scrollPos)
    return next()
})

export default router