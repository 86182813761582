<template>
    <section id="explore_area" class="section_padding result-flights">
        <div class="container">
            <!-- Section Heading -->
            <div v-if="result?.data?.length > 0" class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2 v-if="departure_selecting == 1">{{ $t('Flights Found') }} ({{ count }})</h2>
                        <h2 v-else>{{ $t('Summary of your flight order') }}</h2>
                    </div>
                </div>
            </div>
            <div class="row" id="target-div">
                <div class="col-lg-3 relative">
                    <div class="disable"></div>
                    <div v-if="result?.data?.length > 0" class="left_side_search_area">
                        <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>{{ $t('Filter by price') }}</h5>
                            </div>
                            <div class="filter-price">
                                <Slider v-model="price_rang" :min="min" :max="max" @change="priceRang" class="apply" />
                            </div>
                        </div>
                        <!-- <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>{{ $t('Number of stops') }}</h5>
                            </div>
                            <div class="tour_search_type">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefaultf1"
                                        checked>
                                    <label class="form-check-label" for="flexCheckDefaultf1">
                                        <span class="area_flex_one">
                                            <span>1 {{ $t('Stop') }}</span>
                                        </span>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefaultf2"
                                        checked>
                                    <label class="form-check-label" for="flexCheckDefaultf2">
                                        <span class="area_flex_one">
                                            <span>2 {{ $t('Stop') }}</span>
                                        </span>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefaultf3"
                                        checked>
                                    <label class="form-check-label" for="flexCheckDefaultf3">
                                        <span class="area_flex_one">
                                            <span>3 {{ $t('Stop') }}</span>
                                        </span>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefaultf4"
                                        checked>
                                    <label class="form-check-label" for="flexCheckDefaultf4">
                                        <span class="area_flex_one">
                                            <span>{{ $t('Non-stop') }}</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div> -->
                        <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>{{ $t('Airlines') }}</h5>
                            </div>
                            <div class="tour_search_type">
                                <div v-for="(item, index) in airlines" :key="item.en" class="form-check">
                                    <input class="form-check-input filter-airlines" type="checkbox" :value="index"
                                        checked>
                                    <label class="form-check-label">
                                        <span class="area_flex_one">
                                            <span>{{ $i18n.locale === 'en' ? item.en : item.ar }}</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>{{ $t('Departure time') }}</h5>
                            </div>
                            <div class="tour_search_type">
                                <div class="form-check">
                                    <input class="form-check-input filter-time" type="checkbox" value="1" checked>
                                    <label class="form-check-label">
                                        <span class="area_flex_one">
                                            <span>12:00 MN - 4:59 AM</span>
                                        </span>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input filter-time" type="checkbox" value="2" checked>
                                    <label class="form-check-label">
                                        <span class="area_flex_one">
                                            <span>05:00 AM - 11:59 AM</span>
                                        </span>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input filter-time" type="checkbox" value="3" checked>
                                    <label class="form-check-label">
                                        <span class="area_flex_one">
                                            <span>12:00 NN - 4:59 PM</span>
                                        </span>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input filter-time" type="checkbox" value="4" checked>
                                    <label class="form-check-label">
                                        <span class="area_flex_one">
                                            <span>05:00 PM - 11:59 PM</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div v-else class="left_side_search_area skeleton">
                        <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>
                                    <lv-skeleton :width="200" :height="15" />
                                </h5>
                            </div>
                            <div class="filter-price">
                                <lv-skeleton :width="200" :height="15" />
                            </div>
                            <div class="filter-price">
                                <lv-skeleton :width="200" :height="15" />
                            </div>
                            <div class="filter-price">
                                <lv-skeleton :width="200" :height="15" />
                            </div>
                            <div class="filter-price">
                                <lv-skeleton :width="200" :height="15" />
                            </div>
                            <div class="filter-price">
                                <lv-skeleton :width="200" :height="15" />
                            </div>
                        </div>
                        <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>
                                    <lv-skeleton :width="200" :height="15" />
                                </h5>
                            </div>
                            <div class="tour_search_type">
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                            </div>
                        </div>
                        <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>
                                    <lv-skeleton :width="200" :height="15" />
                                </h5>
                            </div>
                            <div class="tour_search_type">
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                            </div>
                        </div>
                        <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>
                                    <lv-skeleton :width="200" :height="15" />
                                </h5>
                            </div>
                            <div class="tour_search_type">
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                                <div class="form-check">
                                    <lv-skeleton :width="200" :height="15" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="row">
                        <div v-if="this.tripType != 'multi_city'" class="col-lg-12" id="departure-result">
                            <div v-if="result?.data?.length > 0" class="flight_search_result_wrapper flight-result-con">
                                <div v-for="(item, index) in result.data" class="mb-4">
                                    <div v-bind:class="showmore[index] ? 'boxoftripresult is_open' : 'boxoftripresult'">
                                        <div class="d-flex justify-content-center">
                                            <div class="px-2 py-4 bg-white text-black w-75 position-relative">
                                                <div class="multi_city_flight_lists w-100">
                                                    <div class="flight_multis_area_wrapper">
                                                        <div class="flight_search_left">
                                                            <div class="flight_logo relative">
                                                                <div :class="'flight-image i-' + item.itineraries[0].segments[0].carrierCode.toString().toLowerCase()"
                                                                    v-tooltip.top="result.airlines[item.itineraries[0].segments[0].carrierCode][$i18n.locale]">
                                                                </div>
                                                            </div>
                                                            <div class="flight_search_destination">
                                                                <h3>
                                                                    {{ this.$route.query.from }}

                                                                </h3>
                                                                <p class="time">
                                                                    {{ new
                                                                        Date(item.itineraries[0].segments[0].departure.at).toLocaleTimeString('fr-FR',
                                                                            {
                                                                                hour:
                                                                                    '2-digit', minute: '2-digit'
                                                                            }) }}
                                                                </p>
                                                                <p class="date">
                                                                    {{ new
                                                                        Date(item.itineraries[0].segments[0].departure.at).toLocaleDateString('en-US',
                                                                            { day: 'numeric' }) }}
                                                                    {{ $t(new
                                                                        Date(item.itineraries[0].segments[0].departure.at).toLocaleDateString('en-US',
                                                                            {
                                                                                month:
                                                                                    'long'
                                                                            })) }}
                                                                    {{ new
                                                                        Date(item.itineraries[0].segments[0].departure.at).getFullYear()
                                                                    }}
                                                                </p>
                                                                <h6>
                                                                    {{
                                                                        item.itineraries[0].segments[0].departure.airportName
                                                                    }}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div class="flight_right_arrow">
                                                            <img :src="require(`@/assets/icons/right_arrow.png`)"
                                                                alt="img" />
                                                            <h6>
                                                                {{
                                                                    item.itineraries[0].segments.length - 1 == 0 ?
                                                                        $t('Direct Flight')
                                                                        : item.itineraries[0].segments.length - 1 + ' ' +
                                                                        $t('Stop')
                                                                }}
                                                            </h6>
                                                            <p>{{ convertDuration(item.itineraries[0].duration) }}</p>
                                                        </div>
                                                        <div class="flight_search_middel">
                                                            <div class="flight_search_destination">
                                                                <h3>
                                                                    {{ this.$route.query.to }}
                                                                </h3>
                                                                <p class="time">
                                                                    {{ new
                                                                        Date(item.itineraries[0].segments[item.itineraries[0].segments.length
                                                                            - 1].arrival.at).toLocaleTimeString('fr-FR',
                                                                                {
                                                                                    hour:
                                                                                        '2-digit', minute: '2-digit'
                                                                                }) }}
                                                                </p>
                                                                <p class="date">
                                                                    {{ new
                                                                        Date(item.itineraries[0].segments[item.itineraries[0].segments.length
                                                                            - 1].arrival.at).toLocaleDateString('en-US',
                                                                                { day: 'numeric' }) }}
                                                                    {{ $t(new
                                                                        Date(item.itineraries[0].segments[item.itineraries[0].segments.length
                                                                            - 1].arrival.at).toLocaleDateString('en-US', {
                                                                                month:
                                                                                    'long'
                                                                            })) }}
                                                                    {{ new
                                                                        Date(item.itineraries[0].segments[item.itineraries[0].segments.length
                                                                            - 1].arrival.at).getFullYear() }}
                                                                </p>
                                                                <h6>
                                                                    {{
                                                                        item.itineraries[0].segments[item.itineraries[0].segments.length
                                                                            - 1].arrival.airportName }}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dduKua">
                                                    <span>
                                                        {{ $t('seats') }} : {{ item.numberOfBookableSeats }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="right w-25 px-2 py-3">
                                                <div class="d-flex justify-content-center mt-3">
                                                    <p
                                                        class="text-white mb-0 d-inline-flex pb-1 mx-auto position-relative text-center price">
                                                        {{ item.price.total }} {{ $t(item.price.currency) }}
                                                    </p>
                                                </div>
                                                <div class=" text-center mt-2">
                                                    <button class="btn btn_select_trip btn-sm select-flight"
                                                        @click="select_flight(JSON.stringify(item), '0', $event)">
                                                        <span>{{ $t('Select Flight') }}</span>
                                                        <lv-loader type="ball-pulse" style="display: none;"
                                                            :color="'#fff'" :scale="'0.7'" />
                                                    </button>
                                                </div>
                                                <div class="d-flex mt-2 justify-content-center align-items-center">
                                                    <div class="pointer" @click="showmore[index] = !showmore[index]">
                                                        <span class="text fw-bold pointer text-center"
                                                            style="color: #2F363D;">
                                                            {{ $t('Show more') }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- For Show More -->
                                    <div v-if="showmore[index]" class="boxshowmore">
                                        <div v-for='(segment, index) in item.itineraries[0].segments'
                                            class="flight_show_down_wrapper">
                                            <div class="flight-shoe_dow_item d-flex w-100">
                                                <div class="airline-details mt-2">
                                                    <div class="img">
                                                        <div class="flight-image  flight-image-sm i-sv"></div>
                                                    </div>
                                                    <span class="airlineName fw-500">
                                                        {{
                                                            result.airlines[segment.carrierCode][$i18n.locale] }}
                                                        &nbsp;
                                                        {{ segment.carrierCode }}{{ segment.number }}</span>
                                                </div>
                                                <div class="flight_inner_show_component w-70">
                                                    <div class="flight_det_wrapper">
                                                        <div class="flight_det">
                                                            <div class="code_time">
                                                                <span class="code">{{ segment.departure.iataCode
                                                                    }}</span>
                                                                <span class="time">
                                                                    {{ new
                                                                        Date(segment.departure.at).toLocaleTimeString('fr-FR',
                                                                            {
                                                                                hour:
                                                                                    '2-digit', minute: '2-digit'
                                                                            }) }}
                                                                </span>
                                                            </div>
                                                            <p class="airport">
                                                                {{ segment.departure.airportName
                                                                }}
                                                            </p>
                                                            <p class="date">
                                                                {{ new
                                                                    Date(segment.departure.at).toLocaleDateString('en-US',
                                                                        { day: 'numeric' }) }}
                                                                {{ $t(new
                                                                    Date(segment.departure.at).toLocaleDateString('en-US', {
                                                                        month:
                                                                            'long'
                                                                    })) }}
                                                                {{ new
                                                                    Date(segment.departure.at).getFullYear() }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="flight_duration">
                                                        <div class="arrow_right"></div>
                                                        <span>
                                                            {{ convertDuration(segment.duration) }}
                                                        </span>
                                                    </div>
                                                    <div class="flight_det_wrapper">
                                                        <div class="flight_det">
                                                            <div class="code_time">
                                                                <span class="code">{{ segment.arrival.iataCode }}</span>
                                                                <span class="time">
                                                                    {{ new
                                                                        Date(segment.arrival.at).toLocaleTimeString('fr-FR',
                                                                            {
                                                                                hour:
                                                                                    '2-digit', minute: '2-digit'
                                                                            }) }}
                                                                </span>
                                                            </div>
                                                            <p class="airport">
                                                                {{ segment.arrival.airportName
                                                                }}
                                                            </p>
                                                            <p class="date">
                                                                {{ new
                                                                    Date(segment.arrival.at).toLocaleDateString('en-US',
                                                                        { day: 'numeric' }) }}
                                                                {{ $t(new
                                                                    Date(segment.arrival.at).toLocaleDateString('en-US', {
                                                                        month:
                                                                            'long'
                                                                    })) }}
                                                                {{ new
                                                                    Date(segment.arrival.at).getFullYear() }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {{ $t('Number of bags') }} : {{
                                                            getFareDetailsBySegmentId(item.travelerPricings, segment.id) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- For Show More -->
                                </div>
                            </div>
                            <div v-else class="flight_search_result_wrapper skeleton">
                                <div v-for='(item, index) in 10' class="mb-4">
                                    <div class="boxoftripresult">
                                        <div class="d-flex justify-content-center">
                                            <div class="px-2 py-3 bg-white text-black w-75 position-relative">
                                                <div class="multi_city_flight_lists w-100">
                                                    <div class="flight_multis_area_wrapper">
                                                        <div class="flight_search_left">
                                                            <div class="flight_logo">
                                                                <img :src="require(`@/assets/icons/default_70.png`)"
                                                                    alt="img" />
                                                            </div>
                                                            <div class="flight_search_destination">
                                                                <p>
                                                                    <lv-skeleton :width="75" :height="15" />
                                                                </p>
                                                                <h3>
                                                                    <lv-skeleton :width="100" :height="15" />
                                                                </h3>
                                                                <h6>
                                                                    <lv-skeleton :width="150" :height="15" />
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div class="flight_right_arrow">
                                                            <img :src="require(`@/assets/icons/right_arrow.png`)"
                                                                alt="img" />
                                                            <h6>
                                                                <lv-skeleton :width="100" :height="15" />
                                                            </h6>
                                                            <p class="mt-2">
                                                                <lv-skeleton :width="100" :height="15" />
                                                            </p>
                                                        </div>
                                                        <div class="flight_search_middel">
                                                            <div class="flight_search_destination">
                                                                <p>
                                                                    <lv-skeleton :width="75" :height="15" />
                                                                </p>
                                                                <h3>
                                                                    <lv-skeleton :width="100" :height="15" />
                                                                </h3>
                                                                <h6>
                                                                    <lv-skeleton :width="150" :height="15" />
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="right w-25 px-2">
                                                <div class="d-flex justify-content-center">
                                                    <p
                                                        class="text-white mb-0 d-inline-flex pb-1 mx-auto position-relative text-center price">

                                                    </p>
                                                </div>
                                                <div class=" text-center">
                                                    <button class="btn btn_select_trip btn-sm">
                                                        <lv-skeleton :width="75" :height="15" primaryColor="#2F363D"
                                                            secondaryColor="#2F363D" />
                                                    </button>
                                                </div>
                                                <div class="d-flex mt-2 justify-content-center align-items-center">
                                                    <div class="pointer">
                                                        <span class="text fw-bold pointer text-center">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="selected-flight"></div>
                        </div>
                        <div v-if="this.tripType != 'multi_city'" class="col-lg-12 d-none2" id="arrival-result">
                            <div v-if="result?.data?.length > 0" class="flight_search_result_wrapper flight-result-con">
                                <h4 v-if="arrival_selecting == 1"
                                    class="no-margin text-center mb-3 ml5 mr5 count-arrival text-white">
                                    <span class="new-bold-dark font-20 arrival">{{ $t('Return Flight') }}</span>
                                    <span class="flight-count new-bold-dark"> ({{ count }}) </span>
                                    <span class="flight-count new-bold-dark">{{ $t('Results') }}</span>
                                </h4>
                                <h4 v-else class="no-margin text-center mb-4 ml5 mr5 count-arrival text-white">
                                    <span class="new-bold-dark font-20 arrival">{{ $t('Return flight') }}</span>
                                </h4>
                                <div v-for="(item, index) in arrival_results" class="mb-4">
                                    <div v-bind:class="showmore[index] ? 'boxoftripresult is_open' : 'boxoftripresult'">
                                        <div class="d-flex justify-content-center">
                                            <div class="px-2 py-4 bg-white text-black w-75 position-relative">
                                                <div class="multi_city_flight_lists w-100">
                                                    <div class="flight_multis_area_wrapper">
                                                        <div class="flight_search_left">
                                                            <div class="flight_logo relative">
                                                                <div :class="'flight-image i-' + item.itineraries[1].segments[0].carrierCode.toString().toLowerCase()"
                                                                    v-tooltip.top="result.airlines[item.itineraries[1].segments[0].carrierCode][$i18n.locale]">
                                                                </div>
                                                            </div>
                                                            <div class="flight_search_destination">
                                                                <h3>{{ this.searchData.to }}</h3>
                                                                <p class="time">
                                                                    {{ new
                                                                        Date(item.itineraries[1].segments[0].departure.at).toLocaleTimeString('fr-FR',
                                                                            {
                                                                                hour:
                                                                                    '2-digit', minute: '2-digit'
                                                                            }) }}
                                                                </p>
                                                                <p class="date">
                                                                    {{ new
                                                                        Date(item.itineraries[1].segments[0].departure.at).toLocaleDateString('en-US',
                                                                            { day: 'numeric' }) }}
                                                                    {{ $t(new
                                                                        Date(item.itineraries[1].segments[0].departure.at).toLocaleDateString('en-US',
                                                                            {
                                                                                month:
                                                                                    'long'
                                                                            })) }}
                                                                    {{ new
                                                                        Date(item.itineraries[1].segments[0].departure.at).getFullYear()
                                                                    }}
                                                                </p>
                                                                <h6>{{
                                                                    item.itineraries[1].segments[0].departure.airportName
                                                                    }}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div class="flight_right_arrow">
                                                            <img :src="require(`@/assets/icons/right_arrow.png`)"
                                                                alt="img" />
                                                            <h6>
                                                                {{
                                                                    item.itineraries[1].segments.length - 1 == 0 ?
                                                                        $t('Direct Flight')
                                                                        : item.itineraries[1].segments.length - 1 + ' ' +
                                                                        $t('Stop')
                                                                }}
                                                            </h6>
                                                            <p>{{ convertDuration(item.itineraries[1].duration) }}</p>
                                                        </div>
                                                        <div class="flight_search_middel">

                                                            <div class="flight_search_destination">
                                                                <h3>{{ this.searchData.from }}</h3>
                                                                <p class="time">
                                                                    {{ new
                                                                        Date(item.itineraries[1].segments[item.itineraries[1].segments.length
                                                                            - 1].arrival.at).toLocaleTimeString('fr-FR',
                                                                                {
                                                                                    hour:
                                                                                        '2-digit', minute: '2-digit'
                                                                                }) }}
                                                                </p>
                                                                <p class="date">
                                                                    {{ new
                                                                        Date(item.itineraries[1].segments[item.itineraries[1].segments.length
                                                                            - 1].arrival.at).toLocaleDateString('en-US',
                                                                                { day: 'numeric' }) }}
                                                                    {{ $t(new
                                                                        Date(item.itineraries[1].segments[item.itineraries[1].segments.length
                                                                            - 1].arrival.at).toLocaleDateString('en-US', {
                                                                                month:
                                                                                    'long'
                                                                            })) }}
                                                                    {{ new
                                                                        Date(item.itineraries[1].segments[item.itineraries[1].segments.length
                                                                            - 1].arrival.at).getFullYear() }}
                                                                </p>
                                                                <h6>{{
                                                                    item.itineraries[1].segments[item.itineraries[1].segments.length
                                                                        - 1].arrival.airportName }}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dduKua">
                                                    <span>
                                                        {{ $t('seats') }} : {{ item.numberOfBookableSeats }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="right w-25 px-2 py-3">
                                                <div class="d-flex justify-content-center mt-3">
                                                    <p
                                                        class="text-white mb-0 d-inline-flex pb-1 mx-auto position-relative text-center price">
                                                        {{ item.price.total }} {{ $t(item.price.currency) }}
                                                    </p>
                                                </div>
                                                <div class=" text-center mt-2">
                                                    <button class="btn btn_select_trip btn-sm select-flight"
                                                        @click="select_flight(JSON.stringify(item), '1', $event)">
                                                        <span>{{ $t('Select Flight') }}</span>
                                                        <lv-loader type="ball-pulse" style="display: none;"
                                                            :color="'#fff'" :scale="'0.7'" />
                                                    </button>
                                                </div>
                                                <div class="d-flex mt-2 justify-content-center align-items-center">
                                                    <div class="pointer" @click="showmore[index] = !showmore[index]">
                                                        <span class="text fw-bold pointer text-center"
                                                            style="color: #2F363D;">
                                                            {{ $t('Show more') }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- For Show More -->
                                    <div v-if="showmore[index]" class="boxshowmore">
                                        <div v-for='(segment, index) in item.itineraries[1].segments'
                                            class="flight_show_down_wrapper">
                                            <div class="flight-shoe_dow_item d-flex w-100">
                                                <div class="airline-details mt-2">
                                                    <div class="img">
                                                        <div
                                                            :class="'flight-image  flight-image-sm i-' + segment.carrierCode.toString().toLowerCase()">
                                                        </div>
                                                    </div>
                                                    <span class="airlineName fw-500">{{
                                                        result.airlines[segment.carrierCode][$i18n.locale] }}
                                                        &nbsp;
                                                        {{ segment.carrierCode }}{{ segment.number }}</span>
                                                </div>
                                                <div class="flight_inner_show_component w-70">
                                                    <div class="flight_det_wrapper">
                                                        <div class="flight_det">
                                                            <div class="code_time">
                                                                <span class="code">{{ segment.departure.iataCode
                                                                    }}</span>
                                                                <span class="time">
                                                                    {{ new
                                                                        Date(segment.departure.at).toLocaleTimeString('fr-FR',
                                                                            {
                                                                                hour:
                                                                                    '2-digit', minute: '2-digit'
                                                                            }) }}
                                                                </span>
                                                            </div>
                                                            <p class="airport">
                                                                {{ segment.departure.airportName
                                                                }}
                                                            </p>
                                                            <p class="date">
                                                                {{ new
                                                                    Date(segment.departure.at).toLocaleDateString('en-US',
                                                                        { day: 'numeric' }) }}
                                                                {{ $t(new
                                                                    Date(segment.departure.at).toLocaleDateString('en-US', {
                                                                        month:
                                                                            'long'
                                                                    })) }}
                                                                {{ new
                                                                    Date(segment.departure.at).getFullYear() }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="flight_duration">
                                                        <div class="arrow_right"></div>
                                                        <span>
                                                            {{ convertDuration(segment.duration) }}
                                                        </span>
                                                    </div>
                                                    <div class="flight_det_wrapper">
                                                        <div class="flight_det">
                                                            <div class="code_time">
                                                                <span class="code">{{ segment.arrival.iataCode }}</span>
                                                                <span class="time">
                                                                    {{ new
                                                                        Date(segment.arrival.at).toLocaleTimeString('fr-FR',
                                                                            {
                                                                                hour:
                                                                                    '2-digit', minute: '2-digit'
                                                                            }) }}
                                                                </span>
                                                            </div>
                                                            <p class="airport">
                                                                {{ segment.arrival.airportName
                                                                }}
                                                            </p>
                                                            <p class="date">
                                                                {{ new
                                                                    Date(segment.arrival.at).toLocaleDateString('en-US',
                                                                        { day: 'numeric' }) }}
                                                                {{ $t(new
                                                                    Date(segment.arrival.at).toLocaleDateString('en-US', {
                                                                        month:
                                                                            'long'
                                                                    })) }}
                                                                {{ new
                                                                    Date(segment.arrival.at).getFullYear() }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {{ $t('Number of bags') }} : {{
                                                            getFareDetailsBySegmentId(item.travelerPricings, segment.id) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="selected-flight"></div>
                        </div>
                        <div v-if="this.tripType == 'multi_city'" class="col-lg-12" id="departure-result">
                            <div v-if="result?.data?.length > 0" class="flight_search_result_wrapper flight-result-con">
                                <div v-for="(item, index) in result.data" class="mb-4">
                                    <div class="boxoftripresult multi_city">
                                        <div class="d-flex justify-content-center">
                                            <div class="px-2 bg-white text-black w-75 position-relative">
                                                <div v-for='(itinerarie, index) in item.itineraries'
                                                    class="itineraries">
                                                    <div v-for='(segment, index) in itinerarie.segments'
                                                        class="flight_multis_area_wrapper">
                                                        <div class="flight_search_left">
                                                            <div class="flight_logo">
                                                                <div :class="'flight-image i-' + segment.carrierCode.toString().toLowerCase()"
                                                                    v-tooltip.top="result.airlines[segment.carrierCode][$i18n.locale]">
                                                                </div>
                                                            </div>
                                                            <div class="flight_search_destination">
                                                                <h3>{{ segment.departure.iataCode }}
                                                                </h3>
                                                                <p class="time">
                                                                    {{ new
                                                                        Date(segment.departure.at).toLocaleTimeString('fr-FR',
                                                                            {
                                                                                hour:
                                                                                    '2-digit', minute: '2-digit'
                                                                            }) }}
                                                                </p>
                                                                <p class="date">
                                                                    {{ new
                                                                        Date(segment.departure.at).toLocaleDateString('en-US',
                                                                            { day: 'numeric' }) }}
                                                                    {{ $t(new
                                                                        Date(segment.departure.at).toLocaleDateString('en-US',
                                                                            {
                                                                                month:
                                                                                    'long'
                                                                            })) }}
                                                                    {{ new
                                                                        Date(segment.departure.at).getFullYear() }}
                                                                </p>
                                                                <h6>{{
                                                                    segment.departure.airportName
                                                                    }}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div class="flight_right_arrow">
                                                            <img :src="require(`@/assets/icons/right_arrow.png`)"
                                                                alt="img" />
                                                            <h6>
                                                                {{ new
                                                                    Date(segment.departure.at).toLocaleDateString('en-US',
                                                                        { day: 'numeric' }) }}
                                                                {{ $t(new
                                                                    Date(segment.departure.at).toLocaleDateString('en-US', {
                                                                        month:
                                                                            'long'
                                                                    })) }}
                                                                {{ new
                                                                    Date(segment.departure.at).getFullYear() }}
                                                            </h6>
                                                            <p>{{ convertDuration(segment.duration) }}</p>
                                                        </div>
                                                        <div class="flight_search_middel">
                                                            <div class="flight_search_destination">
                                                                <h3>{{ segment.arrival.iataCode }}
                                                                </h3>
                                                                <p class="time">
                                                                    {{ new
                                                                        Date(segment.arrival.at).toLocaleTimeString('fr-FR',
                                                                            {
                                                                                hour:
                                                                                    '2-digit', minute: '2-digit'
                                                                            }) }}
                                                                </p>
                                                                <p class="date">
                                                                    {{ new
                                                                        Date(segment.arrival.at).toLocaleDateString('en-US',
                                                                            { day: 'numeric' }) }}
                                                                    {{ $t(new
                                                                        Date(segment.arrival.at).toLocaleDateString('en-US',
                                                                            {
                                                                                month:
                                                                                    'long'
                                                                            })) }}
                                                                    {{ new
                                                                        Date(segment.arrival.at).getFullYear() }}
                                                                </p>
                                                                <h6>{{
                                                                    segment.arrival.airportName
                                                                    }}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dduKua">
                                                    <span>
                                                        {{ $t('seats') }} : {{ item.numberOfBookableSeats }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="right w-25 px-2">
                                                <div>
                                                    <div class="d-flex justify-content-center">
                                                        <p
                                                            class="text-white mb-0 d-inline-flex pb-1 mx-auto position-relative text-center price">
                                                            {{ item.price.total }} {{ $t(item.price.currency) }}
                                                        </p>
                                                    </div>
                                                    <div class="text-center mt-2">
                                                        <button class="btn btn_select_trip btn-sm select-flight"
                                                            @click="select_flight(JSON.stringify(item), '0', $event)">
                                                            <span>{{ $t('Select Flight') }}</span>
                                                            <lv-loader type="ball-pulse" style="display: none;"
                                                                :color="'#fff'" :scale="'0.7'" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="flight_search_result_wrapper skeleton">
                                <div v-for='(item, index) in 10' class="mb-4">
                                    <div class="boxoftripresult multi_city">
                                        <div class="d-flex justify-content-center">
                                            <div class="px-2 bg-white text-black w-75 position-relative">
                                                <div class="multi_city_flight_lists w-100">
                                                    <div class="itineraries">
                                                        <div class="flight_multis_area_wrapper ">
                                                            <div class="flight_search_left">
                                                                <div class="flight_logo">
                                                                    <img :src="require(`@/assets/icons/default_70.png`)"
                                                                        alt="img" />
                                                                </div>
                                                                <div class="flight_search_destination">
                                                                    <p>
                                                                        <lv-skeleton :width="75" :height="15" />
                                                                    </p>
                                                                    <h3>
                                                                        <lv-skeleton :width="100" :height="15" />
                                                                    </h3>
                                                                    <h6>
                                                                        <lv-skeleton :width="150" :height="15" />
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div class="flight_right_arrow">
                                                                <img :src="require(`@/assets/icons/right_arrow.png`)"
                                                                    alt="img" />
                                                                <h6>
                                                                    <lv-skeleton :width="100" :height="15" />
                                                                </h6>
                                                                <p class="mt-2">
                                                                    <lv-skeleton :width="100" :height="15" />
                                                                </p>
                                                            </div>
                                                            <div class="flight_search_middel">
                                                                <div class="flight_search_destination">
                                                                    <p>
                                                                        <lv-skeleton :width="75" :height="15" />
                                                                    </p>
                                                                    <h3>
                                                                        <lv-skeleton :width="100" :height="15" />
                                                                    </h3>
                                                                    <h6>
                                                                        <lv-skeleton :width="150" :height="15" />
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="itineraries">
                                                        <div class="flight_multis_area_wrapper ">
                                                            <div class="flight_search_left">
                                                                <div class="flight_logo">
                                                                    <img :src="require(`@/assets/icons/default_70.png`)"
                                                                        alt="img" />
                                                                </div>
                                                                <div class="flight_search_destination">
                                                                    <p>
                                                                        <lv-skeleton :width="75" :height="15" />
                                                                    </p>
                                                                    <h3>
                                                                        <lv-skeleton :width="100" :height="15" />
                                                                    </h3>
                                                                    <h6>
                                                                        <lv-skeleton :width="150" :height="15" />
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div class="flight_right_arrow">
                                                                <img :src="require(`@/assets/icons/right_arrow.png`)"
                                                                    alt="img" />
                                                                <h6>
                                                                    <lv-skeleton :width="100" :height="15" />
                                                                </h6>
                                                                <p class="mt-2">
                                                                    <lv-skeleton :width="100" :height="15" />
                                                                </p>
                                                            </div>
                                                            <div class="flight_search_middel">
                                                                <div class="flight_search_destination">
                                                                    <p>
                                                                        <lv-skeleton :width="75" :height="15" />
                                                                    </p>
                                                                    <h3>
                                                                        <lv-skeleton :width="100" :height="15" />
                                                                    </h3>
                                                                    <h6>
                                                                        <lv-skeleton :width="150" :height="15" />
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="right w-25 px-2">
                                                <div>
                                                    <div class="d-flex justify-content-center">
                                                        <p
                                                            class="text-white mb-0 d-inline-flex pb-1 mx-auto position-relative text-center price">

                                                        </p>
                                                    </div>
                                                    <div class=" text-center">
                                                        <button class="btn btn_select_trip btn-sm">
                                                            <lv-skeleton :width="75" :height="15"
                                                                secondaryColor="#2F363D" primaryColor="#2F363D" />
                                                        </button>
                                                    </div>
                                                    <div class="d-flex mt-2 justify-content-center align-items-center">
                                                        <div class="pointer">
                                                            <span class="text fw-bold pointer text-center">

                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="selected-flight"></div>
                        </div>
                        <div class="col-lg-12  text-right price-fl" style="display: none;">
                            <button class="btn btn-fa-primary p15 btn-block btn-lg w-25" @click="booking_submission">
                                <span class="no-margin bold">التالي</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
var that;
import $ from 'jquery';
import Slider from '@vueform/slider';
import jsonDataAr from '@/assets/file/ar-airports.json';
import jsonDataEn from '@/assets/file/en-airports.json';
import airlinesData from '@/assets/file/airlines.json';
import { toast } from 'vue3-toastify';
import LvSkeleton from 'lightvue/skeleton';
import Tooltip from 'lightvue/tooltip';
import LvLoader from 'lightvue/loaders';
import event from '@/useEvent';

export default {
    name: "SearchResult",
    components: {
        Slider,
        LvSkeleton,
        LvLoader
    },
    directives: {
        tooltip: Tooltip,
    },
    data() {
        return {
            showmore: [],
            items: [],
            result: [],
            arrival_results: [],
            date: '',
            ret_date: '',
            isLogin: false,
            price_rang: [0, 75],
            airlines: [],
            allData: [],
            searchData: [],
            count: 0,
            min: 1000 * 1000,
            max: 0,
            arrival_total: 0,
            departure_total: 0,
            tripType: '',
            departure_selecting: 1,
            arrival_selecting: 1,
        }
    },
    methods: {
        getFareDetailsBySegmentId(travelerPricings, segmentId) {
            var travelerPricing = travelerPricings[0].fareDetailsBySegment.filter(item => {
                return item.segmentId == segmentId;
            });
            if (travelerPricing.length) {
                travelerPricing = travelerPricing[0];
                return travelerPricing.includedCheckedBags.quantity ?? 0;
            }
            return 0;
        },
        priceRang(newValue) {
            const filteredFlights = this.allData.data.filter((flight) => {
                const grandTotal = parseFloat(flight.price.grandTotal);
                return grandTotal >= newValue[0] && grandTotal <= newValue[1];
            });
            console.log(newValue);
            this.count = filteredFlights.length;
            this.result.data = filteredFlights;
        },
        getLocationByCode(code) {
            var jsonData = jsonDataAr;
            if (that.$i18n.locale === 'en') {
                jsonData = jsonDataEn;
            }
            var info = jsonData.filter(item => {
                return item.code.includes(code);
            });
            return info[0];
        },
        convertDuration(duration) {
            const regex = /PT(\d+)H(\d+)M/;
            const regex2 = /PT(\d+)H/;
            const match = regex.exec(duration);
            const match2 = regex2.exec(duration);
            if (match) {
                const hours = match[1].padStart(2, '0');
                const minutes = match[2].padStart(2, '0');
                if (that.$i18n.locale === 'en') {
                    return hours + 'h ' + minutes + 'm';
                }
                return hours + 'س ' + minutes + 'د';
            } else if (match2) {
                const hours = match2[1].padStart(2, '0');
                const minutes = '00';
                if (that.$i18n.locale === 'en') {
                    return hours + 'h ' + minutes + 'm';
                }
                return hours + 'س ' + minutes + 'د';
            }
            return '';
        },
        select_flight(details, type, event) {
            var element = $(event.currentTarget);
            details = JSON.parse(details);
            element.prop('disabled', true);
            element.find('span').hide();
            element.find('.loader-inner').show();
            setTimeout(() => {
                if (this.tripType == 'roundtrip') {
                    if (type == 0) {
                        setTimeout(() => {
                            $("html, body").animate({
                                scrollTop: $("#arrival-result").offset().top - 200
                            }, 10);
                        }, 100);
                        this.departure_selecting = 0;
                        this.stops = this.getUniqueSegmentLengths(this.allData.data).sort(function (a, b) {
                            return a - b;
                        });
                        $(".filter-stops").each(function (index) {
                            if (index == 0) {
                                $(this).prop('checked', true);
                            } else {
                                $(this).prop('checked', false);
                            }
                        });
                        $(".filter-airlines").attr('disabled', true);
                        localStorage.setItem('departure_flight', JSON.stringify(details));
                        var airline = details.validatingAirlineCodes[0];
                        this.selected_airline = airline;
                        var array = [this.stops[0]];
                        this.arrival_results = this.allData.data.filter((flight) => {
                            if (array.includes(flight.itineraries[1].segments.length) && flight.validatingAirlineCodes.includes(airline)) {
                                return true;
                            }
                            return false;
                        });
                        this.count = this.arrival_results.length;
                        $('#arrival-result').show();
                        $('#arrival-result .flight-result-con').show();
                        $('#arrival-result .flight-count').show();
                        $('#departure-result .flight-result-con').hide();
                        $('#departure-result .selected-flight').show();
                        $('#departure-result .selected-flight').html(`
                                <div class="boxoftripresult">
                                    <div class="d-flex justify-content-center">
                                        <div class="px-2 py-4 bg-white text-black w-75 position-relative">
                                            <div class="multi_city_flight_lists w-100">
                                                <div class="flight_multis_area_wrapper">
                                                    <div class="flight_search_left">
                                                        <div class="flight_logo relative">
                                                            <div
                                                                class="flight-image i-${details.itineraries[0].segments[0].carrierCode.toString().toLowerCase()}">
                                                            </div>
                                                        </div>
                                                        <div class="flight_search_destination">
                                                            <h3>${this.searchData.from}</h3>
                                                            <p class="time">
                                                                ${new
                                Date(details.itineraries[0].segments[0].departure.at).toLocaleTimeString('fr-FR',
                                    {
                                        hour:
                                            '2-digit', minute: '2-digit'
                                    })}
                                                            </p>
                                                            <p class="date">
                                                                ${new
                                Date(details.itineraries[0].segments[0].departure.at).toLocaleDateString('en-US',
                                    { day: 'numeric' })}
                                                                ${this.$t(new
                                        Date(details.itineraries[0].segments[0].departure.at).toLocaleDateString('en-US', {
                                            month:
                                                'long'
                                        }))}
                                                                ${new
                                Date(details.itineraries[0].segments[0].departure.at).getFullYear()}
                                                            </p>
                                                            <h6>${this.getLocationByCode(this.searchData.from)['name']}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="flight_right_arrow">
                                                        <img src="${require(`@/assets/icons/right_arrow.png`)}" alt="img" />
                                                        <h6>
                                                            ${details.itineraries[0].segments.length - 1 == 0 ?
                                this.$t('Direct Flight')
                                : details.itineraries[0].segments.length - 1 + ' ' + this.$t('Stop')
                            }
                                                        </h6>
                                                        <p>${this.convertDuration(details.itineraries[0].duration)}</p>
                                                    </div>
                                                    <div class="flight_search_middel">
                                                        <div class="flight_search_destination">
                                                            <h3>
                                                                ${this.searchData.to}
                                                            </h3>
                                                            <p class="time">
                                                                ${new
                                Date(details.itineraries[0].segments[details.itineraries[0].segments.length -
                                    1].arrival.at).toLocaleTimeString('fr-FR',
                                        {
                                            hour:
                                                '2-digit', minute: '2-digit'
                                        })}
                                                            </p>
                                                            <p class="date">
                                                                ${new
                                Date(details.itineraries[0].segments[details.itineraries[0].segments.length -
                                    1].arrival.at).toLocaleDateString('en-US',
                                        { day: 'numeric' })}
                                                                ${this.$t(new
                                            Date(details.itineraries[0].segments[details.itineraries[0].segments.length -
                                                1].arrival.at).toLocaleDateString('en-US', {
                                                    month:
                                                        'long'
                                                }))}
                                                                ${new
                                Date(details.itineraries[0].segments[details.itineraries[0].segments.length -
                                    1].arrival.at).getFullYear()}
                                                            </p>
                                                            <h6>${this.getLocationByCode(this.searchData.to)['name']}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="right w-25 px-2 py-3">
                                            <div class=" text-center mt-2">
                                                <p class="text-white mb-0 d-inline-flex pb-1 mx-auto position-relative text-center price">
                                                        ${details.price.total} ${this.$t(details.price.currency)} 
                                                </p>
                                                </br>
                                                <button class="btn btn_select_trip btn-sm change-flight mt-2" data-type="departure">
                                                    <span>${this.$t('Change')}</span>
                                                    <div class="loader-inner ball-pulse" style="transform: scale(0.7); display: none;">
                                                        <div style="background-color: rgb(255, 255, 255);"></div>
                                                        <div style="background-color: rgb(255, 255, 255);"></div>
                                                        <div style="background-color: rgb(255, 255, 255);"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>`);
                        $('#departure-flight').html('<h5 class="text-white mb-3">' + this.$t('Going trip') + '</h5>' + $('#departure-result .selected-flight').html());
                    } else {
                        setTimeout(() => {
                            $("html, body").animate({
                                scrollTop: $("#departure-result").offset().top - 200
                            }, 10);
                        }, 100);
                        this.arrival_selecting = 0;
                        localStorage.setItem('arrival_flight', JSON.stringify(details));
                        let departure_flight = JSON.parse(localStorage.getItem('departure_flight'));
                        let arrival_flight = JSON.parse(localStorage.getItem('arrival_flight'));
                        var flight = null;
                        var arrival_total = parseFloat(arrival_flight.price.grandTotal),
                            departure_total = parseFloat(departure_flight.price.grandTotal),
                            flight_total = 0;
                        if (arrival_total > departure_total) {
                            flight = arrival_flight;
                            flight_total = parseFloat(arrival_flight.price.grandTotal);
                        } else {
                            flight = departure_flight;
                            flight_total = parseFloat(departure_flight.price.grandTotal);
                        }
                        $('#arrival-result .flight-result-con').hide();
                        $('.price-fl .fl-total').html(' ' + (flight_total).toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' ');
                        $('.price-fl .fl-currency').html(this.$t(flight.price.currency));
                        $('#arrival-result .selected-flight').html(`
                                <div class="boxoftripresult">
                                    <div class="d-flex justify-content-center">
                                        <div class="px-2 py-4 bg-white text-black w-75 position-relative">
                                            <div class="multi_city_flight_lists w-100">
                                                <div class="flight_multis_area_wrapper">
                                                    <div class="flight_search_left">
                                                        <div class="flight_logo relative">
                                                            <div
                                                                class="flight-image i-${details.itineraries[1].segments[0].carrierCode.toString().toLowerCase()}">
                                                            </div>
                                                        </div>
                                                        <div class="flight_search_destination">
                                                            <h3>${this.searchData.to}</h3>
                                                            <p class="time">
                                                                ${new
                                Date(details.itineraries[1].segments[0].departure.at).toLocaleTimeString('fr-FR',
                                    {
                                        hour:
                                            '2-digit', minute: '2-digit'
                                    })}
                                                            </p>
                                                            <p class="date">
                                                                ${new
                                Date(details.itineraries[1].segments[0].departure.at).toLocaleDateString('en-US',
                                    { day: 'numeric' })}
                                                                ${this.$t(new
                                        Date(details.itineraries[1].segments[0].departure.at).toLocaleDateString('en-US', {
                                            month:
                                                'long'
                                        }))}
                                                                ${new
                                Date(details.itineraries[1].segments[0].departure.at).getFullYear()}
                                                            </p>
                                                            <h6>${this.getLocationByCode(this.searchData.to)['name']}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="flight_right_arrow">
                                                        <img src="${require(`@/assets/icons/right_arrow.png`)}" alt="img" />
                                                        <h6>
                                                            ${details.itineraries[1].segments.length - 1 == 0 ?
                                this.$t('Direct Flight')
                                : details.itineraries[1].segments.length - 1 + ' ' + this.$t('Stop')
                            }
                                                        </h6>
                                                        <p>${this.convertDuration(details.itineraries[1].duration)}</p>
                                                    </div>
                                                    <div class="flight_search_middel">
                                                        <div class="flight_search_destination">
                                                            <h3>
                                                                ${this.searchData.from}
                                                            </h3>
                                                            <p class="time">
                                                                ${new
                                Date(details.itineraries[1].segments[details.itineraries[1].segments.length -
                                    1].arrival.at).toLocaleTimeString('fr-FR',
                                        {
                                            hour:
                                                '2-digit', minute: '2-digit'
                                        })}
                                                            </p>
                                                            <p class="date">
                                                                ${new
                                Date(details.itineraries[1].segments[details.itineraries[1].segments.length -
                                    1].arrival.at).toLocaleDateString('en-US',
                                        { day: 'numeric' })}
                                                                ${this.$t(new
                                            Date(details.itineraries[1].segments[details.itineraries[1].segments.length -
                                                1].arrival.at).toLocaleDateString('en-US', {
                                                    month:
                                                        'long'
                                                }))}
                                                                ${new
                                Date(details.itineraries[1].segments[details.itineraries[1].segments.length -
                                    1].arrival.at).getFullYear()}
                                                            </p>
                                                            <h6>${this.getLocationByCode(this.searchData.from)['name']}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="right w-25 px-2 py-3">
                                            <div class=" text-center mt-2">
                                                <p class="text-white mb-0 d-inline-flex pb-1 mx-auto position-relative text-center price">
                                                        ${details.price.total} ${this.$t(details.price.currency)} 
                                                </p>
                                                </br>
                                                <button class="btn btn_select_trip btn-sm change-flight mt-2" data-type="arrival">
                                                    <span>${this.$t('Change')}</span>
                                                    <div class="loader-inner ball-pulse" style="transform: scale(0.7); display: none;">
                                                        <div style="background-color: rgb(255, 255, 255);"></div>
                                                        <div style="background-color: rgb(255, 255, 255);"></div>
                                                        <div style="background-color: rgb(255, 255, 255);"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            </div>`);
                        $('#arrival-flight').html('<h5 class="text-white mb-3">' + this.$t('Return trip') + '</h5>' + $('#arrival-result .selected-flight').html());
                        $('.disable').show();
                        $('.price-fl').show();
                    }
                } else if (this.tripType == 'oneway') {
                    setTimeout(() => {
                        $("html, body").animate({
                            scrollTop: $("#departure-result").offset().top - 200
                        }, 10);
                    }, 100);
                    localStorage.removeItem("arrival_flight");
                    localStorage.setItem('departure_flight', JSON.stringify(details));
                    this.departure_selecting = 0;
                    $('#departure-result .flight-result-con').hide();
                    $('#departure-result .selected-flight').html(`
                                <div class="boxoftripresult">
                                    <div class="d-flex justify-content-center">
                                        <div class="px-2 py-4 bg-white text-black w-75 position-relative">
                                            <div class="multi_city_flight_lists w-100">
                                                <div class="flight_multis_area_wrapper">
                                                    <div class="flight_search_left">
                                                        <div class="flight_logo relative">
                                                            <div
                                                                class="flight-image i-${details.itineraries[0].segments[0].carrierCode.toString().toLowerCase()}">
                                                            </div>
                                                        </div>
                                                        <div class="flight_search_destination">
                                                            <h3>${this.searchData.from}</h3>
                                                            <p class="time">
                                                                ${new
                            Date(details.itineraries[0].segments[0].departure.at).toLocaleTimeString('fr-FR',
                                {
                                    hour:
                                        '2-digit', minute: '2-digit'
                                })}
                                                            </p>
                                                            <p class="date">
                                                                ${new
                            Date(details.itineraries[0].segments[0].departure.at).toLocaleDateString('en-US',
                                { day: 'numeric' })}
                                                                ${this.$t(new
                                    Date(details.itineraries[0].segments[0].departure.at).toLocaleDateString('en-US', {
                                        month:
                                            'long'
                                    }))}
                                                                ${new
                            Date(details.itineraries[0].segments[0].departure.at).getFullYear()}
                                                            </p>
                                                            <h6>${this.getLocationByCode(this.searchData.from)['name']}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="flight_right_arrow">
                                                        <img src="${require(`@/assets/icons/right_arrow.png`)}" alt="img" />
                                                        <h6>
                                                            ${details.itineraries[0].segments.length - 1 == 0 ?
                            this.$t('Direct Flight')
                            : details.itineraries[0].segments.length - 1 + ' ' + this.$t('Stop')
                        }
                                                        </h6>
                                                        <p>${this.convertDuration(details.itineraries[0].duration)}</p>
                                                    </div>
                                                    <div class="flight_search_middel">
                                                        <div class="flight_search_destination">
                                                            <h3>
                                                                ${this.searchData.to}
                                                            </h3>
                                                            <p class="time">
                                                                ${new
                            Date(details.itineraries[0].segments[details.itineraries[0].segments.length -
                                1].arrival.at).toLocaleTimeString('fr-FR',
                                    {
                                        hour:
                                            '2-digit', minute: '2-digit'
                                    })}
                                                            </p>
                                                            <p class="date">
                                                                ${new
                            Date(details.itineraries[0].segments[details.itineraries[0].segments.length -
                                1].arrival.at).toLocaleDateString('en-US',
                                    { day: 'numeric' })}
                                                                ${this.$t(new
                                        Date(details.itineraries[0].segments[details.itineraries[0].segments.length -
                                            1].arrival.at).toLocaleDateString('en-US', {
                                                month:
                                                    'long'
                                            }))}
                                                                ${new
                            Date(details.itineraries[0].segments[details.itineraries[0].segments.length -
                                1].arrival.at).getFullYear()}
                                                            </p>
                                                            <h6>${this.getLocationByCode(this.searchData.to)['name']}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="right w-25 px-2 py-3">
                                            <div class=" text-center mt-2">
                                                <p class="text-white mb-0 d-inline-flex pb-1 mx-auto position-relative text-center price">
                                                        ${details.price.total} ${this.$t(details.price.currency)} 
                                                </p>
                                                </br>
                                                <button class="btn btn_select_trip btn-sm change-flight mt-2" data-type="departure">
                                                    <span>${this.$t('Change')}</span>
                                                    <div class="loader-inner ball-pulse" style="transform: scale(0.7); display: none;">
                                                        <div style="background-color: rgb(255, 255, 255);"></div>
                                                        <div style="background-color: rgb(255, 255, 255);"></div>
                                                        <div style="background-color: rgb(255, 255, 255);"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>`);
                    $('#departure-flight').html('<h5 class="text-white mb-3">' + this.$t('Going trip') + '</h5>' + $('#departure-result .selected-flight').html());
                    $('.disable').show();
                    let departure_flight = JSON.parse(localStorage.getItem('departure_flight'));
                    var departure_total = parseFloat(departure_flight.price.grandTotal);

                    $('.price-fl .fl-total').html(' ' + (departure_total).toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' ');
                    $('.price-fl .fl-currency').html(this.$t(departure_flight.price.currency));
                    $('.price-fl').show();
                } else {
                    setTimeout(() => {
                        $("html, body").animate({
                            scrollTop: $("#departure-result").offset().top - 200
                        }, 10);
                    }, 100);
                    localStorage.removeItem("arrival_flight");
                    localStorage.setItem('departure_flight', JSON.stringify(details));
                    this.departure_selecting = 0;
                    var itineraries = ``;
                    details.itineraries.forEach(itinerarie => {
                        itinerarie.segments.forEach(segment => {
                            itineraries += `
                                     <div class="itineraries">
                                        <div class="flight_multis_area_wrapper">
                                            <div class="flight_search_left">
                                                <div class="flight_logo">
                                                    <div class="flight-image i-${segment.carrierCode.toString().toLowerCase()}"></div>
                                                </div>
                                                <div class="flight_search_destination">
                                                    <h3>${segment.departure.iataCode}</h3>
                                                    <p class="time">
                                                                ${new
                                    Date(segment.departure.at).toLocaleTimeString('fr-FR',
                                        {
                                            hour:
                                                '2-digit', minute: '2-digit'
                                        })}
                                                            </p>
                                                            <p class="date">
                                                                ${new
                                    Date(segment.departure.at).toLocaleDateString('en-US',
                                        { day: 'numeric' })}
                                                                ${this.$t(new
                                            Date(segment.departure.at).toLocaleDateString('en-US', {
                                                month:
                                                    'long'
                                            }))}
                                                                ${new
                                    Date(segment.departure.at).getFullYear()}
                                                            </p>
                                                    <h6>${this.getLocationByCode(segment.departure.iataCode)['name']}</h6>
                                                </div>
                                            </div>
                                            <div class="flight_right_arrow"><img
                                                        src="${require(`@/assets/icons/right_arrow.png`)}"
                                                        alt="img">
                                                    <h6> 
                                                        ${new Date(segment.departure.at).toLocaleDateString('en-US', { day: 'numeric' })}
                                                        ${this.$t(new Date(segment.departure.at).toLocaleDateString('en-US', { month: 'long' }))}
                                                        ${new Date(segment.departure.at).getFullYear()}
                                                    </h6>
                                                    <p>${this.convertDuration(segment.duration)}</p>
                                                </div>
                                            <div class="flight_search_middel">
                                                <div class="flight_search_destination">
                                                    <h3>${segment.arrival.iataCode}</h3>
                                                    <p class="time">
                                                                ${new
                                    Date(segment.arrival.at).toLocaleTimeString('fr-FR',
                                        {
                                            hour:
                                                '2-digit', minute: '2-digit'
                                        })}
                                                            </p>
                                                            <p class="date">
                                                                ${new
                                    Date(segment.arrival.at).toLocaleDateString('en-US',
                                        { day: 'numeric' })}
                                                                ${this.$t(new
                                            Date(segment.arrival.at).toLocaleDateString('en-US', {
                                                month:
                                                    'long'
                                            }))}
                                                                ${new
                                    Date(segment.arrival.at).getFullYear()}
                                                            </p>
                                                    <h6>${this.getLocationByCode(segment.arrival.iataCode)['name']}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>`;
                        });
                    });
                    $('#departure-result .flight-result-con').hide();
                    $('#departure-result .selected-flight').html(`
                            <div class="boxoftripresult multi_city">
                                <div class="d-flex justify-content-center">
                                    <div class="px-2 bg-white text-black w-75 position-relative">
                                        <div class="multi_city_flight_lists w-100">
                                            ${itineraries}
                                        </div>
                                    </div>
                                    <div class="right w-25 py-3">
                                        <div class=" text-center">
                                            <p class="text-white mb-0 d-inline-flex pb-1 mx-auto position-relative text-center price">
                                                        ${details.price.total} ${this.$t(details.price.currency)} 
                                            </p>
                                            </br>
                                            <button class="btn btn_select_trip btn-sm change-flight mt-2" data-type="departure">
                                                <span>${this.$t('Change')}</span>
                                                <div class="loader-inner ball-pulse" style="transform: scale(0.7); display: none;">
                                                    <div style="background-color: rgb(255, 255, 255);"></div>
                                                    <div style="background-color: rgb(255, 255, 255);"></div>
                                                    <div style="background-color: rgb(255, 255, 255);"></div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `);
                    $('#departure-flight').html('<h5 class="text-white mb-3">' + this.$t('booking-details') + '</h5>' + $('#departure-result .selected-flight').html());
                    $('.disable').show();
                    let departure_flight = JSON.parse(localStorage.getItem('departure_flight'));
                    var departure_total = parseFloat(departure_flight.price.grandTotal);
                    $('.price-fl .fl-total').html(' ' + (departure_total).toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' ');
                    $('.price-fl .fl-currency').html(this.$t(departure_flight.price.currency));
                    $('.price-fl').show();
                }
            }, 10);
            setTimeout(() => {
                element.prop('disabled', false);
                element.find('span').show();
                element.find('.loader-inner').hide();
            }, 500);
        },
        booking_submission() {
            if (this.isLogin) {
                localStorage.setItem('min', this.min);
                const currentURL = window.location.href;
                const match = currentURL.match(/flight-search-result\?(.+)/);
                const queryString = match[1];
                this.$router.push(`/flight-booking-submission?${queryString}`);
            } else {
                window.$('#login-modal').modal('show');
            }
        },
        getUniqueSegmentLengths(flightOffers) {
            const uniqueSegmentLengths = new Set();
            flightOffers.forEach((offer) => {
                if (this.departure_selecting == 1) {
                    offer.itineraries.forEach((itinerary, index) => {
                        if (index == 0) {
                            uniqueSegmentLengths.add(itinerary.segments.length);
                        }
                    });
                } else {
                    offer.itineraries.forEach((itinerary, index) => {
                        if (index == 1) {
                            uniqueSegmentLengths.add(itinerary.segments.length);
                        }
                    });
                }
            });
            return Array.from(uniqueSegmentLengths);
        },
        Jquery() {
            $(document).on('click', '.change-flight', function () {
                var type = $(this).data('type');
                $(this).prop('disabled', true);
                $(this).find('span').hide();
                $(this).find('.loader-inner').show();
                $('.booking-confirmation').hide();
                $('.flights-area').show();
                setTimeout(() => {
                    if (type == 'multi_city') {
                        $(".filter-airlines").attr('disabled', false);
                        $('.disable').hide();
                        $('.price-fl').hide();
                        $('#departure-result .flight-result-con').show();
                        $('#departure-result .selected-flight').html('');
                        that.departure_selecting = 1;
                    } else {
                        if (type == 'departure') {
                            that.departure_selecting = 1;
                            that.arrival_selecting = 1;
                            that.stops = that.getUniqueSegmentLengths(that.allData.data).sort(function (a, b) {
                                return a - b;
                            });
                            that.count = that.result.data.length;
                            $(".filter-stops").each(function (index) {
                                if (index != 0) {
                                    $(this).prop('checked', false);
                                }
                            });
                            $(".filter-airlines").attr('disabled', false);
                            $('.disable').hide();
                            $('.price-fl').hide();
                            $('#arrival-result').hide();
                            $('#arrival-result .selected-flight').html('');
                            $('#departure-result .selected-flight').html('');
                            $('#departure-result .flight-result-con').show();
                        } else {
                            that.arrival_selecting = 1;
                            $('.disable').hide();
                            $('.price-fl').hide();
                            $('#arrival-result').show();
                            $('#arrival-result .selected-flight').html('');
                            $('#arrival-result .flight-result-con').show();
                        }
                    }
                }, 10);
            });
            $(document).on('change', '.filter-airlines', function () {
                var array = [];
                $(".filter-airlines").each(function () {
                    if ($(this).prop('checked') == true) {
                        array.push($(this).val());
                    }
                });
                const filteredFlights = that.allData.data.filter((flight) => {
                    for (const itinerary of flight.itineraries) {
                        for (const segment of itinerary.segments) {
                            if (array.includes(segment.carrierCode)) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
                if (filteredFlights.length == 0) {
                    $(this).attr('disabled', true);
                } else {
                    that.count = filteredFlights.length;
                    that.result.data = filteredFlights;
                }
            });
        },
    },
    mounted() {
        that = this;
        this.Jquery();
        this.tripType = this.$route.query.type;
        this.searchData = {
            currency: this.$route.query.currency,
            from: this.$route.query.from,
            to: this.$route.query.to,
            date: this.$route.query.date,
            ret_date: this.$route.query.ret_date,
            adults: this.$route.query.adults,
            children: this.$route.query.children,
            infants: this.$route.query.infants,
            cabin: this.$route.query.cabin,
            nonStop: false,
            max: 15,
            maxPrice: "",
            type: this.tripType
        };
        if (localStorage.getItem("access_token")) {
            this.isLogin = localStorage.getItem("access_token");
        }
        var targetTop = $("#target-div").offset().top;
        if (this.tripType != 'multi_city') {
            $("html, body").animate({ scrollTop: targetTop - 120 }, 'slow');
        } else {
            $("html, body").animate({ scrollTop: targetTop - 120 }, 'slow');
        }
        var currency = this.$route.query.currency,
            from = this.$route.query.from,
            to = this.$route.query.to,
            date = this.$route.query.date,
            ret_date = this.$route.query.ret_date,
            adults = this.$route.query.adults,
            children = this.$route.query.children,
            infants = this.$route.query.infants,
            cabin = this.$route.query.cabin,
            type = this.$route.query.type;
        var body = {
            'from': from,
            'to': to,
            'date': date,
            'ret_date': ret_date,
            'currency': currency,
            'adults': adults,
            'children': children,
            'infants': infants,
            'cabin': cabin,
            'nonStop': false,
            'maxPrice': '',
            'max': 200,
            'type': type,
            'lang': this.$i18n.locale
        };
        var url = `${this.$appUrl}/api/flights/result`;
        if (this.$route.query['date[]']) {
            body['from'] = JSON.stringify(this.$route.query['from[]']);
            body['to'] = JSON.stringify(this.$route.query['to[]']);
            body['date'] = JSON.stringify(this.$route.query['date[]']);
        }
        this.axios.post(url, body).then((response) => {
            const data = response.data.data;
            this.result = data;
            this.allData = Object.assign([], data);
            this.count = data.data.length;
            this.airlines = data.airlines;
            this.date = new Date(date);
            this.ret_date = new Date(ret_date);
            if (this.result.data.length == 0) {
                $('#noFlights').show();
                toast.error('There are no flights available', {
                    autoClose: 2500,
                    position: toast.POSITION.TOP_RIGHT,
                    closeButton: true
                });
                setTimeout(() => {
                    window.location.href = '/';
                }, 2500);

            } else {
                data.data.forEach(element => {
                    if (that.min > element.price.grandTotal) {
                        that.min = Number(element.price.grandTotal);
                    }
                    if (that.max < element.price.grandTotal) {
                        that.max = Number(element.price.grandTotal);
                    }
                    element.itineraries[0].segments.forEach(segments => {
                        that.airlines[segments.carrierCode] = airlinesData[segments.carrierCode];
                    });
                });
                $('#filter-price-min').html(parseFloat(that.min).toFixed(2));
                $('#filter-price-max').html(parseFloat(that.max).toFixed(2));
                that.price_rang = [Number(that.min), Number(that.max)];
            }
            if (response.data.status == 0) {
                this.$toast.open({
                    message: response.data.message,
                    type: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            } else {
                $('.searching').hide();
                $('.found').show();
                setTimeout(() => {
                    $('#searchloader').hide();
                    $('.flights-div').css('display', 'flex');
                }, 2000);

            }
        });
        event.on('login-event', function (data) {
            that.isLogin = data.isLogin;
        });
    },
};
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style lang="scss" scoped>
.section_padding {
    padding: 65px 0;
}

.flight_inner_show_component {
    justify-content: center;
}

.airline-details {
    justify-content: flex-start;
    padding-right: 15px;
}

.flight_show_down_wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
}


.disable {
    display: none;
    position: absolute;
    width: calc(100% - 24px);
    height: calc(100% - 30px);
    background-color: #d1af7712;
    z-index: 8;
    cursor: not-allowed;
    margin-left: 0px;
    transition: 0.3s;
    left: 12px;
    border-radius: 8px;
}

.price-fl .txt-price {
    font-size: 1.75rem;
}

.col-md-offset-8 {
    margin-right: 66.66666667%;
    margin-left: 0;
}

.new-bold-dark {
    font-weight: 400;
    color: var(--main-color);
}

.btn-fa-primary {
    background-color: var(--main-color);
    border: 0;
    color: #fff;
    outline: 0;
    box-shadow: none;
    border-radius: 5px;
    transition: .3s;
    margin-top: 10px;
    float: left;

    &:hover {
        background-color: var(--main-color);
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.p15 {
    padding: 15px !important;
}
</style>
<style lang="scss">
.flight_logo {
    width: 92px;
    text-align: -webkit-center;
}

.area_flex_one span {
    color: var(--black-color);
    font-weight: 500;
    padding-top: 5px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 140px;
}

#noFlights .seatNotFound,
#searchloader .searching {
    width: 60%;
}

#searchloader .searching-con {
    text-align: center;
}

#noFlights,
#searchloader {
    height: 100% !important;
}

#noFlights,
#searchloader {
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: calc(100% - 0px);
}

#noFlights .no-search-con,
#searchloader .search-con {
    width: 600px;
    border-radius: 3px;
}

.pl0 {
    padding-left: 0 !important;
}

.mt20 {
    margin-top: 20px !important;
}

.p15 {
    padding: 15px !important;
}

#searchloader .searching-con {
    width: 400px;
    height: 400px;
    display: block;
    border-radius: 50%;
    margin: auto;
}

#noFlights .seatNotFound,
#searchloader .searching {
    display: inline-block;
    width: 100%;
    -webkit-animation: booble 1.3s infinite ease-in;
    -moz-animation: booble 1.3s infinite ease-in;
    -o-animation: booble 1.3s infinite ease-in;
    animation: booble 1.3s infinite ease-in;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    border-radius: inherit;
}

#noFlights .no-searching-con {
    width: 400px;
    height: 340px;
    display: block;
    border-radius: 50%;
    margin: auto;
}

#noFlights .seatNotFound,
#searchloader .searching {
    display: inline-block;
    width: 100%;
    -webkit-animation: booble 1.3s infinite ease-in;
    -moz-animation: booble 1.3s infinite ease-in;
    -o-animation: booble 1.3s infinite ease-in;
    animation: booble 1.3s infinite ease-in;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    border-radius: inherit;
}

.skeleton {
    .flight_search_right {
        background: #f3eff900;
        padding: 25px 16px 20px 25px;
        border-right: 1px solid #ddd;
    }

    .flight-image {
        filter: grayscale(95%) brightness(175%);
    }

    .left_side_search_heading h5 {
        padding-bottom: 20px;
        text-align: center;
    }

    .filter-price {
        padding-top: 15px;
        padding-bottom: 0px;
        text-align: center;
    }

    .tour_search_type .form-check {
        text-align: center;
        padding-left: 0px;
    }
}

.multi_city {
    .multi_city_flight_lists {
        .itineraries {
            padding: 18px 25px;
            border-bottom: 1px dashed #ddd;
            &:last-child {
                border-bottom: none;
            }
        }
    }

    .flight_search_right {
        background: #fff;
    }
}
.boxoftripresult {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 6px;
}
.boxoftripresult .right {
    background-color: #d1af77;
}
.boxoftripresult .btn_select_trip{
    border: 1px solid #fff;
    border-radius: 5px;
    padding: .2rem 1.3rem;
    color: #fff;
    background-color: #2f363d;
    &:hover{
        background-color: #2f363d;
    }
}
.result-flights .btn_select_trip {
    min-width: 90px;
}
.result-flights p, .result-flights p:last-child {
    margin-bottom: 0!important;
}
.result-flights .price {
    font-size: 20px;
}
.result-flights p {
    line-height: 28px;
    font-weight: 400;
}
.text {
    font-size: 12px;
}
.boxoftripresult .price:after {
    content: "";
    height: 3px!important;
    width: 90%;
    position: absolute;
    bottom: -2px;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
}
.result-flights .dduKua {
    height: 30px;
    font-size: 12px;
    font-weight: 600;
    background: #323940;
    color: #fff;
    padding: 2px 5px;
    min-width: 92px;
    position: absolute;
    line-height: 25px;
    inset-block-start: 0;
    inset-inline-end: 0;
    text-align: center;
}
.result-flights .flight_search_middel {
    display: flex;
    align-items: center;
    padding-left: 0;
    width: 35%;
}
.result-flights .flight_right_arrow {
    text-align: center;
    width: 20%;
}
.result-flights .flight_search_left {
    display: flex;
    align-items: center;
    padding: 0 0 0 0;
    width: 45%;
    justify-content: center;
}
.result-flights .flight_search_destination h3 {
    font-weight: 600;
    padding-top: 5px;
    font-size: 24px;
    line-height: 1.1;
    margin: 0;
}
.result-flights .flight_search_destination .time {
    font-size: 18px;
    line-height: 1.6;
    color: #000;
}
.result-flights .flight_search_destination p {
    font-size: 14px;
    line-height: 16px;
    color: #000;
}
.result-flights .flight_search_destination h6 {
    padding-top: 5px;
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 140px;
}
.result-flights .flight_search_destination {
    text-align: center;
}
.text-black {
    color: #000!important;
}
.result-flights .skeleton .right {
    background-color: #d1af77;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.result-flights .flight_right_arrow h6 {
    font-size: 11px;
    font-weight: 700;
    padding-top: 10px;
    margin: 0;
}
.result-flights .flight_logo {
    width: 100px;
    text-align: -webkit-center;
    position: relative;
}
.result-flights .flight_show_down_wrapper {
    display: flex;
    border-bottom: 1px solid #edf1f3;
    padding-top: 25px;
    padding-bottom: 25px;
}
.boxshowmore {
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.boxoftripresult.is_open{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #edf1f3;
}
.result-flights .flight_show_down_wrapper:last-child {
    border-bottom: none;
}
.result-flights .flight_show_down_wrapper:last-child {
    border-bottom: none;
}
.result-flights .flight-shoe_dow_item {
    padding: 0 40px;
}
.w-100 {
    width: 100%!important;
}
.result-flights .flight_inner_show_component {
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
}
.result-flights .airline-details {
    justify-content: flex-start;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}
.result-flights .skeleton .flight_search_middel {
    padding-left: 0;
    padding-right: 30px;
}
.left_side_search_boxed{
    .form-check-input:checked {
        background-color: var(--main-color);
        border-color: var(--main-color);
    }
}
.result-flights #arrival-result, .result-flights #departure-result {
    margin-bottom: 30px;
}
.result-flights .multi_city .itineraries {
    padding: 18px 25px;
    border-bottom: 1px dashed #ddd;
}
.result-flights .multi_city .right {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
</style>