<template>

    <!-- Common Banner Area -->
    <ProfileBanner />

    <!-- Dashboard Area -->
    <Profile />

</template>
<script>

import ProfileBanner from '@/components/user/profile/ProfileBanner.vue'
import Profile from '@/components/user/profile/Profile.vue'

export default {
    name: "ProfileView",
    components: {
        ProfileBanner, Profile
    }
};
</script>
