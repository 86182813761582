<template>
    <section id="testimonials_area" class="section_padding_top testimonials_area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>{{ $t('Customer opinions') }}</h2>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <Carousel :itemsToShow="3.95" :wrapAround="true" :transition="500">
                        <Slide v-for="item in items" :key="item">
                            <div class="col-lg-12 col-md-12">
                                <div class="testimonial-card">
                                    <div class="review_text">
                                        <img :src="item.image" alt="img">
                                        <h4>{{item.name}}</h4>
                                        <p>"كشركة من أهم المزايا الموجودة هي التتبع المباشر التي تساعدنا في معرفة
                                            الرحلات
                                            المستخدمة
                                            ومواعيدها وتكلفتها. - {{item.name}}"</p>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    </Carousel>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
    name: "Testimonials",
    components: {
        Carousel,
        Slide,
    },
    data() {
        return {
            items: [
                {
                    image: require("@/assets/img/client/AlibabCloud.png"),
                    name: 'Alibab Cloud',
                    desc: 'By providing a wide variety of airline ticket prices, allowing users to choose the option that best suits their budget and needs.',
                },
                {
                    image: require("@/assets/img/client/Foodics.png"),
                    name: 'Foodics',
                    desc: 'By providing a wide variety of airline ticket prices, allowing users to choose the option that best suits their budget and needs.',
                },
                {
                    image: require("@/assets/img/client/Knowliom.png"),
                    name: 'Knowliom',
                    desc: 'By providing a wide variety of airline ticket prices, allowing users to choose the option that best suits their budget and needs.',
                },
                {
                    image: require("@/assets/img/client/NEOM.png"),
                    name: 'Neom',
                    desc: 'By providing a wide variety of airline ticket prices, allowing users to choose the option that best suits their budget and needs.',
                },
                {
                    image: require("@/assets/img/client/jevent.png"),
                    name: 'jevent',
                    desc: 'By providing a wide variety of airline ticket prices, allowing users to choose the option that best suits their budget and needs.',
                },
                {
                    image: require("@/assets/img/client/StcGroup.png"),
                    name: 'Stc Group',
                    desc: 'By providing a wide variety of airline ticket prices, allowing users to choose the option that best suits their budget and needs.',
                },
            ],
        }
    }
};
</script>