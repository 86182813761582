<template>
    <div v-if="isLogin" id="body">
        <div id="chat-circle" class="btn btn-raised">
            <div id="chat-overlay"></div>
            <i class="fl-30-ico chat"></i>
        </div>
        <div class="chat-box">
            <div class="chat-box-header">
                {{ $t('Support Chat') }}
                <span class="chat-box-toggle">
                    <i class="fl-ico _close"></i>
                </span>
            </div>
            <div class="chat-box-body">
                <div class="chat-box-overlay">
                </div>
                <div class="chat-logs">
                    <div v-for="(message, index) in messages" :key="index" class="row message-body">
                        <div
                            :class="message.admin_id == null ? 'col-sm-12 message-main-send' : 'col-sm-12 message-main-received'">
                            <div :class="message.admin_id == null ? 'send' : 'received'">
                                <div v-if="message.message_type == 'text'" class="message-text">
                                    <pre>{{ message.body }}</pre>
                                </div>
                                <div v-else-if="message.message_type == 'image'" class="message-text">
                                    <a :href="message.attachment.url" download>
                                        <img :src="message.attachment.url">
                                    </a>
                                </div>
                                <div v-else-if="message.message_type == 'audio'" class="message-text">
                                    <audio id="myAudio" controls :src="message.attachment.url"></audio>
                                </div>
                                <div v-else-if="message.message_type == 'file'" class="message-text">
                                    <a :href="message.attachment.url" :download="message.attachment.name">
                                        <button class="i5tg98hk" tabindex="0"
                                            :title="`Download ${message.attachment.name}`" type="button">
                                            <div class="sfeitywo">
                                                <div class="fhf7t426">
                                                    <div class=""
                                                        style="flex-shrink: 0;display: flex;background-repeat: no-repeat;background-size: contain;height: 30px;width: 26px;flex-grow: 0;min-height: 0;flex-basis: auto;min-width: 0;order: 0;">
                                                        <div class="sxl192xd"></div>
                                                    </div>
                                                    <div class="spjzgwxb">
                                                        <div class="m1c2hokz">
                                                            <span dir="auto">
                                                                {{ message.attachment.name }}
                                                            </span>
                                                        </div>
                                                        <div class="e4p1bexh">
                                                            <span title="pdf">{{ message.attachment.type }}</span>
                                                            <span></span>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                    <div style="flex-grow: 0; flex-shrink: 0;"></div>
                                                </div>
                                            </div>
                                        </button>
                                    </a>
                                </div>
                                <span class="message-time pull-right">
                                    {{ new Date(message?.created_at).toLocaleTimeString('fr-FR', {
                                        hour:
                                            '2-digit', minute: '2-digit',
                                        hour12: true
                                    }) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div><!--chat-log -->
            </div>
            <div class="file-container">
                <span class="file-name">
                    <svg height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2 0C0.9 0 0.01 0.9 0.01 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.42L10.58 0.59C10.21 0.21 9.7 0 9.17 0H2ZM9 6V1.5L14.5 7H10C9.45 7 9 6.55 9 6ZM4 10C3.44772 10 3 10.4477 3 11C3 11.5523 3.44772 12 4 12H12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12 10H4ZM10 15C10 14.4477 9.55228 14 9 14H4C3.44772 14 3 14.4477 3 15C3 15.5523 3.44772 16 4 16H9C9.55228 16 10 15.5523 10 15Z"
                            fill="var(--attachment-type-documents-color)"></path>
                    </svg>
                    <span>
                    </span>
                </span>
                <svg @click="ClearFile" class="clear-file pointer" viewBox="0 0 24 24" height="24" width="24"
                    preserveAspectRatio="xMidYMid meet" fill="currentColor" enable-background="new 0 0 24 24"
                    xml:space="preserve">
                    <path
                        d="M19.6004 17.2L14.3004 11.9L19.6004 6.60005L17.8004 4.80005L12.5004 10.2L7.20039 4.90005L5.40039 6.60005L10.7004 11.9L5.40039 17.2L7.20039 19L12.5004 13.7L17.8004 19L19.6004 17.2Z">
                    </path>
                </svg>
            </div>
            <div v-if="recording" class="input-containert  justify-content-end">
                <span class="timer">00:00</span>
                <div>
                    <img src="../../assets/img/download.png">
                </div>
                <svg @click="clearRecord" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 22"
                    class="pointer" style="color: #ff7f7f;">
                    <path d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                        fill="currentColor"></path>
                </svg>
                <button @click="sendMessage()" class="btn btn-chat-send">
                    <i class="chat-btn btn-send"></i>
                </button>
            </div>
            <div v-else class="chat-input">
                <input v-model="message" type="text" id="chat-input" :placeholder="$t('Send a message')"
                    autocomplete="off" />
                <div class="inline-block float-left chat-text ">
                    <label v-if="!canSend" for="file">
                        <button class="btn btn-img-send">
                            <i class="chat-btn btn-img"></i>
                        </button>
                    </label>
                    <input type="file" name="file" id="file" class="d-none" accept="image/png, image/gif, image/jpeg">

                    <button v-if="!canSend" class="btn btn-record-audio" @click="startRecord">
                        <i class="chat-btn btn-mic"></i>
                    </button>
                    <button v-if="canSend" @click="sendMessage" class="btn btn-chat-send">
                        <i class="chat-btn btn-send"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
var that;
import $ from 'jquery';
import pusher from '../../plugins/pusher';
import WhatsappMessageSent from '../../assets/audio/WhatsappMessageSent.mp3';
import WhatsAppNewMessage from '../../assets/audio/WhatsAppNewMessage.mp3';
import supportService from '@/services/support/support-service';
export default {
    name: 'ChatButton',
    data() {
        return {
            messages: [],
            message: '',
            user: [],
            title: "Conversations",
            canSend: false,
            recording: false,
            audioContext: null,
            mediaRecorder: null,
            audioChunks: [],
            timer: null,
            audioBlob: null,
            audioAction: '',
            fileSrc: '',
            fileType: '',
            perPage: 10,
            page: 1,
            last_page: 0,
            user: null,
            isLogin: false,
            chat_open: false
        };
    },
    methods: {
        updateTimer(startTime) {
            this.timer = setInterval(function () {
                var currentTime = new Date().getTime();
                var elapsedTime = new Date(currentTime - startTime);
                var minutes = String(elapsedTime.getMinutes()).padStart(2, '0');
                var seconds = String(elapsedTime.getSeconds()).padStart(2, '0');
                $('.timer').html(`${minutes}:${seconds}`);
            }, 1000);
        },
        startRecord() {
            this.audioChunks = [];
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    var currentTime = new Date().getTime();
                    this.updateTimer(currentTime);
                    this.audioContext = new AudioContext();
                    this.mediaRecorder = new MediaRecorder(stream);
                    this.mediaRecorder.ondataavailable = (event) => {
                        if (event.data.size > 0) {
                            this.audioChunks.push(event.data);
                        }
                    };
                    this.mediaRecorder.onstop = (a) => {
                        if (this.audioAction == 'send') {
                            this.audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
                            const audioUrl = URL.createObjectURL(this.audioBlob);
                            this.messages.push({
                                admin_id: null,
                                message_type: 'audio',
                                attachment:
                                {
                                    'name': 'audio.wav',
                                    'type': 'wav',
                                    'size': '0.0',
                                    'url': audioUrl,
                                },
                                created_at: new Date()
                            });
                            const formData = new FormData();
                            formData.append('attachment', this.audioBlob, 'recorded_audio.wav');
                            formData.append('message_type', 'audio');
                            const xhr = new XMLHttpRequest();
                            xhr.open('POST', `${this.$appUrl}/api/users/support/send_message`, true);
                            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("access_token"));
                            xhr.onload = () => {
                                if (xhr.status === 200) {
                                    var audio = new Audio(WhatsappMessageSent);
                                    audio.play();
                                } else {
                                    console.error('Error sending audio:', xhr.statusText);
                                }
                            };
                            xhr.send(formData);
                        }
                        clearInterval(this.timer);
                    };
                    this.mediaRecorder.start();
                    this.recording = true;
                }).catch((error) => {
                    console.error('Error accessing microphone:', error);
                });
        },
        clearRecord() {
            if (this.recording) {
                this.mediaRecorder.stop();
                this.audioContext.close();
                this.recording = false;
            }
        },
        ClearFile() {
            $('#chat-input').prop('disabled', false);
            $('.file-container').css('display', 'none');
            $('#file').val(null);
            that.canSend = false;
        },
        sendMessage() {
            if (this.recording) {
                this.audioAction = 'send';
                this.mediaRecorder.stop();
                this.audioContext.close();
                this.recording = false;
            } else {
                if ($('#file').val() != '') {
                    if (this.fileType == 'image') {
                        this.messages.push({
                            admin_id: null,
                            message_type: 'image',
                            attachment:
                            {
                                'name': 'image',
                                'type': 'png',
                                'size': '0.0',
                                'url': this.fileSrc,
                            },
                            created_at: new Date()
                        });
                        const formData = new FormData();
                        formData.append('message_type', 'image');
                        formData.append('attachment', $('#file').get(0).files[0]);
                        const xhr = new XMLHttpRequest();
                        xhr.open('POST', `${this.$appUrl}/api/users/support/send_message`, true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("access_token"));
                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                var audio = new Audio(WhatsappMessageSent);
                                audio.play();
                            } else {
                                console.error('Error sending audio:', xhr.statusText);
                            }
                        };
                        xhr.send(formData);
                    } else {
                        var val = $('#file').val();
                        var ext = val.substring(val.lastIndexOf('.') + 1).toLowerCase();
                        var filename = $('#file').val().replace(/C:\\fakepath\\/i, '');
                        this.messages.push({
                            admin_id: null,
                            message_type: 'file',
                            attachment:
                            {
                                'name': filename,
                                'type': ext,
                                'size': '0.0',
                                'url': this.fileSrc,
                            },
                            created_at: new Date()
                        });
                        const formData = new FormData();
                        formData.append('message_type', 'file');
                        formData.append('attachment', $('#file').get(0).files[0]);
                        const xhr = new XMLHttpRequest();
                        xhr.open('POST', `${this.$appUrl}/api/users/support/send_message`, true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("access_token"));
                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                var audio = new Audio(WhatsappMessageSent);
                                audio.play();
                            } else {
                                console.error('Error sending audio:', xhr.statusText);
                            }
                        };
                        xhr.send(formData);
                    }
                } else {
                    this.messages.push({
                        admin_id: null,
                        message_type: 'text',
                        body: this.message,
                        created_at: new Date()
                    });
                    const formData = new FormData();
                    formData.append('message_type', 'text');
                    formData.append('message', this.message);
                    supportService.send_message(formData).then((response) => {
                        var audio = new Audio(WhatsappMessageSent);
                        audio.play();
                    });
                }
                this.message = '';
                this.ClearFile();
                this.canSend = false;
            }
            $(".chat-logs").animate({
                scrollTop: 9999999
            }, 'slow');
        },
        Jquery() {
            $(document).on('change', '#file', function () {
                var input = this;
                var val = $(this).val();
                var ext = val.substring(val.lastIndexOf('.') + 1).toLowerCase();
                var filename = $('#file').val().replace(/C:\\fakepath\\/i, '');
                $('.file-container .file-name span').html(filename);
                $('.file-container').css('display', 'flex');
                if (input.files && input.files[0] && (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg")) {
                    that.fileType = 'image';
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        that.fileSrc = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                } else {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        that.fileSrc = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                    that.fileType = 'file';
                }
                this.message = '';
                $('#chat-input').prop('disabled', true);
                that.canSend = true;
            });
            $(document).on('click', '.btn-img-send', function () {
                $('#file').click();
            });
            $(document).on('input', '#chat-input', function () {
                var val = $(this).val();
                if (val.length > 0) {
                    that.canSend = true;
                } else {
                    that.canSend = false;
                }
            });
            $(document).on('click', '#chat-circle', function () {
                that.chat_open = true;
                $(".chat-logs").animate({
                    scrollTop: 9999999
                }, 'slow');
                $("#chat-circle").toggle('scale');
                $(".chat-box").toggle('scale');
            })
            $(document).on('click', '.chat-box-toggle', function () {
                that.chat_open = false;
                $("#chat-circle").toggle('scale');
                $(".chat-box").toggle('scale');
            });
        },
        async get_all_messages() {
            await supportService.get_all_messages({ perPage: this.perPage }).then((response) => {
                this.messages = response.data.messages.reverse();
                // this.page = response.data.messages.current_page;
                // this.last_page = response.data.messages.last_page;
                $(".chat-logs").animate({
                    scrollTop: 9999999
                }, 'slow');
            });
        },
        pusher_subscribe() {
            const channel = pusher.subscribe('notifications-channel');
            channel.bind('new-notification', (data) => {
                var message = JSON.parse(data);
                message.admin_id = 1;
                if (message.to_user_id == this.user.id && message.user_type == 'user') {
                    this.messages.push(message);
                    $(".chat-logs").animate({
                        scrollTop: 9999999
                    }, 'slow');
                    if (that.chat_open == true) {
                        var audio = new Audio(WhatsAppNewMessage);;
                        audio.play();
                    }
                }
            });
        }
    },
    mounted() {
        that = this;
        this.Jquery();
        if (localStorage.getItem("access_token")) {
            var user = this.$cookies.get('user');
            if (user && user != 'undefined') {
                this.user = JSON.parse(JSON.stringify(user));
            }
            this.isLogin = localStorage.getItem("access_token");
            this.get_all_messages();
            this.pusher_subscribe();
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/chat-button.scss';
</style>