<template>
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>{{ $t('policies') }}</h2>
                        <ul>
                            <li><router-link to="/">{{ $t('Home') }}</router-link></li>
                            <li>
                                <span><i class="fas fa-circle"></i></span>
                                    {{ $t('Booking Confirmation') }}
                            </li>
                            <li>
                                <span><i class="fas fa-circle"></i></span>
                                {{ $t('policies') }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "BookingPoliciesBanner"
};
</script>