<template>
    <section id="dashboard_main_arae" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="dashboard_common_table">
                        <div class="wallwt_area_top">
                            <h3>{{ $t('Wallet') }}</h3>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="wallet_area_boxed">
                                        <h4>{{ $t('My wallet') }}</h4>
                                        <div class="wallet_blance_boxed">
                                            <p>{{ $t('Wallet balance') }}</p>
                                            <h5>{{ wallet_balance.toFixed(2) }} {{ $t('SAR') }}</h5>
                                        </div>
                                        <div class="wallet_boxed_flex">
                                            <div class="wallet_blance_boxed">
                                                <p>{{ $t('Total credit') }}</p>
                                                <h5>{{ total_credit.toFixed(2) }} {{ $t('SAR') }}</h5>
                                            </div>
                                            <div class="wallet_blance_boxed">
                                                <p>{{ $t('Total debit') }}</p>
                                                <h5>{{ total_debit.toFixed(2) }} {{ $t('SAR') }}</h5>
                                            </div>
                                        </div>
                                        <div class="dashboard_price_range">
                                            <div class="main_range_price"></div>
                                            <div class="price_range_blance">
                                                <p>{{ total_credit.toFixed(2) }} {{ $t('SAR') }}</p>
                                                <p>{{ total_debit.toFixed(2) }} {{ $t('SAR') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="wallet_area_boxed">
                                        <h4>{{ $t('Wallet charging') }}</h4>
                                        <div class="add_balance_area">
                                            <div class="input-group">
                                                <span class="input-group-text">{{ $t('SAR') }}</span>
                                                <input type="number" v-model="charging_amount" class="form-control"
                                                    aria-label="Amount (to the nearest dollar)">
                                            </div>
                                            <div class="other_add_balance_area">
                                                <span>{{ $t('or') }}</span>
                                                <div class="other_add_bal_button">
                                                    <label>
                                                        <input type="radio" name="amount" v-model="selected_amount"
                                                            class="d-none" value="100">
                                                        <span class="btn btn_add_bal">
                                                            100
                                                            {{ $t('SAR') }}
                                                        </span>
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="amount" v-model="selected_amount"
                                                            class="d-none" value="400">
                                                        <span class="btn btn_add_bal">
                                                            400
                                                            {{ $t('SAR') }}
                                                        </span>
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="amount" v-model="selected_amount"
                                                            class="d-none" value="500">
                                                        <span class="btn btn_add_bal">
                                                            500
                                                            {{ $t('SAR') }}
                                                        </span>
                                                    </label>
                                                </div>
                                                <button type="button" class="btn btn_theme btn_md w-100"
                                                    data-bs-toggle="modal" data-bs-target="#wallet-charging-modal">
                                                    {{ $t('Wallet charging') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 class="wallet_table_top">{{ $t('Wallet transaction') }}</h3>
                            <div class="wallet_data_table">
                                <div class="table-responsive-lg table_common_area">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>{{ $t('Date') }}</th>
                                                <th>{{ $t('Credit') }}</th>
                                                <th>{{ $t('Debit') }}</th>
                                                <th>{{ $t('payment method') }}</th>
                                                <th>{{ $t('status') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in transactions ">
                                                <td>
                                                    {{ this.formatDate(item.created_at) }}
                                                </td>
                                                <td>
                                                    {{ item.type == 'deposit' ? parseFloat(item.amount).toFixed(2)
                                                        : parseFloat(0).toFixed(2) }} {{ $t('SAR') }}
                                                </td>
                                                <td>
                                                    {{ item.type == 'deposit' ? parseFloat(0).toFixed(2) :
                                                        parseFloat(item.amount).toFixed(2) }} {{ $t('SAR') }}
                                                </td>
                                                <td>
                                                    {{ item.payment_method == 'credit' ? $t('credit') :
                                                        item.bank?.bank_name }}
                                                </td>
                                                <td>
                                                    <span v-bind:class="item.status">
                                                        {{ $t(item.status ?? '') }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="data_count == 0" class="rt-noData">لا يوجد بيانات</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="wallet-charging-modal" data-bs-backdrop="static" data-bs-keyboard="false"
            tabindex="-1" aria-labelledby="staticLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title  w-100">
                            {{ $t('Payment Details') }}
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <div class="no-margin mb20">
                                    <div class="row align no-margin">
                                        <div class="col-md-12 col-middle">
                                            <label class="checkbox-container">
                                                <input v-model="payment_method" type="radio" name="payment_method"
                                                    value="credit" :disabled="this.corporate_travel">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="inline-middle mr5 ml5">{{ $t('Credit Card/mada Card')
                                                }}</span>
                                        </div>
                                        <div v-if="this.payment_method == 'credit'" class="col-md-12 mt-3">
                                            <span>البطاقة الإئتمانية :</span>
                                            &emsp;
                                            <img v-if="primary_card?.brand_name == 'VISA'"
                                                src="@/assets/icons/ic_visa.png" width="50">
                                            <img v-else-if="primary_card?.brand_name == 'MASTER'"
                                                src="@/assets/icons/ic_master.png" width="50">
                                            <img v-else src="@/assets/icons/ic_mada.png" width="50">
                                            &emsp;
                                            <span>تنتهي ب </span>
                                            &emsp;
                                            <span>
                                                {{
                                                    primary_card?.card_number?.substr(primary_card?.card_number?.length
                                                        - 4) }}
                                            </span>
                                            &emsp;
                                            <span>
                                                <a href="javascript:void(0);" @click="change_credit">
                                                    تغيير
                                                </a>
                                            </span>
                                        </div>
                                        <div v-if="this.payment_method == 'credit'" class="col-md-12 mt-3">
                                            <input type="text" v-model="cvv" class="cvv" pattern="\d*" maxlength="3"
                                                placeholder="CVV">
                                            &emsp;
                                            <span>
                                                <a href="javascript:void(0);"
                                                    v-tooltip.top="'رقم توثيق البطاقة (CVV) هي آخر ثلاث أرقام تظهر خلف البطاقة.'">
                                                    <i class="fa fa-info-circle"></i>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row align no-margin">
                                        <div class="mt15 mb15 col-md-12">
                                            <div class="division"></div>
                                        </div>
                                    </div>
                                    <div class="row align no-margin">
                                        <div class="col-md-12  col-12 col-middle">
                                            <label class="checkbox-container">
                                                <input v-model="payment_method" type="radio" name="payment_method"
                                                    value="bank" :disabled="this.corporate_travel ? true : false">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="inline-middle mr5 ml5">
                                                {{ $t('Bank transfer') }}
                                            </span>
                                        </div>
                                        <div
                                            v-bind:class="'col-md-12  col-12 col-middle mt-3 mb-3 ' + (payment_method == 'bank' ? '' : 'd-none')">
                                            <select v-model="bank_id" ref="select2" class="banks-select"
                                                style="border-bottom: 1px solid #ccc;width: 100%;" dir="rtl">
                                                <option :value="bank.id" :data-image="bank.bank_logo"
                                                    v-for="bank in banks">
                                                    {{ bank.bank_name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div v-if="payment_method == 'bank'" class="col-md-12">
                                            <hr>
                                            <div class="bank-content text-left">
                                                <ul id="transfer-details" class="wallet-bank-list">
                                                    <li class="row align header">
                                                        <div class="col-md-6 no-padding col-middle">
                                                            <h4 class="bold prime-color inline-middle no-margin">
                                                                تفاصيل البنك
                                                            </h4>
                                                        </div>
                                                        <div class="col-md-6 no-padding text-right">
                                                            <img id="wallet-bank-logo" class="wallet-bank-icon"
                                                                :src="bank.bank_logo">
                                                        </div>
                                                    </li>
                                                    <li class="row align">
                                                        <div class="col-md-4  text-left">
                                                            <span>
                                                                اسم
                                                                الحساب :
                                                            </span>
                                                        </div>
                                                        <div class="col-md-4  text-right">
                                                            <span>{{ bank.account_name }}</span>
                                                        </div>
                                                        <div class="col-md-4  text-right">
                                                            <a href="javascript:void(0);"
                                                                @click="copyText(bank.account_name)">
                                                                <i class="fa fa-copy"></i>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li class="row align">
                                                        <div class="col-md-4  text-left">
                                                            <span>
                                                                رقم
                                                                الحساب :
                                                            </span>
                                                        </div>
                                                        <div class="col-md-4  text-right">
                                                            <span class="inline-middle pointer btn-copy-to-clipboard">
                                                                {{ bank.account_number }}
                                                            </span>
                                                        </div>
                                                        <div class="col-md-4  text-right">
                                                            <a href="javascript:void(0);"
                                                                @click="copyText(bank.account_number)">
                                                                <i class="fa fa-copy"></i>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li class="row align">
                                                        <div class="col-md-4  text-left">
                                                            <span>آيبان :</span>
                                                        </div>
                                                        <div class="col-md-4  text-right">
                                                            <span class="inline-middle pointer btn-copy-to-clipboard">
                                                                {{ bank.iban_number }}
                                                            </span>
                                                        </div>
                                                        <div class="col-md-4  text-right">
                                                            <a href="javascript:void(0);"
                                                                @click="copyText(bank.iban_number)">
                                                                <i class="fa fa-copy"></i>
                                                            </a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="receipt_src != null" class="bank-content text-center">
                                                <h4 class="mb-3">
                                                    صورة الإيصال
                                                </h4>
                                                <img :src="receipt_src">
                                            </div>
                                            <input type="file" class="d-none" id="receipt"
                                                accept="image/x-png, image/jpg, image/jpeg" @change="onFileChange">
                                            <button v-if="receipt_src == null" type="button"
                                                class="btn btn_theme btn_md w-100" @click="upload_receipt">
                                                تحميل صورة الإيصال
                                            </button>
                                            <button v-else type="button" class="btn btn-danger btn_md w-100"
                                                @click="clear_receipt_input">
                                                تغيير
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer  py-3">
                        <button type="button" class="btn btn_theme text-white w-100" @click="makeCharging">
                            {{ $t('Next') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="credit-cards-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title  w-100">
                            اختر بطاقة
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-for="card in credit_cards" class="col-md-12 d-flex align-items-center mt-4">
                                <label class="checkbox-container">
                                    <input v-model="primary_card" type="radio" name="primary_card_id" :value="card">
                                    <span class="checkmark"></span>
                                </label>
                                &emsp;
                                <span>
                                    <span>{{ maskCreditCard(card.card_number) }}</span>
                                    &emsp;
                                    <img v-if="card.brand_name == 'VISA'" src="@/assets/icons/ic_visa.png" width="50">
                                    <img v-else-if="card.brand_name == 'MASTER'" src="@/assets/icons/ic_master.png"
                                        width="50">
                                    <img v-else src="@/assets/icons/ic_mada.png" width="50">
                                    <br>
                                    <small>تاريخ الانتهاء : **/{{ card.expiration_month }}</small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer  py-3">
                        <button type="button" class="btn btn_theme text-white w-100" @click="setCreditCard">
                            موافق
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
var that;
import $ from 'jquery';
import { Modal } from "bootstrap";
import { toast } from 'vue3-toastify';
import walletService from '@/services/wallet/wallet-service';
import creditCardService from '@/services/credit-cards/credit-card-service';
import banksService from '@/services/banks/banks-service';
import Tooltip from 'lightvue/tooltip';
import 'select2';
export default {
    name: "Wallet",
    directives: {
        tooltip: Tooltip,
    },
    data() {
        return {
            total_credit: 0,
            total_debit: 0,
            wallet_balance: 0,
            data_count: 0,
            transactions: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            payment_method: 'credit',
            credit_cards: [],
            modal: null,
            banks: [],
            bank_id: null,
            primary_card: [],
            cvv: '',
            charging_amount: null,
            selected_amount: 500,
            receipt_src: null,
            selectedFile: null,
            bank: []
        }
    },
    methods: {
        clear_receipt_input() {
            $('#receipt').val('');
            this.receipt_src = null;
        },
        upload_receipt() {
            $('#receipt').click();
        },
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.receipt_src = e.target.result;
                };
                reader.readAsDataURL(file);
                this.selectedFile = file;
            }
        },
        copyText(text) {
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = text;
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            tempTextArea.setSelectionRange(0, 99999);
            document.execCommand('copy');
            document.body.removeChild(tempTextArea);
            alert('Text copied to clipboard');
        },
        maskCreditCard(number) {
            const str = number.toString();
            const length = str.length;
            const masked = str.substr(length - 4) + '******' + str.slice(0, 3);
            return masked;
        },
        get_primary_card() {
            creditCardService.get_primary_card().then(response => {
                this.primary_card = response['data']['data'];
                this.primary_card_id = this.primary_card.id
            });
        },
        change_credit() {
            $('.pre-loader').show();
            creditCardService.get_all_cards().then(response => {
                this.credit_cards = response['data']['data'];
                this.modal = new Modal(document.getElementById('credit-cards-modal'), {
                    target: "#credit-cards-modal",
                    backdrop: "static"
                });
                this.modal.show()
                $('.pre-loader').hide();
            });
        },
        setCreditCard() {
            $('.pre-loader').show();
            this.primary_card_id = this.primary_card.id;
            creditCardService.set_card_primary(this.primary_card_id, { lang: localStorage.getItem('locale') }).then((response) => {
                $('.pre-loader').hide();
                this.modal.hide();
            });
        },
        calculatePercentage(part, total) {
            if (total === 0) {
                return 0;
            }
            return (part / total) * 100;
        },
        formatDate(isoDate) {
            const date = new Date(isoDate);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}/${month}/${day}`;
        },
        async get_all_banks() {
            await banksService.get_all_banks().then((response) => {
                this.banks = response['data']['data'];
                this.bank = this.banks[0];
                this.bank_id = this.banks[0].id;
            });
        },
        formatState(state) {
            if (!state.id) {
                return state.text;
            }
            var baseUrl = state.element.dataset.image;
            var $state = $(
                '<span style="display: flex;justify-content: space-between;padding:5px;">' + state.text + '<img src="' + baseUrl + '" class="img-flag" style="width: 80px;" /></span>'
            );
            return $state;
        },
        async get_user_wallet() {
            $('.pre-loader').addClass('white');
            $('.pre-loader').show();
            await walletService.get_user_wallet({ lang: localStorage.getItem('locale') }).then(response => {
                var res = response.data;
                this.wallet_balance = res.wallet.balance;
                this.total_credit = res.total_credit;
                this.total_debit = res.total_debit;
                var percentage = this.calculatePercentage(this.total_debit, this.total_credit);
                const newStyles = `
                    .main_range_price::after {
                        content: "${parseFloat(percentage).toFixed(2)}%";
                        width: ${parseFloat(percentage).toFixed(2)}%;
                    }
                `;
                let styleSheet = document.createElement("style");
                styleSheet.type = "text/css";
                styleSheet.innerText = newStyles;
                document.head.appendChild(styleSheet);

                $('.pre-loader').hide();
            });
        },
        async get_recharge_requests() {
            $('.pre-loader').addClass('white');
            $('.pre-loader').show();
            await walletService.get_recharge_requests({ lang: localStorage.getItem('locale') }).then(response => {
                var res = response.data.data;
                this.data_count = res.length;
                if (res.length > 0) {
                    this.transactions = res;
                }
                $('.pre-loader').hide();
            });
        },
        async makePayment(amount) {
            try {
                const paymentData = {
                    'entityId': '8a8294174b7ecb28014b9699220015ca',
                    'amount': amount,
                    'currency': 'EUR',
                    'paymentType': 'PA',
                    'paymentBrand': this.primary_card.brand_name,
                    'card.cvv': this.cvv,
                    'card.holder': this.primary_card.holder_name,
                    'card.number': this.primary_card.card_number,
                    'card.expiryYear': '20' + this.primary_card.expiration_year,
                    'card.expiryMonth': this.primary_card.expiration_month
                };
                const headers = {
                    "Authorization": "Bearer OGE4Mjk0MTc0YjdlY2IyODAxNGI5Njk5MjIwMDE1Y2N8c3k2S0pzVDg="
                };
                const response = await this.axios.post(this.$appUrl + "/api/flights/make_payments", paymentData, { headers });
                if (response.data.result.code === '000.100.110') {
                    const resultData = {
                        'entityId': '8a8294174b7ecb28014b9699220015ca',
                        'amount': amount,
                        'currency': 'EUR',
                        'paymentType': 'PA',
                        'paymentId': response.data.id
                    };
                    const resultResponse = await this.axios.post(this.$appUrl + "/api/flights/payments_result", resultData, { headers });
                    if (resultResponse.data.result.code === '000.100.110') {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        async makeCharging() {
            var amount = parseFloat(this.charging_amount != 0 && this.charging_amount != null ? this.charging_amount : this.selected_amount);
            if (this.payment_method == 'credit') {
                if (this.cvv.length != 3) {
                    $('.pre-loader').hide();
                    toast.error('يرجى إدخال رمز صالح', {
                        autoClose: 2500,
                        position: toast.POSITION.TOP_RIGHT,
                        closeButton: true
                    });
                    return;
                }
                $('.pre-loader').show();
                const response = await this.makePayment(amount);
                if (!response) {
                    $('.pre-loader').hide();
                    toast.error('حدث خطأ أثناء عملية الدفع', {
                        autoClose: 2500,
                        position: toast.POSITION.TOP_RIGHT,
                        closeButton: true
                    });
                    return;
                }
                var body = {
                    'amount': amount,
                    'payment_method': this.payment_method,
                    'payment_status': 'success',
                    'card_id': this.primary_card != null ? this.primary_card.id : null,
                    'type': 'deposit',
                };
                await walletService.send_recharge_request(body, { lang: localStorage.getItem('locale') }).then(response => {
                    $('.btn-close').click();
                    this.cvv = null;
                    this.payment_method = 'credit';
                    this.get_user_wallet();
                    this.get_recharge_requests();
                    toast.success(this.$t('walletchargedsuccessfully'), {
                        autoClose: 2500,
                        position: toast.POSITION.TOP_RIGHT,
                        closeButton: true
                    });
                });
            } else {
                if (this.receipt_src == null) {
                    $('.pre-loader').hide();
                    toast.error('إيصال الدفع مطلوب', {
                        autoClose: 2500,
                        position: toast.POSITION.TOP_RIGHT,
                        closeButton: true
                    });
                    return;
                }

                const formData = new FormData();
                formData.append('amount', amount);
                formData.append('payment_method', this.payment_method);
                formData.append('type', 'deposit');
                formData.append('bank_id', this.bank_id);
                formData.append('attachment', this.selectedFile);
                formData.append('lang', localStorage.getItem('locale'));
                $('.pre-loader').show();
                await walletService.send_recharge_request(formData, { lang: localStorage.getItem('locale') }).then(response => {
                    $('.btn-close').click();
                    this.payment_method = 'credit';
                    this.selectedFile = null;
                    this.receipt_src = null;
                    this.get_recharge_requests();
                    toast.success("تم ارسال الإيصال بنجاح", {
                        autoClose: 2500,
                        position: toast.POSITION.TOP_RIGHT,
                        closeButton: true
                    });
                });
            }
        },
        jquery() {
            $(document).on('change', '.banks-select', function () {
                that.bank = that.banks.filter(item => item.id == $(this).val())[0];
                that.bank_id = $(this).val();
            });
        }
    },
    mounted() {
        $('.banks-select').select2({
            templateResult: this.formatState,
            templateSelection: this.formatState,
        });
        that = this;
        this.jquery();
        this.get_user_wallet();
        this.get_recharge_requests();
        this.get_all_banks();
        this.get_primary_card();
    }
};
</script>
<style scoped>
.row.align > .col-middle {
    display: flex;
    align-items: center;
    gap: 10px;
}
input[type="radio"]:checked~.btn_add_bal {
    background: var(--main-color);
    color: var(--white-color);
}
.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
}

.checkbox-container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.checkbox-container input[type="radio"]:checked~.checkmark {
    background: url('~@/assets/img/green.png') no-repeat;
    background-position: -49px -1px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input[type="radio"]:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;
    border-radius: 50%;
}
.cvv {
    border: none;
    border-bottom: 2px solid #dfdfdf;
    outline: none;
}
.col-md-offset-3 {
    margin-right: 25%;
    margin-left: 0;
}
.bank-content{
    margin-bottom: 20px;
    border-radius: 3px;
}
.row.align {
    float: none;
    vertical-align: middle;
    white-space: nowrap;
}
.wallet-bank-list {
    padding: 0;
    list-style: none;
    position: relative;
    margin: 0 15px;
}
.wallet-bank-list>li.header {
    border-bottom: 0;
}
.wallet-bank-list>li{
    border-bottom: 1px solid #ccc;
    padding: 16px 0;
}
.wallet-bank-list>li:last-child {
    border-bottom: none;
}
.wallet-bank-icon {
    width: 100px;
    height: auto;
}
.fa-copy{
    font-size: 18px;
}
</style>
<style>
#wallet-charging-modal .select2-container .select2-selection--single {
    height: 38px;
    border: 1px solid #dfdfdf;
}
#wallet-charging-modal .select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    right: auto;
    height: 38px;
    left: 5px;
}
</style>