<template>

  <!-- Banner Area -->
  <section id="home_one_banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="banner_one_text">
                    <h1>{{ $t('you welcome in') }} <span>JSKY</span></h1>
                    <h4>{{ $t('Enjoy the most beautiful') }}</h4>
                    <h3>{{ $t('Moments with JSKY') }}</h3>
                </div>
            </div>
        </div>
    </div>
    <!-- Form Area -->
    <Form />
</section>
  <!-- Top destinations -->
  <WhyJsky />
  <!-- News Area -->
  <Destinations />
  <!-- News Area -->
  <JskySolutions />
  <!-- News Area -->
  <Testimonials />

  <!-- Our partners Area -->
  <Partners />

</template>

<script>
// @ is an alias to /src
import Form from '@/components/home/Form.vue'
import WhyJsky from '@/components/home/WhyJsky.vue'
import Destinations from '@/components/home/Destinations.vue'
import JskySolutions from '@/components/home/JskySolutions.vue'
import Testimonials from '@/components/home/Testimonials.vue'
import Partners from '@/components/home/Partners.vue'

export default {
  name: 'HomeView',
  components: {
    Form, WhyJsky, Destinations, JskySolutions,Testimonials, Partners
  }
}
</script>