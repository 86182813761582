import Api from "@/api/api";
import authHeader from "@/services/auth/auth-header";
const END_POINT = "api/users/";
class BanksService {
    get_all_banks(params) {
        return Api.get(END_POINT + "get_all_banks", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new BanksService();