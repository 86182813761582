<template>

    <!-- Common Banner Area -->
    <FaqBanner />

    <!-- Faqs Area -->
    <FaqArea />

</template>
<script>
import FaqBanner from '@/components/pages/FaqBanner.vue'
import FaqArea from '@/components/pages/FaqArea.vue'

export default {
    name: "FaqsView",
    components: {
        FaqBanner, FaqArea
    }
};
</script>