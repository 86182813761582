import Api from "@/api/api";
import authHeader from "@/services/auth/auth-header";
const END_POINT = "api/users/";
class UserService {
    get_user_data(params) {
        return Api.get(END_POINT + "get_user_data", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    update_user_data(body, params) {
        return Api.post(END_POINT + "user_update_data", body, { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new UserService();