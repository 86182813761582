<template>
    <section id="our_partners" class="section_padding our_partners">
        <div class="container">
            <!-- Section Heading -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>{{ $t('partners') }} <span class="main-color">JSKY</span></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="partner_slider_area owl-theme owl-carousel">
                        <swiper :slides-per-view="8">
                            <swiper-slide>
                                <div class="partner_logo">
                                    <a href="javascript:void(0);"><img
                                            src="../../assets/img/client/AlibabCloud.png"></a>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="partner_logo">
                                    <a href="javascript:void(0);"><img src="../../assets/img/client/Foodics.png"></a>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="partner_logo">
                                    <a href="javascript:void(0);"><img src="../../assets/img/client/JockClub.png"></a>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="partner_logo">
                                    <a href="javascript:void(0);"><img src="../../assets/img/client/Knowliom.png"></a>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="partner_logo">
                                    <a href="javascript:void(0);"><img src="../../assets/img/client/NEOM.png"></a>
                                </div>
                            </swiper-slide>
                            <swiper-slide>

                                <div class="partner_logo">
                                    <a href="javascript:void(0);"><img src="../../assets/img/client/jevent.png"></a>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="partner_logo">
                                    <a href="javascript:void(0);"><img src="../../assets/img/client/StcGroup.png"></a>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="partner_logo">
                                    <a href="javascript:void(0);"><img
                                            src="../../assets/img/client/VirginMobile.png"></a>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

// import Swiper core and required components
import SwiperCore, { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";


// install Swiper components
SwiperCore.use([Pagination,]);

export default {
    name: "Partners",

    components: {
        Swiper,
        SwiperSlide
    },

    data() {
        return {
            swiper: null,
        };
    }
};
</script>