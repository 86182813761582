<template>
    <section id="why_jsky_area" class="section_padding_top why_jsky_area">
        <div class="container">
            <!-- Section Heading -->
            <div class="row" style="padding: 0px 30px;">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>{{ $t('Why') }} <span class="main-color">JSKY</span> ؟ </h2>
                    </div>
                </div>
                <div v-for="item in items" class="col-md-3">
                    <a href="javascript:void(0);">
                        <div v-bind:class="'item-card' + (item.active ? ' active' : '')">
                            <img :src="item.icon">
                            <div class="content">
                                <h6>{{ $t(item.title) }}</h6>
                                <p>{{ $t(item.desc) }}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "WhyJsky",
    data() {
        return {
            items: [
                {
                    icon: require("@/assets/img/why-jsky/IconWhyJsky1.png"),
                    title: 'Special prices',
                    desc: 'By providing a wide variety of airline ticket prices, allowing users to choose the option that best suits their budget and needs.',
                    active: false
                },
                {
                    icon: require("@/assets/img/why-jsky/IconWhyJsky2.png"),
                    title: 'Comfortable flights',
                    desc: 'Comfortable flights are those that provide travelers with a comfortable and enjoyable experience while traveling.',
                    active: false
                },
                {
                    icon: require("@/assets/img/why-jsky/IconWhyJsky3.png"),
                    title: '24/7 service',
                    desc: 'Customer service is a vital part of any business, including travel and ticketing services. Here are some basic elements.',
                    active: false
                },
                {
                    icon: require("@/assets/img/why-jsky/IconWhyJsky4.png"),
                    title: 'Easy to book',
                    desc: 'Simplified and advanced features make the booking process smooth and enjoyable. Here are some reasons why you should book',
                    active: true
                }
            ],
        }
    }
};
</script>